import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAddingCreditsHistory } from "../../../core/actions/balanceActions";
import BackLink from "../../_shared/BackLink";
import TableComponent from "../../_shared/TableComponentWithCustomFields";
import { addingCreditsHistoryTableFields, renderAddingCreditsHistoryRow } from "./helpers";

const AddingCreditsHistory = () => {
  const { count, results } = useSelector(({ balances }) => balances.addingCreditsHistory);
  const dispatch = useDispatch();

  const fetchAddingCreditsHistory = meta => {
    dispatch(getAddingCreditsHistory(meta));
  };

  return (
    <div className="page-wrapper">
      <div className="adding-credits-history">
        <BackLink text="Back to Companies Balances" to="/admin/balances" />
        <div className="adding-credits-history__title">Adding credits history</div>
        <TableComponent
          tableFields={addingCreditsHistoryTableFields}
          tableContent={results}
          onTableFetch={fetchAddingCreditsHistory}
          renderTableRow={renderAddingCreditsHistoryRow}
          numberOfPages={count}
          noItemsText="No items"
          withSearch
        />
      </div>
    </div>
  );
};

export default AddingCreditsHistory;

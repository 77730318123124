import React from "react";
import moment from "moment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { deliveriesTableFields } from "./constants";
import { deliveryTypeByType } from "../../../core/helpers/functions";
import SkeletonComponent from "../../_shared/SkeletonComponent";

import ExpandIcon from "../../../assets/image/down-arrow-purple.svg";

export const translateDeliveryStatus = status => {
  switch (status) {
    case "delivery_confirmation":
      return "Delivery confirmation";
    case "deliver_to_pickup":
      return "Deliver to pickup";
    case "needs_delivery":
      return "Needs delivery";
    case "ready_for_pickup":
      return "Ready for pickup";
    case "on_delivery":
      return "On delivery";
    case "done":
      return "Done";
    case "unredeemed":
      return "Unredeemed";
    case "redeemed":
      return "Redeemed";
    default:
      return "—";
  }
};

const renderExpandedInfo = ({
  type,
  address_name,
  courier,
  status,
  tracking_number,
  eta,
  phone_number,
  unique_id
}) => {
  switch (type) {
    case "pickup":
      return (
        <>
          <div>
            <h6>Pickup address</h6>
            <p>{address_name || "—"}</p>
          </div>
          <br />
          <div>
            <h6>Order code</h6>
            <p>{unique_id || "—"}</p>
          </div>
        </>
      );
    case "courier":
      return (
        <>
          <div className="in_line_info">
            <div>
              <h6>Courier info</h6>
              <p>{courier || "—"}</p>
            </div>
            {(status === "on_delivery" || status === "done") && (
              <>
                <div>
                  <h6>Tracking number</h6>
                  <p>{tracking_number || "—"}</p>
                </div>
                <div>
                  <h6>ETA</h6>
                  <p>{eta ? moment(eta).format("DD.MM.YYYY") : "—"}</p>
                </div>
              </>
            )}
            <div>
              <h6>App user’s phone number</h6>
              <p>{phone_number || "—"}</p>
            </div>
          </div>
          <br />
          <h6>Shipping address</h6>
          <p>{address_name || "—"}</p>
        </>
      );
    case "parcel_machine":
      return (
        <>
          <div className="in_line_info">
            <div>
              <h6>Courier info</h6>
              <p>{courier || "—"}</p>
            </div>
            {(status === "on_delivery" || status === "done") && (
              <>
                <div>
                  <h6>Tracking number</h6>
                  <p>{tracking_number || "—"}</p>
                </div>
                <div>
                  <h6>ETA</h6>
                  <p>{eta ? moment(eta).format("DD.MM.YYYY") : "—"}</p>
                </div>
              </>
            )}
            <div>
              <h6>App user’s phone number</h6>
              <p>{phone_number || "—"}</p>
            </div>
          </div>
          <br />
          <h6>Parcel machine location</h6>
          <p>{address_name || "—"}</p>
        </>
      );
    default:
      return null;
  }
};

const renderDeliveryRow = ({
  id,
  loading,
  user,
  status,
  updated_at,
  address_name,
  courier,
  tracking_number,
  eta,
  phone_number,
  type,
  product_name,
  product_size,
  product_quantity,
  gift_name,
  gift_size,
  gift_quantity,
  unique_id
}) => (
  <Accordion
    key={`campaign_${id}`}
    classes={{
      root: "accordion-panel",
      expanded: "accordion-panel__expanded",
      disabled: "accordion-panel__disabled"
    }}
  >
    <AccordionSummary
      expandIcon={<img src={ExpandIcon} alt=">" />}
      classes={{
        root: "accordion-panel__summary",
        content: "accordion-panel__content"
      }}
    >
      <div className="table-row delivery-row">
        <div className="row-item" style={{ width: deliveriesTableFields[0].width }}>
          <div className="delivery-row__user">
            <SkeletonComponent variant="text" loading={loading} width={130}>
              <p className="delivery-row__user--name">
                {user?.first_name} {user?.last_name}
              </p>
            </SkeletonComponent>
            <SkeletonComponent variant="text" loading={loading} width={200}>
              <span className="delivery-row__user--email">{user?.email}</span>
            </SkeletonComponent>
          </div>
        </div>
        <div className="row-item" style={{ width: deliveriesTableFields[1].width }}>
          <SkeletonComponent variant="text" loading={loading} width={130}>
            <p className="delivery-row__type">{deliveryTypeByType(type)}</p>
          </SkeletonComponent>
        </div>
        <div className="row-item" style={{ width: deliveriesTableFields[2].width }}>
          <SkeletonComponent variant="text" loading={loading} width={50}>
            <div className="delivery-status">
              <p className="delivery-status__value">{translateDeliveryStatus(status)}</p>
              <p className="delivery-status__updated">
                Updated&nbsp;
                <span className="delivery-status__updated--value">
                  {moment(updated_at)
                    .subtract(1, "seconds")
                    .fromNow()}
                </span>
              </p>
            </div>
          </SkeletonComponent>
        </div>
      </div>
    </AccordionSummary>
    <AccordionDetails
      classes={{
        root: "accordion-panel__details"
      }}
    >
      <div className="wrapper">
        <div className="in_line_info">
          <div>
            <h6>Product</h6>
            <p>{product_name}</p>
          </div>
          {product_size && (
            <div>
              <h6>Size</h6>
              <p>{product_size}</p>
            </div>
          )}
          {product_quantity && (
            <div>
              <h6>Quantity</h6>
              <p>{product_quantity || "—"}</p>
            </div>
          )}
        </div>
        <br />
        {gift_name && (
          <>
            <div className="in_line_info">
              <div>
                <h6>Bonus product</h6>
                <p>{gift_name}</p>
              </div>
              {gift_size && (
                <div>
                  <h6>Size</h6>
                  <p>{gift_size}</p>
                </div>
              )}
              {gift_quantity && (
                <div>
                  <h6>Quantity</h6>
                  <p>{gift_quantity || "—"}</p>
                </div>
              )}
            </div>
            <br />
          </>
        )}
        {renderExpandedInfo({
          address_name,
          courier,
          tracking_number,
          eta,
          type,
          status,
          phone_number,
          unique_id
        })}
      </div>
    </AccordionDetails>
  </Accordion>
);

export { renderDeliveryRow };

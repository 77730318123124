import { AUTH } from "../actionTypes";

export function postLogin(data) {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: "default",
      request: {
        url: `/auth/login/`,
        method: "post",
        data
      }
    }
  };
}

export function postLogout() {
  return {
    type: AUTH.LOGIN,
    payload: {
      client: "default",
      request: {
        url: `/auth/logout/`,
        method: "post"
      }
    }
  };
}

export function getProfile() {
  return {
    type: AUTH.GET_PROFILE,
    payload: {
      client: "default",
      request: {
        url: `/user/profile/`,
        method: "get"
      }
    }
  };
}

export function resetAuthError(data) {
  return {
    type: AUTH.RESET_ERROR
  };
}

let BASE_URL, SECOND_URL, SENTRY_DSN;

const host = window.location.host;

if (host === "admin.campy.local") {
  BASE_URL = "http://api.campy.local:33000/hl/v1";
  SENTRY_DSN = "https://f02fdea116984a8e835ff21b038dfea4@sentry.4-com.pro/26";
} else if (host === "admin.stage.campy.eu") {
  BASE_URL = `https://api.stage.campy.eu/hl/v1`;
  SENTRY_DSN = "https://f02fdea116984a8e835ff21b038dfea4@sentry.4-com.pro/26";
} else if (host === "admin.prelive.campy.eu") {
  BASE_URL = `https://api.prelive.campy.eu/hl/v1`;
  SENTRY_DSN = "https://f02fdea116984a8e835ff21b038dfea4@sentry.4-com.pro/26";
} else if (host === "admin.campy.eu" || host === "admin.app.campy.eu") {
  BASE_URL = `https://api.campy.eu/hl/v1`;
  SENTRY_DSN = "https://f02fdea116984a8e835ff21b038dfea4@sentry.4-com.pro/26";
} else {
  BASE_URL = `https://api.campy.4-com.pro/hl/v1`;
  SENTRY_DSN = "https://f02fdea116984a8e835ff21b038dfea4@sentry.4-com.pro/26";
}

SECOND_URL = "";

export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
export const SENTRY_DSN_URL = SENTRY_DSN;

import { BALANCES } from "../actionTypes";
import initialResults from "../helpers/constants";

const INITIAL_STATE = {
  companiesBalances: {
    count: 10,
    results: initialResults
  },
  addingCreditsHistory: {
    count: 10,
    results: initialResults,
    meta: {
      page: 1,
      search: ""
    }
  },
  replenishmentRequests: {
    count: 10,
    results: initialResults
  },
  requestsHistory: {
    count: 10,
    results: initialResults,
    meta: {
      page: 1,
      search: ""
    }
  }
};

const balancesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case BALANCES.GET_COMPANIES_BALANCES_SUCCESS:
      return {
        ...state,
        companiesBalances: { ...state.companiesBalances, forceRefresh: false, ...payload.data }
      };
    case BALANCES.GET_ADDING_CREDITS_HISTORY_SUCCESS:
      return {
        ...state,
        addingCreditsHistory: { ...state.addingCreditsHistory, ...payload.data }
      };
    case BALANCES.GET_REPLENISHMENT_REQUESTS_BALANCES_SUCCESS:
      return {
        ...state,
        replenishmentRequests: {
          ...state.replenishmentRequests,
          forceRefresh: false,
          ...payload.data
        }
      };
    case BALANCES.GET_REQUESTS_HISTORY_SUCCESS:
      return {
        ...state,
        requestsHistory: { ...state.requestsHistory, ...payload.data }
      };
    case BALANCES.RESET_BALANCES:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default balancesReducer;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import _isEmpty from "lodash/isEmpty";

import { getAppUserRewards } from "../../../core/actions/appUsersActions";
import TotalBlock from "../../_shared/TotalBlock";
import TableComponent from "../../_shared/TableComponentWithCustomFields";
import { rewardsTableFields } from "../constants";
import { renderRewardRow } from "../helpers";

import RewardIcon from "../../../assets/image/reward.svg";

import "./styles.scss";

const Rewards = () => {
  const { count, results, count_by_type } = useSelector(({ appUsers }) => appUsers.rewards);
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchRewards = meta => {
    dispatch(getAppUserRewards(id, meta));
  };

  return (
    <section className="app-user-rewards">
      <TotalBlock
        loading={_isEmpty(count_by_type)}
        totalIcon={RewardIcon}
        totalText={`${Object.values(count_by_type).reduce(
          (acc, el) => acc + el.value,
          0
        )} rewards in total`}
        items={Object.values(count_by_type).map(el => ({
          label: `${el.value} ${el.type}`,
          value: el.type
        }))}
        backgroundColor="#FEF3E8"
        textColor="#E27C12"
      />
      <TableComponent
        tableFields={rewardsTableFields}
        tableContent={results}
        onTableFetch={fetchRewards}
        renderTableRow={renderRewardRow}
        numberOfPages={count}
        noItemsText="No rewards"
        withExpandedRow
      />
    </section>
  );
};

export default Rewards;

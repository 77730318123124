import { takeEvery } from "redux-saga/effects";
import { disableButtonLoader, enableButtonLoader, openSuccessSnack } from "./index";

export function* handleBalancesLoader() {
  yield takeEvery("POST_ADD_CREDITS", enableButtonLoader);
  yield takeEvery("POST_ADD_CREDITS_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_ADD_CREDITS_FAIL", disableButtonLoader);

  yield takeEvery("POST_ACCEPT_REQUEST", enableButtonLoader);
  yield takeEvery("POST_ACCEPT_REQUEST_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_ACCEPT_REQUEST_FAIL", disableButtonLoader);

  yield takeEvery("POST_REJECT_REQUEST", enableButtonLoader);
  yield takeEvery("POST_REJECT_REQUEST_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_REJECT_REQUEST_FAIL", disableButtonLoader);
}

export function* handleBalancesSnacks() {
  yield takeEvery("POST_ADD_CREDITS_SUCCESS", () => openSuccessSnack("Credits have been added"));
  yield takeEvery("POST_ACCEPT_REQUEST_SUCCESS", () =>
    openSuccessSnack("Request has been accepted")
  );
  yield takeEvery("POST_REJECT_REQUEST_SUCCESS", () =>
    openSuccessSnack("Request has been rejected")
  );
}

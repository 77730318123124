import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getCompaniesBalances } from "../../../core/actions/balanceActions";
import AddCreditsDialog from "../../_shared/Dialogs/AddCreditsDialog";
import TableComponent from "../../_shared/TableComponent";
import tableColumns from "./tableColumns";
import DefaultButton from "../../_shared/Buttons/DefaultButton";

const CompaniesBalances = () => {
  const [companyIDToAddCredits, setCompanyIDToAddCredits] = useState(null);
  const { count, results } = useSelector(({ balances }) => balances.companiesBalances);
  const dispatch = useDispatch();

  const fetchCompaniesBalances = meta => dispatch(getCompaniesBalances(meta));

  const toggleAddCreditsDialog = (companyID = null) => setCompanyIDToAddCredits(companyID);

  return (
    <div className="balances-wrapper__content">
      <Link
        to="/admin/adding-credits-history"
        className="balances-wrapper__content--view-history text-hover"
      >
        Adding credits history
      </Link>
      <TableComponent
        columns={tableColumns}
        data={results}
        fetchData={fetchCompaniesBalances}
        numberOfPages={count}
        noItemsText="No companies"
        withSearch
        searchPlaceholder="Search company by name or owner"
        renderActionButtons={({ id }) => (
          <DefaultButton
            variant="contained"
            classes="button_86 add_credits"
            onClick={() => toggleAddCreditsDialog(id)}
          >
            Add credits
          </DefaultButton>
        )}
      />
      <AddCreditsDialog
        isOpen={!!companyIDToAddCredits}
        onClose={toggleAddCreditsDialog}
        companyId={companyIDToAddCredits}
      />
    </div>
  );
};

export default CompaniesBalances;

import { takeEvery } from "redux-saga/effects";
import {
  disableButtonLoader,
  disableLoader,
  enableButtonLoader,
  enableLoader,
  openSuccessSnack,
  openErrorSnack
} from "./index";

export function* handleCompaniesLoader() {
  yield takeEvery("GET_ALL_COMPANIES", enableLoader);
  yield takeEvery("GET_ALL_COMPANIES_SUCCESS", disableLoader);
  yield takeEvery("GET_ALL_COMPANIES_FAIL", disableLoader);

  yield takeEvery("POST_APPROVE_COMPANY", enableButtonLoader);
  yield takeEvery("POST_APPROVE_COMPANY_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_APPROVE_COMPANY_FAIL", disableButtonLoader);

  yield takeEvery("POST_REJECT_COMPANY", enableButtonLoader);
  yield takeEvery("POST_REJECT_COMPANY_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_REJECT_COMPANY_FAIL", disableButtonLoader);

  yield takeEvery("POST_SUSPEND_COMPANY", enableButtonLoader);
  yield takeEvery("POST_SUSPEND_COMPANY_SUCCESS", disableButtonLoader);
  yield takeEvery("POST_SUSPEND_COMPANY_FAIL", disableButtonLoader);
}

export function* handleCompaniesSnacks() {
  yield takeEvery("POST_APPROVE_COMPANY_SUCCESS", () =>
    openSuccessSnack("Company has been approved")
  );
  yield takeEvery("POST_REJECT_COMPANY_SUCCESS", () =>
    openSuccessSnack("Company has been rejected")
  );
  yield takeEvery("POST_SUSPEND_COMPANY_SUCCESS", () =>
    openSuccessSnack("Company has been suspended")
  );

  yield takeEvery("POST_APPROVE_COMPANY_FAIL", openErrorSnack);
  yield takeEvery("POST_REJECT_COMPANY_FAIL", openErrorSnack);
  yield takeEvery("POST_SUSPEND_COMPANY_FAIL", openErrorSnack);
}

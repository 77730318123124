import React from "react";
import ReactPaginate from "react-paginate";
import "./styles.scss";

const Pagination = ({ active, pageCount, onChange }) => {
  return (
    <div className="pagination-container">
      <ReactPaginate
        forcePage={active - 1}
        onPageChange={onChange}
        pageCount={pageCount}
        pageRangeDisplayed={5}
        marginPagesDisplayed={1}
        previousLabel="Prev"
        nextLabel="Next"
        containerClassName="pagination-list"
        pageClassName="pagination-item"
        pageLinkClassName="pagination-link"
        activeLinkClassName="pagination-link-active"
        breakClassName="pagination-ellipsis"
      />
    </div>
  );
};

export default Pagination;

import { APP_USERS } from "../actionTypes";
import initialResults from "../helpers/constants";

const INITIAL_STATE = {
  list: {
    count: 10,
    results: initialResults,
    users_totals: {}
  },
  basicInfo: { initial: true, interests: [], hashtags: [] },
  campaigns: {
    count: 10,
    results: initialResults,
    count_by_status: {}
  },
  rewards: {
    count: 10,
    results: initialResults,
    count_by_type: {}
  },
  transactions: {
    count: 10,
    results: initialResults
  },
  sharing: {}
};

const appUsersReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case APP_USERS.GET_APP_USERS_SUCCESS:
      return { ...state, list: { ...state.list, ...payload.data } };

    case APP_USERS.GET_APP_USER_BASIC_INFO_SUCCESS:
      return { ...state, basicInfo: payload.data };

    case APP_USERS.GET_APP_USER_CAMPAIGNS_SUCCESS:
      return { ...state, campaigns: { ...state.campaigns, ...payload.data } };

    case APP_USERS.GET_APP_USER_REWARDS_SUCCESS:
      return { ...state, rewards: { ...state.rewards, ...payload.data } };

    case APP_USERS.GET_APP_USER_TRANSACTIONS_SUCCESS:
      return { ...state, transactions: { ...state.transactions, ...payload.data } };

    case APP_USERS.GET_APP_USER_SHARING_SUCCESS:
      return { ...state, sharing: payload.data };

    case APP_USERS.RESET_APP_USERS_INFO:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default appUsersReducer;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { patchPurchaseNote } from "../../../core/actions/purchasesActions";
import DefaultButton from "../../_shared/Buttons/DefaultButton";

const NoteForm = ({ purchaseID, defaultValue }) => {
  const [tempNote, setTempNote] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (tempNote !== defaultValue) setTempNote(defaultValue);
    // eslint-disable-next-line
  }, [defaultValue]);

  const onNoteSave = () => {
    dispatch(patchPurchaseNote(purchaseID, { note: tempNote }));
  };

  return (
    <div className="note">
      <div className="note__label">Note</div>
      <textarea name="note" value={tempNote} onChange={e => setTempNote(e.target.value)} />
      <DefaultButton
        variant="contained"
        classes="confirm"
        disabled={defaultValue === tempNote}
        onClick={onNoteSave}
      >
        Save
      </DefaultButton>
    </div>
  );
};
export default NoteForm;

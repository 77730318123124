import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import _isNumber from "lodash/isNumber";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { getAppUserSharing } from "../../../core/actions/appUsersActions";
import { splitByCommas } from "../../../core/helpers/functions";
import TotalBlock from "../../_shared/TotalBlock";

import ShareIcon from "../../../assets/image/share.svg";

import "./styles.scss";

const Sharing = () => {
  const {
    finished_count,
    finished_percent,
    invited_count,
    started_count,
    started_percent,
    total_credits,
    total_shares
  } = useSelector(({ appUsers }) => appUsers.sharing);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAppUserSharing(id));
  }, [id, dispatch]);

  const credits = `${splitByCommas((total_credits || 0).toFixed(2))}€`;

  return (
    <section className="app-user-sharing">
      <TotalBlock
        loading={!_isNumber(total_shares)}
        totalIcon={ShareIcon}
        totalText={`${total_shares} shares in total`}
        items={[
          {
            label: (
              <span>
                <b>{credits}</b> Campy credits received for sharing
              </span>
            ),
            value: ""
          }
        ]}
        backgroundColor="#FEF5FA"
        textColor="#F23EA6"
      />
      <div className="app-user-sharing__wrapper">
        <div className="app-user-sharing__block">
          <div className="app-user-sharing__block--title">Invited via link</div>
          <div className="app-user-sharing__block--progressbar">
            <CircularProgressbarWithChildren
              value={_isNumber(invited_count) ? 100 : 0}
              styles={buildStyles({
                pathColor: "#6429B3",
                trailColor: "rgba(100, 41, 179, 0.1)"
              })}
            >
              <div className="app-user-sharing__block--users-value">{invited_count || 0}</div>
              <div className="app-user-sharing__block--users-label">Users</div>
            </CircularProgressbarWithChildren>
          </div>
          <div className="app-user-sharing__block--description">
            Friends invited via link <br />
            in total
          </div>
        </div>
        <div className="app-user-sharing__block">
          <div className="app-user-sharing__block--title">Started campaign</div>
          <div className="app-user-sharing__block--progressbar">
            <CircularProgressbarWithChildren
              value={started_percent || 0}
              styles={buildStyles({
                pathColor: "#30837B",
                trailColor: "rgba(48, 131, 123, 0.1)"
              })}
            >
              <div className="app-user-sharing__block--users-value">{started_count || 0}</div>
              <div className="app-user-sharing__block--users-label">Users</div>
            </CircularProgressbarWithChildren>
          </div>
          <div className="app-user-sharing__block--description">
            {`${(started_percent || 0).toFixed(0)}% of invited`}
          </div>
        </div>
        <div className="app-user-sharing__block">
          <div className="app-user-sharing__block--title">Finished campaign</div>
          <div className="app-user-sharing__block--progressbar">
            <CircularProgressbarWithChildren
              value={finished_percent || 0}
              styles={buildStyles({
                pathColor: "#324EA3",
                trailColor: "rgba(50, 78, 163, 0.1)"
              })}
            >
              <div className="app-user-sharing__block--users-value">{finished_count || 0}</div>
              <div className="app-user-sharing__block--users-label">Users</div>
            </CircularProgressbarWithChildren>
          </div>
          <div className="app-user-sharing__block--description">
            {`${(finished_percent || 0).toFixed(0)}% of started`}
          </div>
          <div className="app-user-sharing__block--credits-block">
            <div className="app-user-sharing__block--credits-value">{credits}</div>
            <div className="app-user-sharing__block--credits-label">Campy credits received</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sharing;

import { PURCHASES } from "../actionTypes";
import { joinQueries } from "../helpers/functions";

export function getPurchases(meta) {
  return {
    type: PURCHASES.GET_PURCHASES,
    payload: {
      client: "default",
      request: {
        url: `/purchases/${joinQueries(meta)}`,
        method: "get"
      }
    }
  };
}

export const patchPurchaseNote = (id, data) => ({
  type: PURCHASES.PATCH_PURCHASE_NOTE,
  payload: {
    client: "default",
    request: {
      url: `/purchases/${id}/`,
      method: "patch",
      data
    }
  }
});

export function resetPurchases() {
  return {
    type: PURCHASES.RESET_PURCHASES
  };
}

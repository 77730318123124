import React, { Component } from "react";
import { connect } from "react-redux";

import Tabs from "../_shared/Tabs";
import Users from "./Users";
import Companies from "./Companies";

import "./styles.scss";

class Statistics extends Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search.substring(1));
    this.tabs = [
      { label: "Companies", value: "companies" },
      { label: "Users", value: "users" }
    ];
    this.state = {
      tab: params.get("tab") || "companies"
    };
  }

  changeTab = tab => {
    const { history } = this.props;
    this.setState({ tab });
    history.push(`/admin/statistics?tab=${tab}`);
  };

  getStatisticsTab = () => {
    const { tab } = this.state;
    if (tab === "users") {
      return <Users />;
    } else {
      return <Companies />;
    }
  };

  render() {
    const { tab } = this.state;
    return (
      <div className="page-wrapper">
        <div className="statistics_wrapper">
          <h1>Statistics</h1>
          <div className="statistics_tabs_wrapper">
            <Tabs currentTab={tab} changeTab={this.changeTab} tabs={this.tabs} />
          </div>
          {this.getStatisticsTab()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // dashboard: state.dashboard,
  };
};

const mapDispatchToProps = {
  // getDashboard
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);

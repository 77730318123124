import React, { memo, useState } from "react";
import { useSelector } from "react-redux";

import TableComponent from "../../_shared/TableComponentWithCustomFields";
import { renderContactRow } from "./helpers";
import { contactsTableFields } from "../constants";
import ContactDialog from "./ContactDialog";

import "./styles.scss";

const Contacts = () => {
  const [isContactDialogOpen, setContactDialogOpen] = useState(false);
  const [contact, setContact] = useState({ empty: true });
  const { contacts } = useSelector(({ company }) => company.basicInfo);

  const handleContactDialog = () => setContactDialogOpen(val => !val);

  const onEditContactClick = contact => {
    setContact(contact);
    handleContactDialog();
  };
  const onContactDialogClose = () => {
    handleContactDialog();
    setContact({ empty: true });
  };

  return (
    <section className="company__wrapper">
      <div className="company__wrapper--title">
        <p>Contacts</p>
        <button className="company__wrapper--add-btn" onClick={handleContactDialog}>
          + Add
        </button>
      </div>
      {contacts.length === 0 && <div className="no-items">No contacts yet</div>}
      {!!contacts.length && (
        <TableComponent
          tableFields={contactsTableFields}
          tableContent={contacts}
          onTableFetch={() => {}}
          renderTableRow={el => renderContactRow({ ...el, onEditContactClick })}
          isStatic
        />
      )}
      {isContactDialogOpen && (
        <ContactDialog defaultValues={contact} onClose={onContactDialogClose} />
      )}
    </section>
  );
};

export default memo(Contacts);

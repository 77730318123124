import React from "react";
import { useSelector } from "react-redux";

import DialogComponent from "../../DialogComponent";
import DefaultButton from "../../Buttons/DefaultButton";

const ConfirmationDialog = ({
  open,
  onClose,
  title,
  confirmationText,
  onConfirm,
  buttonText = "Confirm"
}) => {
  const buttonLoading = useSelector(({ app }) => app.buttonLoading);
  return (
    <DialogComponent open={open} onClose={onClose} closeIcon>
      <div className="default_dialog_wrapper">
        <div className="dialog_title">{title}</div>
        <div className="dialog_description">{confirmationText}</div>
        <div className="buttons_wrapper">
          <DefaultButton
            variant="contained"
            classes="confirm"
            onClick={onConfirm}
            loading={buttonLoading}
          >
            {buttonText}
          </DefaultButton>
        </div>
      </div>
    </DialogComponent>
  );
};

export default ConfirmationDialog;

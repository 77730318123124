import React from "react";
import moment from "moment";

import { productsTableFields } from "../constants";
import SkeletonComponent from "../../_shared/SkeletonComponent";

import APIIcon from "../../../assets/image/api-label.svg";
import TooltipMessage from "../../_shared/TooltipMessage";

const renderProductRow = ({
  id,
  loading,
  name,
  image,
  description,
  is_from_api,
  created_at,
  created_by,
  price,
  campaigns,
  campaigns_count,
  onCampaignsClick
}) => (
  <div className="table-row product-row" key={`campaign_${id}`}>
    <div className="row-item" style={{ width: productsTableFields[0].width }}>
      <div className="product-row__date">
        <SkeletonComponent variant="text" loading={loading} width={70}>
          <p>{moment(created_at).format("DD.MM.YYYY")}</p>
        </SkeletonComponent>
        <SkeletonComponent variant="text" loading={loading} width={50}>
          <p>{moment(created_at).format("HH:mm")}</p>
        </SkeletonComponent>
      </div>
    </div>
    <div className="row-item product-row__product" style={{ width: productsTableFields[1].width }}>
      <div className="product-row__product--image">
        <SkeletonComponent variant="rect" loading={loading} width={40} height={40}>
          <img src={image} alt="product" />
        </SkeletonComponent>
      </div>
      <div className="product-row__product--info">
        <SkeletonComponent variant="text" loading={loading} width={200}>
          <div className="product-row__product--name">
            {name}
            {is_from_api && <img src={APIIcon} alt="API" className="product-row__product--api" />}
          </div>
        </SkeletonComponent>
        <SkeletonComponent variant="text" loading={loading} width={300}>
          <TooltipMessage text={description} delay={200} position="top">
            <p className="product-row__product--description ellipsis">{description}</p>
          </TooltipMessage>
        </SkeletonComponent>
      </div>
    </div>
    <div className="row-item" style={{ width: productsTableFields[2].width }}>
      <SkeletonComponent variant="text" loading={loading} width={80}>
        <p className="product-row__price ellipsis">{`${price?.toFixed(2)} €`}</p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: productsTableFields[3].width }}>
      <div>
        <SkeletonComponent variant="text" loading={loading} width={80}>
          <p className="product-row__name">{`${created_by?.first_name ||
            ""} ${created_by?.last_name || ""}`}</p>
        </SkeletonComponent>
        <SkeletonComponent variant="text" loading={loading} width={120}>
          <p className="product-row__email">{created_by?.email}</p>
        </SkeletonComponent>
      </div>
    </div>
    <div className="row-item" style={{ width: productsTableFields[4].width }}>
      <SkeletonComponent variant="text" loading={loading} width={70}>
        <button
          className="product-row__campaigns"
          onClick={() => onCampaignsClick(name, campaigns)}
        >
          {campaigns_count}
        </button>
      </SkeletonComponent>
    </div>
  </div>
);

const renderCampaignRow = ({ id, name, company_name }) => (
  <div className="admin-row__campaign-row" key={`campaign_${id}`}>
    <div>
      <div className="admin-row__campaign-row--name ellipsis">{name}</div>
      <div className="admin-row__campaign-row--company ellipsis">{company_name}</div>
    </div>
  </div>
);

const renderBelongsToDescription = productName => (
  <div className="list-dialog__description">
    <span>{productName}</span>&nbsp;belongs to
  </div>
);

export { renderProductRow, renderCampaignRow, renderBelongsToDescription };

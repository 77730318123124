import { CAMPAIGNS } from "../actionTypes";
import initialResults from "../helpers/constants";

const INITIAL_STATE = {
  list: {
    count: 10,
    results: initialResults,
    totals: {}
  },
  header: { initial: true },
  basicInfo: { initial: true },
  steps: initialResults,
  audience: { initial: true, interests: [], hashtags: [], gender: [] },
  distribution: { initial: true },
  cost: { initial: true },
  deliveries: {
    count: 10,
    results: initialResults
  }
};

const campaignsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CAMPAIGNS.GET_CAMPAIGNS_SUCCESS:
      return { ...state, list: payload.data };

    case CAMPAIGNS.GET_CAMPAIGN_HEADER_SUCCESS:
      return { ...state, header: payload.data };
    case CAMPAIGNS.GET_CAMPAIGN_BASIC_INFO_SUCCESS:
      return { ...state, basicInfo: payload.data };
    case CAMPAIGNS.GET_CAMPAIGN_STEPS_SUCCESS:
      return { ...state, steps: payload.data };
    case CAMPAIGNS.GET_CAMPAIGN_AUDIENCE_SUCCESS:
      return { ...state, audience: payload.data };
    case CAMPAIGNS.GET_CAMPAIGN_DISTRIBUTION_SUCCESS:
      return { ...state, distribution: payload.data };
    case CAMPAIGNS.GET_CAMPAIGN_COST_SUCCESS:
      return { ...state, cost: payload.data };
    case CAMPAIGNS.GET_CAMPAIGN_DELIVERIES_SUCCESS:
      return { ...state, deliveries: payload.data };

    case CAMPAIGNS.RESET_CAMPAIGNS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default campaignsReducer;

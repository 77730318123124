import React from "react";
import moment from "moment";
import { Image as CloudinaryImage } from "cloudinary-react";

import Loader from "../../components/_shared/Loader";

import DiscountIcon from "../../assets/image/discount.svg";
import MonetaryIcon from "../../assets/image/monetary.svg";
import BonusIcon from "../../assets/image/bonus.svg";
import NoveltyIcon from "../../assets/image/novelty.svg";

export const parseObjectWithQueries = queries => {
  return Object.entries(queries).map(entry => `${entry[0]}=${entry[1]}`);
};

export const joinQueries = arr => {
  const parsedArr = parseObjectWithQueries(arr);
  return `${parsedArr.length ? "?" : ""}${parsedArr.join("&")}`;
};

export function getOption(label) {
  return (
    <div className={`status ${label}`}>
      <div>
        {label !== "All networks" && <span />}
        {label}
      </div>
    </div>
  );
}

export function splitByCommas(data) {
  return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function renderPageLoader(data) {
  return (
    <div className="loader_wrapper">
      <Loader size={40} />
    </div>
  );
}

export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function findAnError(object) {
  if (Object.values(object)[0][0]) {
    return Object.values(object)[0][0].message;
  } else if (Object.values(object)[0]) {
    return (
      Object.values(object)[0].message || Object.values(object)[0].image || "Something went wrong"
    );
  } else {
    return "Something went wrong";
  }
}

export function parseUsersChart(data) {
  let totalData = [],
    iosData = [],
    androidData = [];
  data.forEach(({ total, ios, android, date }) => {
    totalData.push([moment(date).valueOf(), total]);
    iosData.push([moment(date).valueOf(), ios]);
    androidData.push([moment(date).valueOf(), android]);
  });
  return { totalData, iosData, androidData };
}

export const formatDate = date => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const rewardIconByType = type => {
  switch (type) {
    case "discount":
      return <img src={DiscountIcon} alt="discount" />;
    case "monetary":
      return <img src={MonetaryIcon} alt="monetary" />;
    case "bonus":
      return <img src={BonusIcon} alt="bonus" />;
    case "novelty":
      return <img src={NoveltyIcon} alt="novelty" />;
    default:
      return null;
  }
};

export const translateCampaignStatus = status => {
  switch (status) {
    case "active":
      return "Published";
    case "inactive":
      return "Not published";
    case "finalizing":
      return "Finalizing";
    case "finalized":
      return "Finalized";
    case "archived":
      return "Archived";
    case "stopped_immediately":
      return "Stopped";
    case "deleted":
      return "Deleted";
    default:
      return "Unknown status";
  }
};

export const deliveryTypeByType = type => {
  switch (type) {
    case "courier":
      return "Courier";
    case "parcel_machine":
      return "Parcel machine";
    case "pickup":
      return "Pickup";
    case "redeemable_any":
      return "Redeemable at any location";
    default:
      return "No delivery";
  }
};

export const renderClickableImage = image => {
  if (image) {
    return (
      <a className="clickable-image" href={image} target="_blank" rel="noopener noreferrer">
        <div className="clickable-image--wrapper">
          <CloudinaryImage
            cloudName="campy"
            width="40"
            height="40"
            gravity="face"
            crop="thumb"
            publicId={image.split("/").pop()}
          />
        </div>
        <p className="ellipsis">{image.split("/").pop()}</p>
      </a>
    );
  }
  return "–";
};

import React, { useEffect } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import DialogComponent from "../../../_shared/DialogComponent";
import RenderField from "../../../_shared/RenderField";
import DefaultButton from "../../../_shared/Buttons/DefaultButton";
import { patchCompanyPayment } from "../../../../core/actions/companyActions";

let NoteDialog = ({
  onClose,
  handleSubmit,
  submitting,
  pristine,
  valid,
  initialize,
  defaultValues
}) => {
  const editingMode = defaultValues.note;
  const dispatch = useDispatch();
  const { id: companyID } = useParams();

  useEffect(() => {
    if (editingMode) {
      initialize(defaultValues);
    }
    // eslint-disable-next-line
  }, [editingMode]);

  const saveContact = async data => {
    await dispatch(patchCompanyPayment(companyID, defaultValues.id, data));
  };

  const onFormSubmit = data => {
    return saveContact(data).then(res => {
      if (res && res.error) {
        throw new SubmissionError({ note: res.error.response.data?.note[0].message });
      } else {
        onClose();
      }
    });
  };

  return (
    <DialogComponent open onClose={onClose} closeIcon>
      <div className="default_dialog_wrapper">
        <div className="dialog_title not_capitalize">{`${editingMode ? "Edit" : "Add"} note`}</div>
        <form onSubmit={handleSubmit(onFormSubmit)} className="dialog_form contact-dialog">
          <div className="fields-wrapper">
            <Field
              name="note"
              type="text"
              classes="note-multiline"
              component={RenderField}
              label="Note"
              multiline
            />
          </div>
          <div className="contact-dialog__buttons">
            <DefaultButton
              variant="contained"
              classes="confirm"
              formAction
              disabled={submitting || pristine || !valid}
              loading={submitting}
            >
              {editingMode ? "Save" : "Add"}
            </DefaultButton>
            <DefaultButton variant="contained" classes="cancel_btn" onClick={onClose}>
              Cancel
            </DefaultButton>
          </div>
        </form>
      </div>
    </DialogComponent>
  );
};

NoteDialog = reduxForm({
  form: "PaymentNoteForm"
})(NoteDialog);

export default NoteDialog;

import { getOption } from "../../core/helpers/functions";

const tabs = [
  { label: "Basic info", value: "basic_info" },
  { label: "Payments", value: "payments" },
  { label: "Products", value: "products" },
  { label: "Campaigns stats", value: "campaigns_stats" }
];

const campaignsTableFields = [
  { name: "name", label: "Title", withSort: true, width: "45%" },
  { name: "owner_name", label: "Owner", withSort: true, width: "40%" },
  { name: "admins_count", label: "Admins", withSort: true, width: "15%" }
];

const adminsTableFields = [
  { name: "name", label: "Name", withSort: true, width: "30%" },
  { name: "email", label: "Email", withSort: true, width: "30%" },
  { name: "companies_count", label: "Total companies", withSort: true, width: "20%" },
  { name: "campaigns_count", label: "Total campaigns", withSort: true, width: "20%" }
];

const contactsTableFields = [
  { name: "name", label: "Name", withSort: false, width: "25%" },
  { name: "email", label: "Email", withSort: false, width: "30%" },
  { name: "phone_number", label: "Phone number", withSort: false, width: "20%" },
  { name: "position", label: "Position", withSort: false, width: "20%" },
  { name: "actions", label: "", withSort: false, width: "5%" }
];

const paymentsTableFields = [
  { name: "created_at", label: "Date/Time", withSort: true, width: "15%" },
  { name: "type", label: "Type", withSort: false, width: "45%" },
  { name: "math_amount", label: "Amount", withSort: true, width: "15%" },
  { name: "note", label: "Note", withSort: false, width: "25%" }
];

const paymentsTypes = [
  { label: getOption("All transactions"), value: "" },
  { label: getOption("Balance replenishment"), value: "company_payment" },
  { label: getOption("Campaign deposit"), value: "company_to_campaign" },
  { label: getOption("Returning unused budget"), value: "campaign_force_stop" }
];

const productsTableFields = [
  { name: "created_at", label: "Date/Time", withSort: true, width: "10%" },
  { name: "product", label: "Product", withSort: false, width: "45%" },
  { name: "price", label: "Price", withSort: true, width: "15%" },
  { name: "user_name", label: "Added by", withSort: false, width: "20%" },
  { name: "campaigns_count", label: "Campaigns", withSort: true, width: "10%" }
];

const productsTypes = [
  { label: getOption("All products"), value: "" },
  { label: getOption("Added using API"), value: true },
  { label: getOption("Added manually"), value: false }
];

const campaignsStatsTableFields = [
  { name: "created_at", label: "Date/Time", withSort: true, width: "10%" },
  { name: "title", label: "Title", withSort: false, width: "40%" },
  {
    name: "distribution__max_participants_value",
    label: "Participants",
    withSort: true,
    width: "10%"
  },
  { name: "status", label: "Status", withSort: false, width: "40%" }
];

const campaignsStatuses = [
  { label: getOption("All statuses"), value: "" },
  { label: getOption("Published"), value: "active" },
  { label: getOption("Not published"), value: "inactive" },
  { label: getOption("Finalizing"), value: "finalizing" },
  { label: getOption("Finalized"), value: "finalized" },
  { label: getOption("Archived"), value: "archived" },
  { label: getOption("Stopped"), value: "stopped_immediately" }
];

export {
  tabs,
  campaignsTableFields,
  adminsTableFields,
  contactsTableFields,
  paymentsTableFields,
  paymentsTypes,
  productsTableFields,
  productsTypes,
  campaignsStatsTableFields,
  campaignsStatuses
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { getCampaignCost } from "../../../core/actions/campaignsActions";
import Published from "../../_shared/CostViews/Published";
import Inactive from "../../_shared/CostViews/Inactive";
import Finalized from "../../_shared/CostViews/Finalized";

import "./styles.scss";

const Cost = () => {
  const { cost_tab_data_old, cost_tab_data_new, status } = useSelector(
    ({ campaigns }) => campaigns.cost
  );
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCampaignCost(id));
  }, [dispatch, id]);

  return (
    <div className="campaign-cost">
      {(status === "active" || status === "finalizing") && <Published {...cost_tab_data_old} />}
      {(status === "inactive" || status === "archived") && <Inactive {...cost_tab_data_old} />}
      {(status === "finalized" || status === "stopped_immediately") && (
        <Finalized {...cost_tab_data_new} />
      )}
    </div>
  );
};

export default Cost;

import { BILLING } from "../actionTypes";
import initialResults from "../helpers/constants";

const INITIAL_STATE = {
  list: {
    count: 10,
    results: initialResults
  },
  billingByCompany: {
    count: 10,
    results: initialResults
  }
};

const balancesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case BILLING.GET_BILLING_SUCCESS:
      return {
        ...state,
        list: payload.data
      };
    case BILLING.GET_BILLING_BY_COMPANY_SUCCESS:
      return {
        ...state,
        billingByCompany: payload.data
      };
    case BILLING.POST_SEND_INVOICE_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          results: state.list.results.map(l =>
            l.id === payload.data.id ? { ...l, is_sent: true } : l
          )
        }
      };
    case BILLING.RESET_BILLING:
      return { ...state, list: INITIAL_STATE.list };
    case BILLING.RESET_BILLING_BY_COMPANY:
      return { ...state, billingByCompany: INITIAL_STATE.billingByCompany };

    default:
      return state;
  }
};

export default balancesReducer;

import React from "react";
import DefaultButton from "../_shared/Buttons/DefaultButton";

const renderActionButtons = ({ id, status, toggleDialog }) => {
  switch (status) {
    case "approved":
      return (
        <>
          <DefaultButton
            variant="contained"
            classes="button_86 suspend"
            onClick={e => {
              e.stopPropagation();
              toggleDialog("suspend_company", id);
            }}
          >
            Suspend
          </DefaultButton>
        </>
      );
    case "pending":
      return (
        <>
          <DefaultButton
            variant="contained"
            classes="button_86 approve"
            onClick={e => {
              e.stopPropagation();
              toggleDialog("approve_company", id);
            }}
          >
            Approve
          </DefaultButton>
          <DefaultButton
            variant="contained"
            classes="button_86 reject"
            onClick={e => {
              e.stopPropagation();
              toggleDialog("reject_company", id);
            }}
          >
            Reject
          </DefaultButton>
        </>
      );
    case "suspended":
      return (
        <>
          <DefaultButton
            variant="contained"
            classes="button_86 unsuspend"
            onClick={e => {
              e.stopPropagation();
              toggleDialog("unsuspend_company", id);
            }}
          >
            Unsuspend
          </DefaultButton>
        </>
      );
    case "rejected":
      return (
        <>
          <DefaultButton
            variant="contained"
            classes="button_86 approve"
            onClick={e => {
              e.stopPropagation();
              toggleDialog("approve_company", id);
            }}
          >
            Approve
          </DefaultButton>
        </>
      );
    default:
      return null;
  }
};

export { renderActionButtons };

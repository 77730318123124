import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { tabs } from "./constants";
import { getCompanyBasicInfo, resetCompanyInfo } from "../../core/actions/companyActions";
import Tabs from "../_shared/Tabs";
import BackLink from "../_shared/BackLink";
import CompanyHeader from "./CompanyHeader";
import CompanyBasicInfo from "./BasicInfo";
import Campaigns from "./Campaigns";
import Admins from "./Admins";
import Contacts from "./Contacts";
import Payments from "./Payments";
import Products from "./Products";
import CampaignsStats from "./CampaignsStats";

import "./styles.scss";

const Company = () => {
  const [tab, setTab] = useState(sessionStorage.getItem("companyTab") || "basic_info");
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCompanyBasicInfo(id));
    return () => {
      dispatch(resetCompanyInfo());
    };
    // eslint-disable-next-line
  }, []);

  const renderTab = () => {
    switch (tab) {
      case "basic_info":
        return <CompanyBasicInfo />;
      case "payments":
        return <Payments />;
      case "products":
        return <Products />;
      case "campaigns_stats":
        return <CampaignsStats />;
      default:
        return null;
    }
  };

  const onTabChange = newTab => {
    setTab(newTab);
    sessionStorage.setItem("companyTab", newTab);
  };

  return (
    <div className="company">
      <section className="company__wrapper">
        <BackLink text="Back to Companies" to="/admin/companies" />
        <CompanyHeader />
        <div className="company__tabs">
          <Tabs currentTab={tab} changeTab={onTabChange} tabs={tabs} />
        </div>
        {renderTab()}
      </section>
      {tab === "basic_info" && (
        <>
          <Campaigns />
          <Admins />
          <Contacts />
        </>
      )}
    </div>
  );
};

export default Company;

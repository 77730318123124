import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { getCampaignDeliveries } from "../../../core/actions/campaignsActions";
import { deliveriesTableFields } from "./constants";
import { renderDeliveryRow } from "./helpers";
import TableComponent from "../../_shared/TableComponentWithCustomFields";

import "./styles.scss";

const Deliveries = () => {
  const { results, count } = useSelector(({ campaigns }) => campaigns.deliveries);
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchDeliveries = meta => dispatch(getCampaignDeliveries(id, meta));

  return (
    <div className="campaign-deliveries">
      <TableComponent
        tableFields={deliveriesTableFields}
        tableContent={results}
        onTableFetch={fetchDeliveries}
        renderTableRow={renderDeliveryRow}
        numberOfPages={count}
        noItemsText="No deliveries"
        withExpandedRow
      />
    </div>
  );
};

export default Deliveries;

const tableColumns = [
  { name: "companyWithLegalName", label: "Company", withSort: true, sortKey: "name", width: "30%" },
  { name: "billing_contact_email", label: "Billing contact", withSort: false, width: "30%" },
  {
    name: "amount_of_transactions",
    label: "Amount of transactions",
    withSort: false,
    width: "20%"
  },
  { name: "cost_of_campaigns", label: "Cost of campaigns", withSort: false, width: "20%" }
];

export default tableColumns;

const tabs = [
  { label: "Basic info", value: "basic_info" },
  { label: "Campaigns", value: "campaigns" },
  { label: "Rewards", value: "rewards" },
  { label: "Transactions", value: "transactions" },
  { label: "Sharing", value: "sharing" }
];

const campaignsTableFields = [
  { name: "title", label: "Title", withSort: false, width: "30%" },
  { name: "company", label: "Company", withSort: true, width: "20%" },
  { name: "date", label: "Start and end date", withSort: false, width: "35%" },
  { name: "status", label: "Status", withSort: true, width: "15%" }
];

const rewardsTableFields = [
  { name: "title", label: "Title", withSort: false, width: "40%" },
  { name: "campaign_reward_type", label: "Type", withSort: true, width: "10%" },
  { name: "delivery_type", label: "Delivery", withSort: true, width: "20%" },
  { name: "delivery_status", label: "Status", withSort: false, width: "30%" }
];

const transactionsTableFields = [
  { name: "id", label: "ID", withSort: true, width: "25%" },
  { name: "created_at", label: "Date/Time", withSort: true, width: "25%" },
  { name: "type", label: "Type", withSort: false, width: "25%" },
  { name: "math_amount", label: "Amount", withSort: true, width: "25%" }
];

export { tabs, campaignsTableFields, rewardsTableFields, transactionsTableFields };

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  getReplenishmentRequests,
  postAcceptRequest,
  postRejectRequest
} from "../../../core/actions/balanceActions";
import ConfirmationDialog from "../../_shared/Dialogs/ConfirmationDialog";
import tableColumns from "./tableColumns";
import TableComponent from "../../_shared/TableComponent";
import DefaultButton from "../../_shared/Buttons/DefaultButton";

const ReplenishmentRequests = () => {
  const { count, results } = useSelector(({ balances }) => balances.replenishmentRequests);
  const [rejectDialogID, setRejectDialogID] = useState(null);
  const [acceptDialogID, setAcceptDialogID] = useState(null);
  const dispatch = useDispatch();

  const fetchReplenishmentRequests = meta => dispatch(getReplenishmentRequests(meta));

  const acceptRequest = async () => {
    await dispatch(postAcceptRequest(acceptDialogID));
    setAcceptDialogID(null);
  };

  const rejectRequest = async () => {
    await dispatch(postRejectRequest(rejectDialogID));
    setRejectDialogID(null);
  };

  return (
    <div className="balances-wrapper__content">
      <Link
        to="/admin/requests-history"
        className="balances-wrapper__content--view-history text-hover"
      >
        Requests history
      </Link>
      <TableComponent
        columns={tableColumns}
        data={results}
        fetchData={fetchReplenishmentRequests}
        numberOfPages={count}
        noItemsText="No requests"
        withSearch
        searchPlaceholder="Search company by name or owner"
        renderActionButtons={({ id }) => (
          <>
            <DefaultButton
              variant="contained"
              classes="button_86 approve"
              onClick={() => setAcceptDialogID(id)}
            >
              Accept
            </DefaultButton>
            <DefaultButton
              variant="contained"
              classes="button_86 reject"
              onClick={() => setRejectDialogID(id)}
            >
              Reject
            </DefaultButton>
          </>
        )}
      />
      <ConfirmationDialog
        open={!!acceptDialogID}
        onClose={() => setAcceptDialogID(null)}
        title="Accept request"
        confirmationText="Confirm that you want to accept this replenishment request"
        onConfirm={acceptRequest}
      />
      <ConfirmationDialog
        open={!!rejectDialogID}
        onClose={() => setRejectDialogID(null)}
        title="Reject request"
        confirmationText="Confirm that you want to reject this replenishment request"
        onConfirm={rejectRequest}
      />
    </div>
  );
};

export default ReplenishmentRequests;

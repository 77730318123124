import { BILLING } from "../actionTypes";
import { joinQueries } from "../helpers/functions";

const getBilling = meta => ({
  type: BILLING.GET_BILLING,
  payload: {
    client: "default",
    request: {
      url: `/billing/companies/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const postSendInvoice = id => ({
  type: BILLING.POST_SEND_INVOICE,
  payload: {
    client: "default",
    request: {
      url: `/billing/${id}/send-invoice/`,
      method: "post"
    }
  }
});

const getBillingByCompany = (id, meta) => ({
  type: BILLING.GET_BILLING_BY_COMPANY,
  payload: {
    client: "default",
    request: {
      url: `/billing/companies/${id}/campaigns/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const resetBilling = () => ({
  type: BILLING.RESET_BILLING
});

const resetBillingByCompany = () => ({
  type: BILLING.RESET_BILLING_BY_COMPANY
});

export { getBilling, postSendInvoice, getBillingByCompany, resetBilling, resetBillingByCompany };

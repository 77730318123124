import { AUTH } from "../actionTypes";

const INITIAL_STATE = {
  user_info: {},
  error_auth: ""
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH.GET_PROFILE_SUCCESS:
      return { ...state, user_info: action.payload.data };
    case AUTH.LOGIN_FAIL:
      let authError = "";
      if (
        action.error.response.data.non_field_errors &&
        action.error.response.data.non_field_errors[0]
      ) {
        authError = action.error.response.data.non_field_errors[0].message;
      }
      return { ...state, error_auth: authError };
    case AUTH.GET_PROFILE_FAIL:
      localStorage.removeItem("token");
      return INITIAL_STATE;
    case AUTH.RESET_ERROR:
      return { ...state, error_auth: "" };

    default:
      return state;
  }
};

export default authReducer;

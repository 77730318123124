import { CAMPAIGNS } from "../actionTypes";
import { joinQueries } from "../helpers/functions";

const getCampaigns = meta => ({
  type: CAMPAIGNS.GET_CAMPAIGNS,
  payload: {
    client: "default",
    request: {
      url: `/campaigns/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const getCampaignHeader = id => ({
  type: CAMPAIGNS.GET_CAMPAIGN_HEADER,
  payload: {
    client: "default",
    request: {
      url: `/campaigns/${id}/header/`,
      method: "get"
    }
  }
});

const getCampaignBasicInfo = id => ({
  type: CAMPAIGNS.GET_CAMPAIGN_BASIC_INFO,
  payload: {
    client: "default",
    request: {
      url: `/campaigns/${id}/basic-info/`,
      method: "get"
    }
  }
});

const patchCampaignNote = (id, data) => ({
  type: CAMPAIGNS.PATCH_CAMPAIGN_NOTE,
  payload: {
    client: "default",
    request: {
      url: `/campaigns/${id}/update/`,
      method: "patch",
      data
    }
  }
});

const getCampaignSteps = id => ({
  type: CAMPAIGNS.GET_CAMPAIGN_STEPS,
  payload: {
    client: "default",
    request: {
      url: `/campaigns/${id}/steps/`,
      method: "get"
    }
  }
});

const getCampaignAudience = id => ({
  type: CAMPAIGNS.GET_CAMPAIGN_AUDIENCE,
  payload: {
    client: "default",
    request: {
      url: `/campaigns/${id}/audience/`,
      method: "get"
    }
  }
});

const getCampaignDistribution = id => ({
  type: CAMPAIGNS.GET_CAMPAIGN_DISTRIBUTION,
  payload: {
    client: "default",
    request: {
      url: `/campaigns/${id}/distribution/`,
      method: "get"
    }
  }
});

const getCampaignCost = id => ({
  type: CAMPAIGNS.GET_CAMPAIGN_COST,
  payload: {
    client: "default",
    request: {
      url: `/campaigns/${id}/cost/`,
      method: "get"
    }
  }
});

const getCampaignDeliveries = (id, meta) => ({
  type: CAMPAIGNS.GET_CAMPAIGN_DELIVERIES,
  payload: {
    client: "default",
    request: {
      url: `/campaigns/${id}/deliveries/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const resetCampaigns = () => ({
  type: CAMPAIGNS.RESET_CAMPAIGNS
});

export {
  getCampaigns,
  getCampaignHeader,
  getCampaignBasicInfo,
  patchCampaignNote,
  getCampaignSteps,
  getCampaignAudience,
  getCampaignDistribution,
  getCampaignCost,
  getCampaignDeliveries,
  resetCampaigns
};

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { tabs } from "./constants";
import { getAppUserBasicInfo, resetAppUsers } from "../../core/actions/appUsersActions";
import Tabs from "../_shared/Tabs";
import BackLink from "../_shared/BackLink";
import Header from "./Header";
import BasicInfo from "./BasicInfo";
import Campaigns from "./Campaigns";
import Rewards from "./Rewards";
import Transactions from "./Transactions";
import Sharing from "./Sharing";

import "./styles.scss";

const Company = () => {
  const [tab, setTab] = useState(sessionStorage.getItem("appUserTab") || "basic_info");
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getAppUserBasicInfo(id));
    return () => {
      dispatch(resetAppUsers());
    };
    // eslint-disable-next-line
  }, []);

  const renderTab = useMemo(() => {
    switch (tab) {
      case "basic_info":
        return <BasicInfo />;
      case "campaigns":
        return <Campaigns />;
      case "rewards":
        return <Rewards />;
      case "transactions":
        return <Transactions />;
      case "sharing":
        return <Sharing />;
      default:
        return null;
    }
  }, [tab]);

  const onTabChange = newTab => {
    setTab(newTab);
    sessionStorage.setItem("appUserTab", newTab);
  };

  return (
    <div className="company">
      <section className="company__wrapper">
        <BackLink text="Back to App Users" to="/admin/app-users" />
        <Header />
        <div className="company__tabs">
          <Tabs currentTab={tab} changeTab={onTabChange} tabs={tabs} />
        </div>
        {renderTab}
      </section>
    </div>
  );
};

export default Company;

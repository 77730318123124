import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { closeErrorSnack } from "../../actions/appActions";
import { getProfile } from "../../actions/authActions";
import ScrollToTop from "../../helpers/ScrollToTop";

import Panel from "../../../components/Panel";
import Header from "../../../components/Header";
import Companies from "../../../components/Companies";
import Statistics from "../../../components/Statistics";
import Balances from "../../../components/Balances";
import AddingCreditsHistory from "../../../components/Balances/AddingCreditsHistory";
import RequestsHistory from "../../../components/Balances/RequestsHistory";
import Company from "../../../components/Company";
import AppUsers from "../../../components/AppUsers";
import AppUser from "../../../components/AppUser";
import Campaigns from "../../../components/Campaigns";
import Campaign from "../../../components/Campaign";
import Billing from "../../../components/Billing";
import BillingByCompany from "../../../components/Billing/BillingByCompany";
import Purchases from "../../../components/Purchases";

import NotFoundImage from "../../../assets/image/404.png";

class Container extends Component {
  state = {
    mounted: false
  };

  componentDidMount() {
    this.doRequest().then(() => {
      this.setState({ mounted: false });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.errorSnack !== this.props.errorSnack && this.props.errorSnack) {
      this.notifyError();
    }
    if (prevProps.successSnack !== this.props.successSnack && this.props.successSnack) {
      this.notifySuccess();
    }
  }

  doRequest = async () => {
    const { getProfile } = this.props;
    await getProfile();
  };

  notifyError = () =>
    toast.error(this.props.errorSnackText, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

  notifySuccess = () =>
    toast.success(this.props.successSnackText, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

  render() {
    const { match, history } = this.props;
    if (!localStorage.token) return <Redirect to="/auth/login" />;
    return (
      <div className="page_all">
        <Header history={history} />
        <div className="page">
          <Panel />
          <div className="triangle" />
          <Switch>
            <Route path={match.url} exact render={() => <Redirect to="/admin/companies" />} />
            <Route path={`${match.url}/companies`} exact component={Companies} />
            <Route path={`${match.url}/company/:id`} exact component={Company} />
            <Route path={`${match.url}/statistics`} exact component={Statistics} />
            <Route path={`${match.url}/balances`} exact component={Balances} />
            <Route
              path={`${match.url}/adding-credits-history`}
              exact
              component={AddingCreditsHistory}
            />
            <Route path={`${match.url}/requests-history`} exact component={RequestsHistory} />
            <Route path={`${match.url}/app-users`} exact component={AppUsers} />
            <Route path={`${match.url}/app-users/:id`} exact component={AppUser} />
            <Route path={`${match.url}/campaigns`} exact component={Campaigns} />
            <Route path={`${match.url}/campaigns/:id`} exact component={Campaign} />
            <Route path={`${match.url}/billing`} exact component={Billing} />
            <Route path={`${match.url}/billing/:id`} exact component={BillingByCompany} />
            <Route path={`${match.url}/purchases`} exact component={Purchases} />
            <Route
              render={() => (
                <div className="not_found">
                  <img src={NotFoundImage} alt="not found" />
                </div>
              )}
            />
          </Switch>
          <ToastContainer />
          <ScrollToTop />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => {
  return {
    errorSnack: app.errorSnack,
    errorSnackText: app.errorSnackText,
    successSnack: app.successSnack,
    successSnackText: app.successSnackText,
    loading: app.loading
  };
};

const mapDispatchToProps = {
  closeErrorSnack,
  getProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import _isNumber from "lodash/isNumber";

import { getAppUserTransactions } from "../../../core/actions/appUsersActions";
import TotalBlock from "../../_shared/TotalBlock";
import TableComponent from "../../_shared/TableComponentWithCustomFields";
import { transactionsTableFields } from "../constants";
import { renderTransactionRow } from "../helpers";

import TransactionIcon from "../../../assets/image/transaction.svg";

import "./styles.scss";
import { splitByCommas } from "../../../core/helpers/functions";

const Transactions = () => {
  const { count, results, credits_used_total, money_spent_total } = useSelector(
    ({ appUsers }) => appUsers.transactions
  );
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchTransactions = meta => {
    dispatch(getAppUserTransactions(id, meta));
  };

  return (
    <section className="app-user-transactions">
      <div className="app-user-transactions__totals">
        <TotalBlock
          loading={!_isNumber(credits_used_total)}
          totalIcon={TransactionIcon}
          totalText={`${splitByCommas(credits_used_total?.toFixed(2) || "0.00")} €`}
          items={[{ label: "Campy credits are used in total", value: "" }]}
          backgroundColor="#DEFFEE"
          textColor="#00685E"
        />
        <TotalBlock
          loading={!_isNumber(money_spent_total)}
          totalIcon={TransactionIcon}
          totalText={`${splitByCommas(money_spent_total?.toFixed(2) || "0.00")} €`}
          items={[{ label: "Money spent in total", value: "" }]}
          backgroundColor="#DEFFEE"
          textColor="#00685E"
        />
      </div>
      <TableComponent
        tableFields={transactionsTableFields}
        tableContent={results}
        onTableFetch={fetchTransactions}
        renderTableRow={renderTransactionRow}
        numberOfPages={count}
        noItemsText="No transactions"
        withExpandedRow
      />
    </section>
  );
};

export default Transactions;

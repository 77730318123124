const tabs = [
  { label: "Basic info", value: "basic_info" },
  { label: "Steps", value: "steps" },
  { label: "Audience", value: "audience" },
  { label: "Distribution", value: "distribution" },
  { label: "Cost", value: "cost" },
  { label: "Deliveries", value: "deliveries" }
];

export { tabs };

import React from "react";

import { splitByCommas } from "../../../../core/helpers/functions";

const Inactive = ({
  max_participants,
  total_estimated_cost,
  total_estimated_cost_bonuses,
  total_estimated_fee,
  total_estimated_link_sharing_cost
}) => (
  <div className="accordion-cost-wrapper">
    <div className="cost_block">
      <div className="cost_block_info">
        <div className="total_estimated_cost inactive">
          <p>Estimated campaign cost</p>
          <div>{splitByCommas(total_estimated_cost || "0.00")}&nbsp;€</div>
        </div>
        <div className="split_info mb-32 inactive">
          <div>
            <p>Estimated cost of bonuses</p>
            <div>
              <span>{splitByCommas(total_estimated_cost_bonuses || "0.00")}&nbsp;€</span>
            </div>
          </div>
          <div>
            <p>Maximum participants</p>
            <div>
              <span>{max_participants}</span>
            </div>
          </div>
          <div>
            <p>Estimated fee (inc VAT)</p>
            <div>
              <span>{splitByCommas(total_estimated_fee || "0.00")}&nbsp;€</span>
            </div>
          </div>
        </div>
        <div className="split_info inactive">
          <div>
            <p>Estimated link sharing cost</p>
            <div>
              <span>{splitByCommas(total_estimated_link_sharing_cost || "0.00")}&nbsp;€</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Inactive;

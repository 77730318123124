import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getRequestsHistory } from "../../../core/actions/balanceActions";
import BackLink from "../../_shared/BackLink";
import TableComponent from "../../_shared/TableComponentWithCustomFields";
import { renderRequestsHistoryRow, requestsHistoryTableFields } from "./helpers";

const RequestsHistory = () => {
  const { count, results } = useSelector(({ balances }) => balances.requestsHistory);
  const dispatch = useDispatch();

  const fetchRequestsHistory = meta => {
    dispatch(getRequestsHistory(meta));
  };

  return (
    <div className="page-wrapper">
      <div className="requests-history">
        <BackLink text="Back to Replenishment Requests" to="/admin/balances" />
        <div className="requests-history__title">Requests history</div>
        <TableComponent
          tableFields={requestsHistoryTableFields}
          tableContent={results}
          onTableFetch={fetchRequestsHistory}
          renderTableRow={renderRequestsHistoryRow}
          numberOfPages={count}
          noItemsText="No items"
          withSearch
        />
      </div>
    </div>
  );
};

export default RequestsHistory;

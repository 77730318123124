import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import TableComponent from "../../_shared/TableComponentWithCustomFields";
import { renderCampaignStatsRow } from "./helpers";
import { campaignsStatuses, campaignsStatsTableFields } from "../constants";
import { getCompanyCampaignsStats } from "../../../core/actions/companyActions";

import "./styles.scss";

const CampaignsStats = () => {
  const { results, count } = useSelector(({ company }) => company.campaignsStats);
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchCampaignsStats = meta => {
    dispatch(getCompanyCampaignsStats(id, meta));
  };

  return (
    <section className="campaigns-stats">
      <TableComponent
        tableFields={campaignsStatsTableFields}
        tableContent={results}
        onTableFetch={fetchCampaignsStats}
        renderTableRow={renderCampaignStatsRow}
        numberOfPages={count}
        withSearch
        searchPlaceholder="Search by campaign title"
        noItemsText="No campaigns"
        filterKey="status"
        filterOptions={campaignsStatuses}
        withExpandedRow
      />
    </section>
  );
};

export default memo(CampaignsStats);

import React from "react";

import { splitByCommas } from "../../../../core/helpers/functions";

const Finalized = ({
  actual_total_cost,
  actual_participants,
  actual_bonus_cost,
  actual_fee,
  actual_link_sharing,
  estimated_total_cost,
  estimated_participants,
  estimated_bonus_cost,
  estimated_fee,
  estimated_link_sharing
}) => (
  <div className="accordion-cost-wrapper">
    <div className="cost_block">
      <div className="cost_block_info">
        <div className="total_estimated_cost">
          <p>Total campaign cost</p>
          <div>
            {splitByCommas(actual_total_cost ? actual_total_cost.toFixed(2) : "0.00")}&nbsp;€
          </div>
        </div>
        <div className="split_info mb-32">
          <div>
            <p>Cost of bonuses</p>
            <div>
              <span>
                {splitByCommas(actual_bonus_cost ? actual_bonus_cost.toFixed(2) : "0.00")}
                &nbsp;€
              </span>
            </div>
          </div>
          <div>
            <p>Participants</p>
            <div>
              <span>{actual_participants}</span> of {estimated_participants}
            </div>
          </div>
          <div>
            <p>Fee (inc VAT)</p>
            <div>
              <span>{splitByCommas(actual_fee ? actual_fee.toFixed(2) : "0.00")}&nbsp;€</span>
            </div>
          </div>
        </div>
        <div className="split_info">
          <div>
            <p>Link sharing cost</p>
            <div>
              <span>
                {splitByCommas(actual_link_sharing ? actual_link_sharing.toFixed(2) : "0.00")}
                &nbsp;€
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr className="accordion-cost-wrapper__separator" />
    <div className="cost_block">
      <div className="cost_block_info">
        <div className="total_estimated_cost inactive">
          <p>Estimated campaign cost</p>
          <div className="inactive">
            {splitByCommas(estimated_total_cost ? estimated_total_cost.toFixed(2) : "0.00")}&nbsp;€
          </div>
        </div>
        <div className="split_info mb-32 inactive">
          <div>
            <p>Estimated cost of bonuses</p>
            <div>
              {splitByCommas(estimated_bonus_cost ? estimated_bonus_cost.toFixed(2) : "0.00")}
              &nbsp;€
            </div>
          </div>
          <div>
            <p>Maximum participants</p>
            <div>{estimated_participants}</div>
          </div>
          <div>
            <p>Estimated fee (inc VAT)</p>
            <div>{splitByCommas(estimated_fee ? estimated_fee.toFixed(2) : "0.00")}&nbsp;€</div>
          </div>
        </div>
        <div className="split_info inactive">
          <div>
            <p>Estimated link sharing cost</p>
            <div>
              {splitByCommas(estimated_link_sharing ? estimated_link_sharing.toFixed(2) : "0.00")}
              &nbsp;€
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Finalized;

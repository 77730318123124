import React from "react";
import App from "../containers/App";
import { Route, Switch, Redirect } from "react-router-dom";
import Container from "../containers/Container";
import AuthContainer from "../containers/AuthContainer";
import NotFoundImage from "../../assets/image/404.png";

export default (
  <App>
    <Switch>
      <Route
        path="/"
        exact
        render={() =>
          !!localStorage.token ? <Redirect to="/admin" /> : <Redirect to="/auth/login" />
        }
      />
      <Route path="/auth" component={AuthContainer} />
      <Route path="/admin" component={Container} />
      <Route
        render={() => (
          <div className="not_found">
            <img src={NotFoundImage} alt="not found" />
          </div>
        )}
      />
    </Switch>
  </App>
);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";

import { postLogout } from "../../core/actions/authActions";

import campy_logo from "../../assets/image/campy.svg";
import LogOutIcon from "../../assets/image/logout.svg";

import "./styles.scss";

class Header extends Component {
  handleOut = async () => {
    const { history, postLogout } = this.props;
    await postLogout();
    localStorage.removeItem("token");
    history.push("/auth");
  };

  render() {
    const {
      user_info: { full_name, email }
    } = this.props;
    return (
      <header className="header_wrapper no_select">
        <Link to="/admin" className="logo">
          <img src={campy_logo} alt="campy_logo" />
        </Link>
        <div className="header_info">
          <div className="info" onClick={this.openMenu}>
            <div>
              <span>{full_name}</span>
              <p>{email}</p>
            </div>
            <IconButton
              classes={{
                root: "dialog_close_button"
              }}
              onClick={this.handleOut}
            >
              <img src={LogOutIcon} alt="logout" />
            </IconButton>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    user_info: auth.user_info
  };
};

const mapDispatchToProps = {
  postLogout
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

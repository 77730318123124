import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import moment from "moment";

import { getBilling, resetBilling } from "../../core/actions/billingActions";
import tableColumns from "./tableColumns";
import TableComponent from "../_shared/TableComponent";

import "./styles.scss";

const Billing = () => {
  const { count, results } = useSelector(({ billing }) => billing.list);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    return () => {
      dispatch(resetBilling());
    };
  }, [dispatch]);

  const fetchBilling = meta => dispatch(getBilling(meta));

  const onRowClick = id => {
    history.push(`/admin/billing/${id}`);
  };

  return (
    <div className="page-wrapper">
      <h1>Billing</h1>
      <div className="page-wrapper__content">
        <TableComponent
          columns={tableColumns}
          data={results}
          fetchData={fetchBilling}
          numberOfPages={count}
          noItemsText="No items"
          withSearch
          withDatePickers
          datePickerProps={{
            views: ["year", "month"],
            variant: "inline",
            format: "MM/yyyy"
          }}
          searchPlaceholder="Search company by name"
          onRowClick={onRowClick}
          defaultMeta={{
            page: 1,
            search: "",
            date_to: moment(new Date())
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD"),
            date_from: moment(new Date())
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD")
          }}
        />
      </div>
    </div>
  );
};

export default Billing;

export const AUTH = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",

  RESET_ERROR: "RESET_ERROR",

  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAIL: "GET_PROFILE_FAIL"
};

export const APP = {
  LOADING: "LOADING",
  BUTTON_LOADING: "BUTTON_LOADING",

  ERROR_SNACK_OPEN: "ERROR_SNACK_OPEN",
  ERROR_SNACK_CLOSE: "ERROR_SNACK_CLOSE",

  SUCCESS_SNACK_OPEN: "SUCCESS_SNACK_OPEN",
  SUCCESS_SNACK_CLOSE: "SUCCESS_SNACK_CLOSE",

  REFETCH_CURRENT_TABLE: "REFETCH_CURRENT_TABLE"
};

export const COMPANIES = {
  GET_ALL_COMPANIES: "GET_ALL_COMPANIES",
  GET_ALL_COMPANIES_SUCCESS: "GET_ALL_COMPANIES_SUCCESS",
  GET_ALL_COMPANIES_FAIL: "GET_ALL_COMPANIES_FAIL",

  POST_APPROVE_COMPANY: "POST_APPROVE_COMPANY",
  POST_APPROVE_COMPANY_SUCCESS: "POST_APPROVE_COMPANY_SUCCESS",
  POST_APPROVE_COMPANY_FAIL: "POST_APPROVE_COMPANY_FAIL",

  POST_REJECT_COMPANY: "POST_REJECT_COMPANY",
  POST_REJECT_COMPANY_SUCCESS: "POST_REJECT_COMPANY_SUCCESS",
  POST_REJECT_COMPANY_FAIL: "POST_REJECT_COMPANY_FAIL",

  POST_SUSPEND_COMPANY: "POST_SUSPEND_COMPANY",
  POST_SUSPEND_COMPANY_SUCCESS: "POST_SUSPEND_COMPANY_SUCCESS",
  POST_SUSPEND_COMPANY_FAIL: "POST_SUSPEND_COMPANY_FAIL"
};

export const BALANCES = {
  GET_COMPANIES_BALANCES: "GET_COMPANIES_BALANCES",
  GET_COMPANIES_BALANCES_SUCCESS: "GET_COMPANIES_BALANCES_SUCCESS",
  GET_COMPANIES_BALANCES_FAIL: "GET_COMPANIES_BALANCES_FAIL",

  GET_ADDING_CREDITS_HISTORY: "GET_ADDING_CREDITS_HISTORY",
  GET_ADDING_CREDITS_HISTORY_SUCCESS: "GET_ADDING_CREDITS_HISTORY_SUCCESS",
  GET_ADDING_CREDITS_HISTORY_FAIL: "GET_ADDING_CREDITS_HISTORY_FAIL",

  GET_REPLENISHMENT_REQUESTS_BALANCES: "GET_REPLENISHMENT_REQUESTS_BALANCES",
  GET_REPLENISHMENT_REQUESTS_BALANCES_SUCCESS: "GET_REPLENISHMENT_REQUESTS_BALANCES_SUCCESS",
  GET_REPLENISHMENT_REQUESTS_BALANCES_FAIL: "GET_REPLENISHMENT_REQUESTS_BALANCES_FAIL",

  GET_REQUESTS_HISTORY: "GET_REQUESTS_HISTORY",
  GET_REQUESTS_HISTORY_SUCCESS: "GET_REQUESTS_HISTORY_SUCCESS",
  GET_REQUESTS_HISTORY_FAIL: "GET_REQUESTS_HISTORY_FAIL",

  POST_ADD_CREDITS: "POST_ADD_CREDITS",
  POST_ADD_CREDITS_SUCCESS: "POST_ADD_CREDITS_SUCCESS",
  POST_ADD_CREDITS_FAIL: "POST_ADD_CREDITS_FAIL",

  POST_ACCEPT_REQUEST: "POST_ACCEPT_REQUEST",
  POST_ACCEPT_REQUEST_SUCCESS: "POST_ACCEPT_REQUEST_SUCCESS",
  POST_ACCEPT_REQUEST_FAIL: "POST_ACCEPT_REQUEST_FAIL",

  POST_REJECT_REQUEST: "POST_REJECT_REQUEST",
  POST_REJECT_REQUEST_SUCCESS: "POST_REJECT_REQUEST_SUCCESS",
  POST_REJECT_REQUEST_FAIL: "POST_REJECT_REQUEST_FAIL",

  RESET_BALANCES: "RESET_BALANCES"
};

export const STATISTICS = {
  GET_COMPANIES_STATISTICS: "GET_COMPANIES_STATISTICS",
  GET_COMPANIES_STATISTICS_SUCCESS: "GET_COMPANIES_STATISTICS_SUCCESS",
  GET_COMPANIES_STATISTICS_FAIL: "GET_COMPANIES_STATISTICS_FAIL",

  GET_USERS_STATISTICS: "GET_USERS_STATISTICS",
  GET_USERS_STATISTICS_SUCCESS: "GET_USERS_STATISTICS_SUCCESS",
  GET_USERS_STATISTICS_FAIL: "GET_USERS_STATISTICS_FAIL",

  GET_CAMPAIGNS_BY_COMPANY: "GET_CAMPAIGNS_BY_COMPANY",
  GET_CAMPAIGNS_BY_COMPANY_SUCCESS: "GET_CAMPAIGNS_BY_COMPANY_SUCCESS",
  GET_CAMPAIGNS_BY_COMPANY_FAIL: "GET_CAMPAIGNS_BY_COMPANY_FAIL",

  RESET_CAMPAIGNS_BY_COMPANY: "RESET_CAMPAIGNS_BY_COMPANY"
};

export const COMPANY = {
  GET_COMPANY_BASIC_INFO: "GET_COMPANY_BASIC_INFO",
  GET_COMPANY_BASIC_INFO_SUCCESS: "GET_COMPANY_BASIC_INFO_SUCCESS",
  GET_COMPANY_BASIC_INFO_FAIL: "GET_COMPANY_BASIC_INFO_FAIL",

  PATCH_COMPANY_BASIC_INFO: "PATCH_COMPANY_BASIC_INFO",
  PATCH_COMPANY_BASIC_INFO_SUCCESS: "PATCH_COMPANY_BASIC_INFO_SUCCESS",
  PATCH_COMPANY_BASIC_INFO_FAIL: "PATCH_COMPANY_BASIC_INFO_FAIL",

  GET_COMPANY_ADMINS: "GET_COMPANY_ADMINS",
  GET_COMPANY_ADMINS_SUCCESS: "GET_COMPANY_ADMINS_SUCCESS",
  GET_COMPANY_ADMINS_FAIL: "GET_COMPANY_ADMINS_FAIL",

  GET_COMPANY_CAMPAIGNS: "GET_COMPANY_CAMPAIGNS",
  GET_COMPANY_CAMPAIGNS_SUCCESS: "GET_COMPANY_CAMPAIGNS_SUCCESS",
  GET_COMPANY_CAMPAIGNS_FAIL: "GET_COMPANY_CAMPAIGNS_FAIL",

  POST_COMPANY_CONTACT: "POST_COMPANY_CONTACT",
  POST_COMPANY_CONTACT_SUCCESS: "POST_COMPANY_CONTACT_SUCCESS",
  POST_COMPANY_CONTACT_FAIL: "POST_COMPANY_CONTACT_FAIL",

  PATCH_COMPANY_CONTACT: "PATCH_COMPANY_CONTACT",
  PATCH_COMPANY_CONTACT_SUCCESS: "PATCH_COMPANY_CONTACT_SUCCESS",
  PATCH_COMPANY_CONTACT_FAIL: "PATCH_COMPANY_CONTACT_FAIL",

  GET_COMPANY_PAYMENTS: "GET_COMPANY_PAYMENTS",
  GET_COMPANY_PAYMENTS_SUCCESS: "GET_COMPANY_PAYMENTS_SUCCESS",
  GET_COMPANY_PAYMENTS_FAIL: "GET_COMPANY_PAYMENTS_FAIL",

  GET_COMPANY_PRODUCTS: "GET_COMPANY_PRODUCTS",
  GET_COMPANY_PRODUCTS_SUCCESS: "GET_COMPANY_PRODUCTS_SUCCESS",
  GET_COMPANY_PRODUCTS_FAIL: "GET_COMPANY_PRODUCTS_FAIL",

  PATCH_COMPANY_PAYMENT: "PATCH_COMPANY_PAYMENT",
  PATCH_COMPANY_PAYMENT_SUCCESS: "PATCH_COMPANY_PAYMENT_SUCCESS",
  PATCH_COMPANY_PAYMENT_FAIL: "PATCH_COMPANY_PAYMENT_FAIL",

  GET_COMPANY_CAMPAIGNS_STATS: "GET_COMPANY_CAMPAIGNS_STATS",
  GET_COMPANY_CAMPAIGNS_STATS_SUCCESS: "GET_COMPANY_CAMPAIGNS_STATS_SUCCESS",
  GET_COMPANY_CAMPAIGNS_STATS_FAIL: "GET_COMPANY_CAMPAIGNS_STATS_FAIL",

  RESET_COMPANY_INFO: "RESET_COMPANY_INFO"
};

export const APP_USERS = {
  GET_APP_USERS: "GET_APP_USERS",
  GET_APP_USERS_SUCCESS: "GET_APP_USERS_SUCCESS",
  GET_APP_USERS_FAIL: "GET_APP_USERS_FAIL",

  GET_APP_USER_BASIC_INFO: "GET_APP_USER_BASIC_INFO",
  GET_APP_USER_BASIC_INFO_SUCCESS: "GET_APP_USER_BASIC_INFO_SUCCESS",
  GET_APP_USER_BASIC_INFO_FAIL: "GET_APP_USER_BASIC_INFO_FAIL",

  GET_APP_USER_CAMPAIGNS: "GET_APP_USER_CAMPAIGNS",
  GET_APP_USER_CAMPAIGNS_SUCCESS: "GET_APP_USER_CAMPAIGNS_SUCCESS",
  GET_APP_USER_CAMPAIGNS_FAIL: "GET_APP_USER_CAMPAIGNS_FAIL",

  GET_APP_USER_REWARDS: "GET_APP_USER_REWARDS",
  GET_APP_USER_REWARDS_SUCCESS: "GET_APP_USER_REWARDS_SUCCESS",
  GET_APP_USER_REWARDS_FAIL: "GET_APP_USER_REWARDS_FAIL",

  GET_APP_USER_TRANSACTIONS: "GET_APP_USER_TRANSACTIONS",
  GET_APP_USER_TRANSACTIONS_SUCCESS: "GET_APP_USER_TRANSACTIONS_SUCCESS",
  GET_APP_USER_TRANSACTIONS_FAIL: "GET_APP_USER_TRANSACTIONS_FAIL",

  GET_APP_USER_SHARING: "GET_APP_USER_SHARING",
  GET_APP_USER_SHARING_SUCCESS: "GET_APP_USER_SHARING_SUCCESS",
  GET_APP_USER_SHARING_FAIL: "GET_APP_USER_SHARING_FAIL",

  RESET_APP_USERS_INFO: "RESET_APP_USERS_INFO"
};

export const CAMPAIGNS = {
  GET_CAMPAIGNS: "GET_CAMPAIGNS",
  GET_CAMPAIGNS_SUCCESS: "GET_CAMPAIGNS_SUCCESS",
  GET_CAMPAIGNS_FAIL: "GET_CAMPAIGNS_FAIL",

  GET_CAMPAIGN_HEADER: "GET_CAMPAIGN_HEADER",
  GET_CAMPAIGN_HEADER_SUCCESS: "GET_CAMPAIGN_HEADER_SUCCESS",
  GET_CAMPAIGN_HEADER_FAIL: "GET_CAMPAIGN_HEADER_FAIL",

  GET_CAMPAIGN_BASIC_INFO: "GET_CAMPAIGN_BASIC_INFO",
  GET_CAMPAIGN_BASIC_INFO_SUCCESS: "GET_CAMPAIGN_BASIC_INFO_SUCCESS",
  GET_CAMPAIGN_BASIC_INFO_FAIL: "GET_CAMPAIGN_BASIC_INFO_FAIL",

  PATCH_CAMPAIGN_NOTE: "PATCH_CAMPAIGN_NOTE",
  PATCH_CAMPAIGN_NOTE_SUCCESS: "PATCH_CAMPAIGN_NOTE_SUCCESS",
  PATCH_CAMPAIGN_NOTE_FAIL: "PATCH_CAMPAIGN_NOTE_FAIL",

  GET_CAMPAIGN_STEPS: "GET_CAMPAIGN_STEPS",
  GET_CAMPAIGN_STEPS_SUCCESS: "GET_CAMPAIGN_STEPS_SUCCESS",
  GET_CAMPAIGN_STEPS_FAIL: "GET_CAMPAIGN_STEPS_FAIL",

  GET_CAMPAIGN_AUDIENCE: "GET_CAMPAIGN_AUDIENCE",
  GET_CAMPAIGN_AUDIENCE_SUCCESS: "GET_CAMPAIGN_AUDIENCE_SUCCESS",
  GET_CAMPAIGN_AUDIENCE_FAIL: "GET_CAMPAIGN_AUDIENCE_FAIL",

  GET_CAMPAIGN_DISTRIBUTION: "GET_CAMPAIGN_DISTRIBUTION",
  GET_CAMPAIGN_DISTRIBUTION_SUCCESS: "GET_CAMPAIGN_DISTRIBUTION_SUCCESS",
  GET_CAMPAIGN_DISTRIBUTION_FAIL: "GET_CAMPAIGN_DISTRIBUTION_FAIL",

  GET_CAMPAIGN_COST: "GET_CAMPAIGN_COST",
  GET_CAMPAIGN_COST_SUCCESS: "GET_CAMPAIGN_COST_SUCCESS",
  GET_CAMPAIGN_COST_FAIL: "GET_CAMPAIGN_COST_FAIL",

  GET_CAMPAIGN_DELIVERIES: "GET_CAMPAIGN_DELIVERIES",
  GET_CAMPAIGN_DELIVERIES_SUCCESS: "GET_CAMPAIGN_DELIVERIES_SUCCESS",
  GET_CAMPAIGN_DELIVERIES_FAIL: "GET_CAMPAIGN_DELIVERIES_FAIL",

  RESET_CAMPAIGNS: "RESET_CAMPAIGNS"
};

export const BILLING = {
  GET_BILLING: "GET_BILLING",
  GET_BILLING_SUCCESS: "GET_BILLING_SUCCESS",
  GET_BILLING_FAIL: "GET_BILLING_FAIL",

  POST_SEND_INVOICE: "POST_SEND_INVOICE",
  POST_SEND_INVOICE_SUCCESS: "POST_SEND_INVOICE_SUCCESS",
  POST_SEND_INVOICE_FAIL: "POST_SEND_INVOICE_FAIL",

  GET_BILLING_BY_COMPANY: "GET_BILLING_BY_COMPANY",
  GET_BILLING_BY_COMPANY_SUCCESS: "GET_BILLING_BY_COMPANY_SUCCESS",
  GET_BILLING_BY_COMPANY_FAIL: "GET_BILLING_BY_COMPANY_FAIL",

  RESET_BILLING: "RESET_BILLING",
  RESET_BILLING_BY_COMPANY: "RESET_BILLING_BY_COMPANY"
};

export const PURCHASES = {
  GET_PURCHASES: "GET_PURCHASES",
  GET_PURCHASES_SUCCESS: "GET_PURCHASES_SUCCESS",
  GET_PURCHASES_FAIL: "GET_PURCHASES_FAIL",

  PATCH_PURCHASE_NOTE: "PATCH_PURCHASE_NOTE",
  PATCH_PURCHASE_NOTE_SUCCESS: "PATCH_PURCHASE_NOTE_SUCCESS",
  PATCH_PURCHASE_NOTE_FAIL: "PATCH_PURCHASE_NOTE_FAIL",

  RESET_PURCHASES: "RESET_PURCHASES"
};

const tableColumns = [
  { name: "companyWithLegalName", label: "Company", withSort: true, sortKey: "name", width: "30%" },
  {
    name: "creationDate",
    label: "Creation date",
    withSort: true,
    sortKey: "created_at",
    width: "30%"
  },
  { name: "status", label: "Status", withSort: false, width: "15%" },
  { name: "actions", label: "", withSort: false, width: "25%" }
];

export default tableColumns;

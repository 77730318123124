import { getOption } from "./functions";

import AndroidIcon from "../../assets/image/android.svg";
import AppleIcon from "../../assets/image/apple.svg";

const platforms = [
  { label: getOption("All platforms"), value: "" },
  { label: getOption("Android"), value: "android", icon: AndroidIcon },
  { label: getOption("iOS"), value: "ios", icon: AppleIcon }
];

export default platforms;

import React from "react";

import { adminsTableFields } from "../constants";
import SkeletonComponent from "../../_shared/SkeletonComponent";

const renderAdminRow = ({
  id,
  loading,
  first_name,
  last_name,
  companyID,
  email,
  companies_count,
  campaigns_count,
  companies,
  campaigns,
  onCompaniesClick,
  onCampaignsClick
}) => (
  <div className="table-row admin-row" key={`admin_${id}`}>
    <div className="row-item" style={{ width: adminsTableFields[0].width }}>
      <div className="admin-row__name">
        <SkeletonComponent variant="text" loading={loading} width={150}>
          <p className="ellipsis">{`${first_name} ${last_name}`}</p>
        </SkeletonComponent>
        {companies?.find(el => +el.id === +companyID)?.is_owner && (
          <span className="is-owner">Owner</span>
        )}
      </div>
    </div>
    <div className="row-item" style={{ width: adminsTableFields[1].width }}>
      <SkeletonComponent variant="text" loading={loading} width={220}>
        <p className="admin-row__email ellipsis">{email}</p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: adminsTableFields[2].width }}>
      <SkeletonComponent variant="text" loading={loading} width={70}>
        <button
          className="admin-row__companies"
          onClick={() => onCompaniesClick(`${first_name} ${last_name}`, companies)}
        >
          {companies_count}
        </button>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: adminsTableFields[3].width }}>
      <SkeletonComponent variant="text" loading={loading} width={70}>
        <button
          className="admin-row__campaigns"
          onClick={() => onCampaignsClick(`${first_name} ${last_name}`, campaigns)}
        >
          {campaigns_count}
        </button>
      </SkeletonComponent>
    </div>
  </div>
);

const renderCompanyRow = ({ id, name, campaigns, is_owner }) => (
  <div className="admin-row__company-row" key={`company_${id}`}>
    <div>
      <div className="admin-row__company-row--name">{name}</div>
      {!is_owner &&
        campaigns.map(({ name: campaignName }) => (
          <div className="admin-row__company-row--campaign ellipsis">{campaignName}</div>
        ))}
    </div>
    {is_owner ? (
      <div className="admin-row__campaign-row--owner">Owner</div>
    ) : (
      <div className="admin-row__campaign-row--admin">Admin</div>
    )}
  </div>
);

const renderCampaignRow = ({ id, name, company, is_owner }) => (
  <div className="admin-row__campaign-row" key={`campaign_${id}`}>
    <div>
      <div className="admin-row__campaign-row--name ellipsis">{name}</div>
      <div className="admin-row__campaign-row--company ellipsis">{company?.name}</div>
    </div>
    {is_owner ? (
      <div className="admin-row__campaign-row--owner">Owner</div>
    ) : (
      <div className="admin-row__campaign-row--admin">Admin</div>
    )}
  </div>
);

const renderBelongsToDescription = adminName => (
  <div className="list-dialog__description">
    <span>{adminName}</span>&nbsp;belongs to
  </div>
);

export { renderAdminRow, renderCompanyRow, renderCampaignRow, renderBelongsToDescription };

import React from "react";
import { PushSpinner } from "react-spinners-kit";

class Loader extends React.Component {
  render() {
    return (
      <div className="loader_block">
        <PushSpinner sizeUnit={"px"} color={"#F23EA6"} {...this.props} />
      </div>
    );
  }
}

export default Loader;

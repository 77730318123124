import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { getCampaignSteps } from "../../../core/actions/campaignsActions";
import SkeletonComponent from "../../_shared/SkeletonComponent";
import { getStepInfo, renderStepDetails } from "./helpers";

import ExpandIcon from "../../../assets/image/down-arrow-purple.svg";

import "./styles.scss";

const Steps = () => {
  const steps = useSelector(({ campaigns }) => campaigns.steps);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCampaignSteps(id));
  }, [dispatch, id]);

  return (
    <div className="campaign-steps">
      {!steps.length && <div className="no-items">No steps</div>}
      {steps.map(({ id, loading, title, type, detail }) => {
        const stepInfo = getStepInfo(type) || {};
        return (
          <Accordion
            key={`step-${id}`}
            classes={{
              root: "accordion-panel",
              expanded: "accordion-panel__expanded"
            }}
          >
            <AccordionSummary
              expandIcon={<img src={ExpandIcon} alt=">" />}
              classes={{
                root: "accordion-panel__summary",
                content: "accordion-panel__content"
              }}
            >
              <div className="campaign-step__summary">
                <SkeletonComponent variant="rect" loading={loading} width={24} height={24}>
                  <img src={stepInfo.icon} alt="" className="campaign-step__summary--icon" />
                </SkeletonComponent>
                <div className="campaign-step__summary--info">
                  <div className="campaign-step__summary--name">
                    <SkeletonComponent variant="text" loading={loading} width={200}>
                      <span>{stepInfo.name}</span>
                    </SkeletonComponent>
                  </div>
                  <div className="campaign-step__summary--title">
                    <SkeletonComponent variant="text" loading={loading} width={200}>
                      <span>{detail?.title || "–"}</span>
                    </SkeletonComponent>
                  </div>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: "accordion-panel__details"
              }}
            >
              {renderStepDetails({ stepType: type, dispatch, ...detail })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default memo(Steps);

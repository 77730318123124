import React, { useEffect, useMemo, useState } from "react";
import _debounce from "lodash/debounce";
import _omitBy from "lodash/omitBy";
import { FormControl } from "@material-ui/core";
import clsx from "clsx";
import { useLocation } from "react-router";

import Pagination from "../Pagination";
import SelectComponent from "../SelectComponent";
import DatePicker from "../DatePicker";
import { formatDate } from "../../../core/helpers/functions";
import Checkbox from "../CheckBox";

import SearchIcon from "../../../assets/image/zoom.svg";
import SortInactiveIcon from "../../../assets/image/sort_inactive.svg";
import SortUpIcon from "../../../assets/image/sort-up.svg";
import SortDownIcon from "../../../assets/image/sort-down.svg";

import "./styles.scss";

const TableComponent = ({
  tableFields,
  tableContent,
  onTableFetch,
  renderTableRow,
  numberOfPages,
  isStatic,
  withSearch = false,
  searchPlaceholder = "",
  noItemsText = "",
  filterKey = null,
  filterOptions,
  secondFilterKey = null,
  secondFilterOptions,
  filterCheckbox = null,
  withDatePickers = false,
  datePickerProps = {},
  withExpandedRow = false,
  defaultMeta = {
    page: 1,
    search: "",
    ordering: "",
    date_to: "",
    date_from: ""
  }
}) => {
  const location = useLocation();
  const [meta, setMeta] = useState(
    localStorage.getItem(`meta-${location.pathname}`)
      ? JSON.parse(localStorage.getItem(`meta-${location.pathname}`))
      : defaultMeta
  );

  useEffect(() => {
    if (!isStatic) onTableFetch(_omitBy(meta, v => !v));

    if (location.pathname.split("/").length === 3) {
      localStorage.setItem(`meta-${location.pathname}`, JSON.stringify(meta));
    }
    // eslint-disable-next-line
  }, [JSON.stringify(meta)]);

  const onMetaChange = (fieldName, value) => {
    setMeta(prev => ({
      ...prev,
      [fieldName]: value
    }));
  };

  const onInputChange = inputText => {
    setMeta(prev => ({
      ...prev,
      page: 1,
      search: inputText
    }));
  };

  const onFilterChange = (fieldName, value) => {
    setMeta(prev => ({
      ...prev,
      page: 1,
      [fieldName]: value
    }));
  };

  const handleDateChange = (date, name) => {
    setMeta(prev => ({
      ...prev,
      page: 1,
      [name]: date ? formatDate(date) : ""
    }));
  };

  const debouncedOnInput = useMemo(
    () => _debounce(onInputChange, 300),
    // eslint-disable-next-line
    []
  );

  const setOrdering = ordering => {
    if (meta.ordering === `-${ordering}`) {
      onMetaChange("ordering", ordering);
    } else {
      onMetaChange("ordering", `-${ordering}`);
    }
  };

  const renderTableCell = ({ label, name, withSort, width }) => {
    const { ordering } = meta;
    let sortIcon = SortInactiveIcon;
    if (ordering === name) sortIcon = SortUpIcon;
    if (ordering === `-${name}`) sortIcon = SortDownIcon;
    return (
      <div
        className="row-item"
        style={{ width }}
        onClick={() => withSort && setOrdering(name)}
        key={name}
      >
        <p className="row-item__label">{label}</p>
        {withSort && <img src={sortIcon} alt="sort" />}
      </div>
    );
  };

  return (
    <>
      {(withSearch || filterKey || withDatePickers) && (
        <div className="table-search">
          {withSearch && (
            <div className="search">
              <input
                defaultValue={meta.search}
                onChange={e => {
                  e.persist();
                  debouncedOnInput(e.target.value);
                }}
                type="text"
                placeholder={searchPlaceholder}
              />
              <span>
                <img src={SearchIcon} alt="search" />
              </span>
            </div>
          )}
          {filterKey && (
            <FormControl className="select_wrapper">
              <SelectComponent
                value={filterOptions.find(el => el.value === meta[filterKey]) || filterOptions[0]}
                options={filterOptions}
                change={({ value }) => onFilterChange(filterKey, value)}
              />
            </FormControl>
          )}
          {secondFilterKey && (
            <FormControl className="select_wrapper">
              <SelectComponent
                value={
                  secondFilterOptions.find(el => el.value === meta[secondFilterKey]) ||
                  secondFilterOptions[0]
                }
                options={secondFilterOptions}
                change={({ value }) => onFilterChange(secondFilterKey, value)}
              />
            </FormControl>
          )}
          {filterCheckbox && (
            <Checkbox
              input={{
                value: meta[filterCheckbox.name],
                onChange: e => onFilterChange(filterCheckbox.name, e.target.checked),
                name: filterCheckbox.name
              }}
              label={filterCheckbox.label}
            />
          )}
          {withDatePickers && (
            <div className="table-date-pickers">
              <DatePicker
                maxDate={meta.date_to ? new Date(meta.date_to) : undefined}
                handleDateChange={handleDateChange}
                name="date_from"
                prevDate={meta.date_from}
                leftInputAdornment="From"
                {...datePickerProps}
              />
              <DatePicker
                minDate={meta.date_from ? new Date(meta.date_from) : undefined}
                handleDateChange={handleDateChange}
                name="date_to"
                prevDate={meta.date_to}
                leftInputAdornment="To"
                {...datePickerProps}
              />
            </div>
          )}
        </div>
      )}
      <div className="table-container">
        <div className="table-header" style={{ paddingRight: withExpandedRow ? `52px` : "0" }}>
          <div className="table-row">{tableFields.map(el => renderTableCell(el))}</div>
        </div>
        {!!tableContent.length && (
          <div className={clsx("table-body", withExpandedRow && "with-expanded-row")}>
            {tableContent.map(el => renderTableRow(el))}
          </div>
        )}
        {!tableContent.length && <div className="no-items">{noItemsText}</div>}
      </div>
      {numberOfPages > 10 && (
        <div className="pagination_wrapper">
          <Pagination
            active={meta.page}
            pageCount={Math.ceil(numberOfPages / 10)}
            onChange={e => onMetaChange("page", e.selected + 1)}
          />
        </div>
      )}
    </>
  );
};

export default TableComponent;

import React from "react";
import { Link } from "react-router-dom";

import ArrowIcon from "../../../assets/image/small-left.svg";

import "./styles.scss";

const BackLink = ({ text, to }) => (
  <Link to={to} className="back-link">
    <img src={ArrowIcon} alt="<" className="back-link__arrow" />
    {text}
  </Link>
);

export default BackLink;

import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import AuthReducer from "./reduceAuth";
import AppReducer from "./reduceApp";
import CompaniesReducer from "./reduceCompanies";
import BalancesReducer from "./reduceBalances";
import StatisticsReducer from "./reduceStatistics";
import CompanyReducer from "./reduceCompany";
import AppUsersReducer from "./reduceAppUsers";
import CampaignsReducer from "./reduceCampaigns";
import BillingReducer from "./reduceBilling";
import PurchasesReducer from "./reducePurchases";

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    auth: AuthReducer,
    app: AppReducer,
    companies: CompaniesReducer,
    balances: BalancesReducer,
    statistics: StatisticsReducer,
    company: CompanyReducer,
    appUsers: AppUsersReducer,
    campaigns: CampaignsReducer,
    billing: BillingReducer,
    purchases: PurchasesReducer
  });

export default rootReducer;

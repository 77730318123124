import React from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import "./styles.scss";

export default function MaterialTabs({ currentTab, changeTab, tabs }) {
  return (
    <Tabs
      value={currentTab}
      onChange={(e, tab) => changeTab(tab)}
      classes={{
        root: "tabs_wrapper",
        flexContainer: "tabs_buttons_container"
      }}
    >
      {tabs.map(({ label, value }) => (
        <Tab
          key={`${value}_tab`}
          label={label}
          value={value}
          classes={{
            root: "tab_wrapper",
            selected: "tab_selected"
          }}
        />
      ))}
    </Tabs>
  );
}

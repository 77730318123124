import { put, takeEvery, all } from "redux-saga/effects";
import { handleProfileLoader, handleProfileSnacks } from "./profile";
import { handleCompaniesLoader, handleCompaniesSnacks } from "./companies";
import { handleBalancesLoader, handleBalancesSnacks } from "./balances";
import { handleStatisticsLoader } from "./statistics";
import { findAnError } from "../helpers/functions";
import { handleCompanyLoader, handleCompanySnacks } from "./company";
import { handleCampaignsSnacks } from "./campaigns";

export default function* rootSaga() {
  yield all([
    handleLoader(),
    handleRefetchTables(),
    handleSnack(),
    handleProfileLoader(),
    handleProfileSnacks(),
    handleCompaniesLoader(),
    handleCompaniesSnacks(),
    handleBalancesLoader(),
    handleBalancesSnacks(),
    handleStatisticsLoader(),
    handleCompanyLoader(),
    handleCompanySnacks(),
    handleCampaignsSnacks()
  ]);
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

export function* openErrorSnack(e) {
  yield put({
    type: "ERROR_SNACK_OPEN",
    payload:
      e && e.error && e.error.response.data
        ? findAnError(e.error.response.data)
        : e && e.payload
        ? e.payload
        : e
        ? e
        : "Something went wrong"
  });
}

export function* openSuccessSnack(e) {
  yield put({ type: "SUCCESS_SNACK_OPEN", payload: e });
}

export function* resetErrorSnack() {
  yield delay(100);
  yield put({ type: "ERROR_SNACK_CLOSE" });
}

export function* resetSuccessSnack() {
  yield delay(100);
  yield put({ type: "SUCCESS_SNACK_CLOSE" });
}

export function* handleSnack() {
  yield takeEvery("ERROR_SNACK_OPEN", resetErrorSnack);
  yield takeEvery("SUCCESS_SNACK_OPEN", resetSuccessSnack);
}

export function* enableLoader() {
  yield put({ type: "LOADING", payload: true });
}

export function* disableLoader() {
  yield delay(200);
  yield put({ type: "LOADING", payload: false });
}

export function* enableButtonLoader() {
  yield put({ type: "BUTTON_LOADING", payload: true });
}

export function* disableButtonLoader() {
  yield put({ type: "BUTTON_LOADING", payload: false });
}

function* handleLoader() {
  yield takeEvery("LOGIN", enableLoader);
  yield takeEvery("LOGIN_SUCCESS", disableLoader);
  yield takeEvery("LOGIN_FAIL", disableLoader);

  yield takeEvery("LOGIN", enableButtonLoader);
  yield takeEvery("LOGIN_SUCCESS", disableButtonLoader);
  yield takeEvery("LOGIN_FAIL", disableButtonLoader);
}

export function* setRefetchCurrentTable(refetchCurrentTable) {
  yield put({ type: "REFETCH_CURRENT_TABLE", refetchCurrentTable });
}

function* handleRefetchTables() {
  yield takeEvery("POST_APPROVE_COMPANY_SUCCESS", () => setRefetchCurrentTable(true));
  yield takeEvery("POST_REJECT_COMPANY_SUCCESS", () => setRefetchCurrentTable(true));
  yield takeEvery("POST_SUSPEND_COMPANY_SUCCESS", () => setRefetchCurrentTable(true));
  yield takeEvery("GET_ALL_COMPANIES_SUCCESS", () => setRefetchCurrentTable(false));

  yield takeEvery("POST_ADD_CREDITS_SUCCESS", () => setRefetchCurrentTable(true));
  yield takeEvery("POST_REJECT_REQUEST_SUCCESS", () => setRefetchCurrentTable(true));
  yield takeEvery("POST_ACCEPT_REQUEST_SUCCESS", () => setRefetchCurrentTable(true));
  yield takeEvery("GET_COMPANIES_BALANCES_SUCCESS", () => setRefetchCurrentTable(false));
  yield takeEvery("GET_REPLENISHMENT_REQUESTS_BALANCES_SUCCESS", () =>
    setRefetchCurrentTable(false)
  );
}

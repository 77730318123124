const tableColumns = [
  {
    name: "companyWithRequestedBy",
    label: "Company",
    withSort: true,
    sortKey: "company_name",
    width: "30%"
  },
  { name: "created_at", label: "Date/Time", withSort: true, width: "25%" },
  { name: "amount", label: "Amount", withSort: true, width: "20%" },
  { name: "actions", label: "", withSort: false, width: "25%" }
];

export default tableColumns;

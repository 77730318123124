import React from "react";
import { useDispatch, useSelector } from "react-redux";
import _isEmpty from "lodash/isEmpty";

import {
  getCompaniesStatistics,
  getCampaignsByCompany,
  resetCampaignsByCompany
} from "../../../core/actions/statisticsActions";
import TableComponent from "../../_shared/TableComponent";
import TotalBlock from "../../_shared/TotalBlock";

import CompaniesIcon from "../../../assets/image/companies_total.svg";
import CampaignsIcon from "../../../assets/image/campaigns_icon.svg";
import ListDialog from "../../_shared/Dialogs/ListDialog";

const Companies = () => {
  const { count, results, campaigns_totals, companies_totals } = useSelector(
    ({ statistics }) => statistics.companies_statistics
  );
  const { campaigns } = useSelector(({ statistics }) => statistics);
  const dispatch = useDispatch();

  const fetchCompanies = meta => dispatch(getCompaniesStatistics(meta));

  const fetchCampaigns = ({ id }, name) =>
    dispatch(getCampaignsByCompany(id, name.replace("total", "").replace("_campaigns_count", "")));

  const onCampaignsDialogClose = () => dispatch(resetCampaignsByCompany());

  return (
    <div className="page-wrapper__content">
      <TableComponent
        columns={[
          {
            name: "companyWithStatus",
            label: "Company",
            withSort: false,
            sortKey: "name",
            width: "15%"
          },
          {
            name: "owner",
            label: "Owner",
            withSort: true,
            sortKey: "created_by__email",
            width: "15%"
          },
          {
            name: "total_campaigns_count",
            onClick: fetchCampaigns,
            label: "Total campaigns",
            withSort: true,
            width: "10%"
          },
          {
            name: "active_campaigns_count",
            onClick: fetchCampaigns,
            label: "Published campaigns",
            withSort: true,
            width: "10%"
          },
          {
            name: "inactive_campaigns_count",
            onClick: fetchCampaigns,
            label: "Unpublished campaigns",
            withSort: true,
            width: "10%"
          },
          {
            name: "finalizing_campaigns_count",
            onClick: fetchCampaigns,
            label: "Finalizing campaigns",
            withSort: true,
            width: "10%"
          },
          {
            name: "finalized_campaigns_count",
            onClick: fetchCampaigns,
            label: "Finalized campaigns",
            withSort: true,
            width: "10%"
          },
          {
            name: "stopped_immediately_campaigns_count",
            onClick: fetchCampaigns,
            label: "Stopped campaigns",
            withSort: true,
            width: "10%"
          },
          {
            name: "archived_campaigns_count",
            onClick: fetchCampaigns,
            label: "Archived campaigns",
            withSort: true,
            width: "10%"
          }
        ]}
        data={results}
        fetchData={fetchCompanies}
        numberOfPages={count}
        noItemsText="No companies"
        withSearch
        searchPlaceholder="Search company by name or owner"
        renderTitle={meta => (
          <h2>
            <span style={{ textTransform: "capitalize" }}>{meta.status || "All"}</span> companies
          </h2>
        )}
        renderTotals={onMetaChange => (
          <>
            <div className="companies_in_total_wrapper">
              <TotalBlock
                loading={_isEmpty(companies_totals)}
                totalIcon={CompaniesIcon}
                totalText={`${companies_totals.total} companies in total`}
                items={[
                  { label: `${companies_totals.approved} approved`, value: "approved" },
                  { label: `${companies_totals.suspended} suspended`, value: "suspended" },
                  { label: `${companies_totals.pending} pending`, value: "pending" },
                  { label: `${companies_totals.rejected} rejected`, value: "rejected" }
                ]}
                onItemClick={val => onMetaChange("status", val)}
              />
              <TotalBlock
                loading={_isEmpty(campaigns_totals)}
                totalIcon={CampaignsIcon}
                totalText={`${campaigns_totals.total} campaigns in total`}
                items={[
                  { label: `${campaigns_totals.active} published`, value: "active" },
                  { label: `${campaigns_totals.inactive} unpublished`, value: "inactive" },
                  { label: `${campaigns_totals.finalizing} finalizing`, value: "finalizing" },
                  { label: `${campaigns_totals.finalized} finalized`, value: "finalized" },
                  {
                    label: `${campaigns_totals.stopped_immediately} stopped`,
                    value: "stopped_immediately"
                  },
                  { label: `${campaigns_totals.archived} archived`, value: "archived" }
                ]}
              />
            </div>
          </>
        )}
      />
      {!!campaigns.length && (
        <ListDialog
          isOpen
          title="Campaigns"
          onClose={onCampaignsDialogClose}
          renderRow={({ id, name }) => (
            <div key={id} className="campaign-name">
              {name}
            </div>
          )}
          elements={campaigns}
        />
      )}
    </div>
  );
};

export default Companies;

import React from "react";
import clsx from "clsx";
import moment from "moment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { campaignsTableFields, rewardsTableFields, transactionsTableFields } from "./constants";
import { deliveryTypeByType, rewardIconByType, splitByCommas } from "../../core/helpers/functions";
import SkeletonComponent from "../_shared/SkeletonComponent";

import ExpandIcon from "../../assets/image/down-arrow-purple.svg";

const renderCampaignRow = ({
  id,
  loading,
  title,
  status_display,
  company,
  started_at,
  completed_at
}) => (
  <div className="table-row app-user-campaign-row" key={`campaign_${id}`}>
    <div className="row-item" style={{ width: campaignsTableFields[0].width }}>
      <div className="app-user-campaign-row__title-wrapper">
        <SkeletonComponent variant="text" loading={loading} width={200}>
          <p className="app-user-campaign-row__title overflowed_p" title={title}>
            {title}
          </p>
        </SkeletonComponent>
      </div>
    </div>
    <div className="row-item" style={{ width: campaignsTableFields[1].width }}>
      <SkeletonComponent variant="text" loading={loading} width={80}>
        <p>{company?.name}</p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: campaignsTableFields[2].width }}>
      <SkeletonComponent variant="text" loading={loading} width={200}>
        <p>{`${moment(started_at).format("DD.MM.YY HH:mm")} ${
          completed_at ? `- ${moment(completed_at).format("DD.MM.YY HH:mm")}` : ""
        }`}</p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: campaignsTableFields[3].width }}>
      <div className={clsx("app-user-campaign-row__status", status_display?.toLowerCase() || "")}>
        <SkeletonComponent variant="text" loading={loading} width={70}>
          <p>{status_display}</p>
        </SkeletonComponent>
      </div>
    </div>
  </div>
);

const renderDeliveryDetailsInfo = ({
  delivery_type,
  delivery_status,
  address_name,
  courier,
  tracking_number,
  eta,
  phone_number,
  unique_id
}) => {
  switch (delivery_type) {
    case "pickup":
      return (
        <div className="app-user-reward-row__details--delivery-info">
          <div>
            <div className="app-user-reward-row__details--label">Pickup address</div>
            <p className="app-user-reward-row__details--value">{address_name || "—"}</p>
          </div>
          <div>
            <div className="app-user-reward-row__details--label">Order code</div>
            <p className="app-user-reward-row__details--value">{unique_id || "—"}</p>
          </div>
        </div>
      );
    case "courier":
      return (
        <div className="app-user-reward-row__details--delivery-info">
          <div>
            <div className="app-user-reward-row__details--label">Courier info</div>
            <p className="app-user-reward-row__details--value">{courier || "—"}</p>
          </div>
          {(delivery_status === "on_delivery" || delivery_status === "done") && (
            <div className="app-user-reward-row__details--in-line">
              <div>
                <div className="app-user-reward-row__details--label">Tracking number</div>
                <p className="app-user-reward-row__details--value">{tracking_number || "—"}</p>
              </div>
              <div>
                <div className="app-user-reward-row__details--label">ETA</div>
                <p className="app-user-reward-row__details--value">
                  {eta ? moment(eta).format("DD.MM.YYYY") : "—"}
                </p>
              </div>
              <div>
                <div className="app-user-reward-row__details--label">App user’s phone number</div>
                <p className="app-user-reward-row__details--value">{phone_number || "—"}</p>
              </div>
            </div>
          )}
          <div>
            <div className="app-user-reward-row__details--label">Shipping address</div>
            <p className="app-user-reward-row__details--value">{address_name || "—"}</p>
          </div>
        </div>
      );
    case "parcel_machine":
      return (
        <div className="app-user-reward-row__details--delivery-info">
          <div>
            <div className="app-user-reward-row__details--label">Courier info</div>
            <p className="app-user-reward-row__details--value">{courier || "—"}</p>
          </div>
          {(delivery_status === "on_delivery" || delivery_status === "done") && (
            <div className="app-user-reward-row__details--in-line">
              <div>
                <div className="app-user-reward-row__details--label">Tracking number</div>
                <p className="app-user-reward-row__details--value">{tracking_number || "—"}</p>
              </div>
              <div>
                <div className="app-user-reward-row__details--label">ETA</div>
                <p className="app-user-reward-row__details--value">
                  {eta ? moment(eta).format("DD.MM.YYYY") : "—"}
                </p>
              </div>
              <div>
                <div className="app-user-reward-row__details--label">App user’s phone number</div>
                <p className="app-user-reward-row__details--value">{phone_number || "—"}</p>
              </div>
            </div>
          )}
          <div>
            <div className="app-user-reward-row__details--label">Parcel machine location</div>
            <p className="app-user-reward-row__details--value">{address_name || "—"}</p>
          </div>
        </div>
      );
    default:
      return null;
  }
};

const renderProductInfo = (
  status,
  { price, original_price, product_name, product_size, gift_name, gift_size, free_products_given }
) => (
  <div className={clsx("app-user-reward-row__details--product-info", product_size && "grid-3")}>
    <div>
      <div className="app-user-reward-row__details--label">Product</div>
      <div className="app-user-reward-row__details--value">{product_name}</div>
    </div>
    {product_size && (
      <div>
        <div className="app-user-reward-row__details--label">Size</div>
        <div className="app-user-reward-row__details--value">{product_size}</div>
      </div>
    )}
    <div>
      <div className="app-user-reward-row__details--label">Price</div>
      <div className="app-user-reward-row__details--value">
        <p>{price || original_price} €</p>
        {original_price !== price && (
          <p className="app-user-reward-row__details--original-price">{original_price} €</p>
        )}
      </div>
    </div>
    {gift_name && (
      <>
        <div>
          <div className="app-user-reward-row__details--label">Bonus product</div>
          <div className="app-user-reward-row__details--value">{gift_name}</div>
        </div>
        {gift_size && (
          <div>
            <div className="app-user-reward-row__details--label">Size</div>
            <div className="app-user-reward-row__details--value">{gift_size}</div>
          </div>
        )}
        <div>
          <div className="app-user-reward-row__details--label">Items</div>
          <div className="app-user-reward-row__details--value">{free_products_given}</div>
        </div>
      </>
    )}
  </div>
);

const renderRewardRow = ({
  id,
  loading,
  type,
  delivery_type,
  delivery_status,
  delivery_status_display,
  step_details = {},
  delivery_details = {},
  unique_id,
  product_size,
  gift_size
}) => (
  <Accordion
    key={`campaign_${id}`}
    classes={{
      root: "accordion-panel",
      expanded: "accordion-panel__expanded",
      disabled: "accordion-panel__disabled"
    }}
    disabled={!delivery_type}
  >
    <AccordionSummary
      expandIcon={<img src={ExpandIcon} alt=">" style={{ opacity: delivery_type ? "1" : "0" }} />}
      classes={{
        root: "accordion-panel__summary",
        content: "accordion-panel__content"
      }}
    >
      <div className="table-row app-user-reward-row">
        <div className="row-item" style={{ width: rewardsTableFields[0].width }}>
          <div className="app-user-reward-row__title-wrapper">
            <SkeletonComponent variant="text" loading={loading} width={200}>
              <p className="app-user-reward-row__title overflowed_p" title={step_details.title}>
                {step_details.title || "—"}
              </p>
            </SkeletonComponent>
          </div>
        </div>
        <div className="row-item" style={{ width: rewardsTableFields[1].width }}>
          <SkeletonComponent variant="text" loading={loading} width={24} height={24}>
            <p className="app-user-reward-row__name">{rewardIconByType(type)}</p>
          </SkeletonComponent>
        </div>
        <div className="row-item" style={{ width: rewardsTableFields[2].width }}>
          <SkeletonComponent variant="text" loading={loading} width={70}>
            <p className={clsx("app-user-reward-row__delivery", !delivery_type && "no-delivery")}>
              {deliveryTypeByType(delivery_type)}
            </p>
          </SkeletonComponent>
        </div>
        <div className="row-item" style={{ width: rewardsTableFields[3].width }}>
          <SkeletonComponent variant="text" loading={loading} width={80}>
            <div
              className={clsx("app-user-reward-row__status", !delivery_status_display && "paid")}
            >
              {delivery_status_display || "Paid"}
            </div>
          </SkeletonComponent>
        </div>
      </div>
    </AccordionSummary>
    <AccordionDetails
      classes={{
        root: "accordion-panel__details"
      }}
    >
      <div className="app-user-reward-row__details">
        {renderProductInfo(delivery_status, { ...step_details, product_size, gift_size })}
        <hr className="app-user-reward-row__details--separator" />
        {renderDeliveryDetailsInfo({
          ...delivery_details,
          delivery_status,
          delivery_type,
          unique_id
        })}
      </div>
    </AccordionDetails>
  </Accordion>
);

const renderTransactionDetails = ({
  title,
  product_name,
  campy_credits_used,
  product_price,
  paid_using_card,
  earning_type
}) => {
  switch (title?.toLowerCase()) {
    case "product purchase":
      return (
        <div className="app-user-transaction-row__details">
          <div>
            <div className="app-user-transaction-row__details--label">Product</div>
            <div className="app-user-transaction-row__details--value">{product_name}</div>
          </div>
          <div className="app-user-transaction-row__details--in-line">
            <div>
              <div className="app-user-transaction-row__details--label">Price</div>
              <div className="app-user-transaction-row__details--value">{`${splitByCommas(
                (product_price || 0).toFixed(2)
              )} €`}</div>
            </div>
            <div>
              <div className="app-user-transaction-row__details--label">Campy credits used</div>
              <div className="app-user-transaction-row__details--value">{`${splitByCommas(
                (campy_credits_used || 0).toFixed(2)
              )} €`}</div>
            </div>
            <div>
              <div className="app-user-transaction-row__details--label">Paid using card</div>
              <div className="app-user-transaction-row__details--value">{`${splitByCommas(
                (paid_using_card || 0).toFixed(2)
              )} €`}</div>
            </div>
          </div>
        </div>
      );
    case "earned campy credit":
      return (
        <div className="app-user-transaction-row__details">
          <div>
            <div className="app-user-transaction-row__details--label">Earning type</div>
            <div className="app-user-transaction-row__details--value">{earning_type}</div>
          </div>
        </div>
      );
    default:
      return <div />;
  }
};

const renderTransactionRow = ({
  id,
  loading,
  created_at,
  title_high_level_admin_user: title,
  math_amount,
  extra_data = {}
}) => (
  <Accordion
    key={`campaign_${id}`}
    classes={{
      root: "accordion-panel",
      expanded: "accordion-panel__expanded",
      disabled: "accordion-panel__disabled"
    }}
    disabled={title === "Added campy credit"}
  >
    <AccordionSummary
      expandIcon={
        <img
          src={ExpandIcon}
          alt=">"
          style={{ opacity: title === "Added campy credit" ? "0" : "1" }}
        />
      }
      classes={{
        root: "accordion-panel__summary",
        content: "accordion-panel__content"
      }}
    >
      <div className="table-row app-user-transaction-row">
        <div className="row-item" style={{ width: transactionsTableFields[0].width }}>
          <div className="app-user-transaction-row__id">
            <SkeletonComponent variant="text" loading={loading} width={200}>
              <p>{id}</p>
            </SkeletonComponent>
          </div>
        </div>
        <div className="row-item" style={{ width: transactionsTableFields[1].width }}>
          <SkeletonComponent variant="text" loading={loading} width={24} height={24}>
            <p>{moment(created_at).format("DD.MM.YY HH:mm")}</p>
          </SkeletonComponent>
        </div>
        <div className="row-item" style={{ width: transactionsTableFields[2].width }}>
          <SkeletonComponent variant="text" loading={loading} width={70}>
            <p>{title}</p>
          </SkeletonComponent>
        </div>
        <div className="row-item" style={{ width: transactionsTableFields[3].width }}>
          <SkeletonComponent variant="text" loading={loading} width={80}>
            <div
              className={clsx(
                "app-user-transaction-row__amount",
                math_amount >= 0 ? "income" : "outcome"
              )}
            >
              {`${math_amount >= 0 ? "+" : "-"} ${splitByCommas(
                Math.abs(math_amount || 0).toFixed(2)
              )} €`}
            </div>
          </SkeletonComponent>
        </div>
      </div>
    </AccordionSummary>
    <AccordionDetails
      classes={{
        root: "accordion-panel__details"
      }}
    >
      {renderTransactionDetails({ ...extra_data, title })}
    </AccordionDetails>
  </Accordion>
);

export { renderCampaignRow, renderRewardRow, renderTransactionRow };

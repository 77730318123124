import React from "react";
import { NavLink } from "react-router-dom";

import companies_icon from "../../assets/image/companies_inactive.svg";
import companies_icon_purple from "../../assets/image/companies_active.svg";
import statistics_icon from "../../assets/image/analytics_inactive.svg";
import statistics_icon_purple from "../../assets/image/analytics_active.svg";
import balances_icon from "../../assets/image/cost_inactive.svg";
import balances_icon_purple from "../../assets/image/cost_active.svg";
import app_users_icon from "../../assets/image/app-users_inactive.svg";
import app_users_icon_purple from "../../assets/image/app-users_active.svg";
import campaign_icon from "../../assets/image/campaign_icon_inactive.svg";
import campaign_icon_purple from "../../assets/image/campaign_icon_active.svg";
import billing_icon from "../../assets/image/billing_inactive.svg";
import billing_icon_purple from "../../assets/image/billing_active.svg";
import purchases_icon from "../../assets/image/purchases_inactive.svg";
import purchases_icon_purple from "../../assets/image/purchases_active.svg";

import "./styles.scss";

const Panel = () => (
  <div className="panel_wrapper">
    <NavLink to="/admin/companies" exact>
      <img src={companies_icon} alt="companies" />
      <img src={companies_icon_purple} alt="companies" className="hover" />
      Companies
    </NavLink>
    <NavLink to="/admin/campaigns" exact>
      <img src={campaign_icon} alt="campaigns" />
      <img src={campaign_icon_purple} alt="campaigns" className="hover" />
      Campaigns
    </NavLink>
    <NavLink to="/admin/app-users" exact>
      <img src={app_users_icon} alt="app users" />
      <img src={app_users_icon_purple} alt="app users" className="hover" />
      App users
    </NavLink>
    <NavLink to="/admin/statistics" exact>
      <img src={statistics_icon} alt="statistics" />
      <img src={statistics_icon_purple} alt="statistics" className="hover" />
      Statistics
    </NavLink>
    <NavLink to="/admin/balances" exact>
      <img src={balances_icon} alt="balances" />
      <img src={balances_icon_purple} alt="balances" className="hover" />
      Balances
    </NavLink>
    <NavLink to="/admin/billing" exact>
      <img src={billing_icon} alt="billing" />
      <img src={billing_icon_purple} alt="billing" className="hover" />
      Billing
    </NavLink>
    <NavLink to="/admin/purchases" exact>
      <img src={purchases_icon} alt="purchases" />
      <img src={purchases_icon_purple} alt="purchases" className="hover" />
      Purchases
    </NavLink>
  </div>
);

export default Panel;

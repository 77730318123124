const tableColumns = [
  { name: "campaign", label: "Campaign", withSort: true, sortKey: "campaign_name", width: "25%" },
  { name: "status", label: "Status", withSort: false, width: "15%" },
  { name: "createdAt", label: "Created", withSort: true, sortKey: "created_at", width: "10%" },
  {
    name: "publishedAt",
    label: "Published",
    withSort: true,
    sortKey: "published_at",
    width: "10%"
  },
  {
    name: "startedAt",
    label: "Started",
    withSort: true,
    sortKey: "distribution__availability_start_at",
    width: "10%"
  },
  {
    name: "finishedAt",
    label: "Finished",
    withSort: true,
    sortKey: "force_stopped_at",
    width: "10%"
  },
  { name: "archivedAt", label: "Archived", withSort: true, sortKey: "archived_at", width: "10%" },
  { name: "deletedAt", label: "Deleted", withSort: true, sortKey: "deleted_at", width: "10%" }
];

export default tableColumns;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";

import { postAddCredits } from "../../../../core/actions/balanceActions";
import DefaultButton from "../../Buttons/DefaultButton";
import RenderField from "../../RenderField";
import DialogComponent from "../../DialogComponent";

class AddCreditsDialog extends Component {
  onDialogClose = () => {
    const { onClose, reset } = this.props;
    onClose();
    reset("AddCreditsForm");
  };

  addCredits = data => {
    const { postAddCredits, companyId } = this.props;
    return postAddCredits(companyId, data).then(res => {
      if (res && res.error) {
        throw new SubmissionError({ amount: res.error.response.data.amount[0].message });
      } else {
        this.onDialogClose();
      }
    });
  };

  render() {
    const { buttonLoading, handleSubmit, submitting, pristine, valid, isOpen } = this.props;
    return (
      <DialogComponent open={isOpen} onClose={this.onDialogClose} closeIcon>
        <div className="default_dialog_wrapper">
          <div className="dialog_title not_capitalize">Add credits</div>
          <div className="dialog_description balances_description">
            Enter the amount you want to add <br /> to the company’s balance
          </div>
          <form onSubmit={handleSubmit(this.addCredits)} className="dialog_form add_credits_form">
            <Field name="amount" type="number" component={RenderField} label="Amount" />
            <div className="buttons_wrapper">
              <DefaultButton
                variant="contained"
                classes="confirm"
                formAction
                disabled={submitting || pristine || !valid}
                loading={buttonLoading}
              >
                Submit
              </DefaultButton>
              <DefaultButton variant="contained" classes="cancel_btn" onClick={this.onDialogClose}>
                Cancel
              </DefaultButton>
            </div>
          </form>
        </div>
      </DialogComponent>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.amount) {
    errors.amount = "Required";
  }
  return errors;
};

AddCreditsDialog = reduxForm({
  form: "AddCreditsForm",
  validate
})(AddCreditsDialog);

const mapStateToProps = ({ app }) => {
  return {
    buttonLoading: app.buttonLoading
  };
};

const mapDispatchToProps = {
  postAddCredits
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCreditsDialog);

import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import "./styles.scss";

const BasicInfo = () => {
  const { birth_date, gender, interests, hashtags } = useSelector(
    ({ appUsers }) => appUsers.basicInfo
  );
  return (
    <section className="app-user-basic-info">
      <div className="app-user-basic-info__fields">
        <div>
          <div className="app-user-basic-info__label">Birthday</div>
          <div className="app-user-basic-info__value">
            {birth_date ? moment(birth_date).format("DD.MM.YYYY") : "–"}
          </div>
        </div>
        <div>
          <div className="app-user-basic-info__label">Gender</div>
          <div className="app-user-basic-info__value">{gender || "–"}</div>
        </div>
      </div>
      <div>
        <div className="app-user-basic-info__label">Interests</div>
        <div className="app-user-basic-info__tags">
          {!interests.length && "–"}
          {interests.map(({ id, name }) => (
            <div className="app-user-basic-info__tags--tag" key={`interest-${id}`}>
              {name}
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="app-user-basic-info__label">Tags</div>
        <div className="app-user-basic-info__tags">
          {!hashtags.length && "–"}
          {hashtags.map(({ id, name }) => (
            <div className="app-user-basic-info__tags--tag" key={`interest-${id}`}>
              {name}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BasicInfo;

import React from "react";

import DialogComponent from "../../DialogComponent";

const ListDialog = ({ title, isOpen, onClose, renderDescription = null, elements, renderRow }) => (
  <DialogComponent open={isOpen} onClose={onClose} closeIcon classes="list-dialog">
    <div className="list-dialog__wrapper">
      <div className="list-dialog__title">{title}</div>
      {renderDescription && renderDescription()}
      <div className="list-dialog__list">{elements.map(el => renderRow(el))}</div>
    </div>
  </DialogComponent>
);

export default ListDialog;

import React from "react";
import Select, { components } from "react-select";

import ExpandIcon from "../../../assets/image/down-arrow-purple.svg";

import "./styles.scss";

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <div
          className={
            props.selectProps.menuIsOpen ? "select_indicator indicator_active" : "select_indicator"
          }
        >
          <img src={ExpandIcon} alt=">" style={{ marginRight: 6 }} />
        </div>
      </components.DropdownIndicator>
    )
  );
};

const SelectComponent = ({
  value,
  onKeyDown,
  options,
  loading,
  loadingMessage = "Loading filters...",
  change,
  placeholder,
  isClearable = false,
  isSearchable = false,
  disabled = false
}) => (
  <Select
    className="select_component"
    classNamePrefix="select"
    isDisabled={disabled}
    isLoading={loading}
    isClearable={isClearable}
    isSearchable={isSearchable}
    name="color"
    value={value}
    options={options}
    onChange={change}
    loadingMessage={() => loadingMessage}
    placeholder={placeholder}
    onKeyDown={onKeyDown}
    components={{ DropdownIndicator }}
  />
);

export default SelectComponent;

import { takeEvery } from "redux-saga/effects";
import { disableLoader, enableLoader, openSuccessSnack, openErrorSnack } from "./index";

export function* handleProfileLoader() {
  yield takeEvery("GET_PROFILE", enableLoader);
  yield takeEvery("GET_PROFILE_SUCCESS", disableLoader);
  yield takeEvery("GET_PROFILE_FAIL", disableLoader);
}

export function* handleProfileSnacks() {
  yield takeEvery("PATCH_PROFILE_SUCCESS", () => openSuccessSnack("Changes have been updated"));
  yield takeEvery("PATCH_PROFILE_FAIL", () => openErrorSnack({ error: "Something went wrong" }));
}

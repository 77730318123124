import { takeEvery } from "redux-saga/effects";
import { disableLoader, enableLoader } from "./index";

export function* handleStatisticsLoader() {
  yield takeEvery("GET_COMPANIES_STATISTICS", enableLoader);
  yield takeEvery("GET_COMPANIES_STATISTICS_SUCCESS", disableLoader);
  yield takeEvery("GET_COMPANIES_STATISTICS_FAIL", disableLoader);

  yield takeEvery("GET_USERS_STATISTICS", enableLoader);
  yield takeEvery("GET_USERS_STATISTICS_SUCCESS", disableLoader);
  yield takeEvery("GET_USERS_STATISTICS_FAIL", disableLoader);
}

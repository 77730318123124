import React from "react";
import { IconButton } from "@material-ui/core";

import { contactsTableFields } from "../constants";
import SkeletonComponent from "../../_shared/SkeletonComponent";

import EditIcon from "../../../assets/image/edit.svg";

const renderContactRow = ({
  id,
  loading,
  name,
  email,
  phone_number,
  position,
  onEditContactClick
}) => (
  <div className="table-row" key={`contact_${id}`}>
    <div className="row-item" style={{ width: contactsTableFields[0].width }}>
      <SkeletonComponent variant="text" loading={loading} width={300}>
        <p className="row-item__bold-text ellipsis">{name}</p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: contactsTableFields[1].width }}>
      <SkeletonComponent variant="text" loading={loading} width={300}>
        <p className="row-item__usual-text ellipsis">{email}</p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: contactsTableFields[2].width }}>
      <SkeletonComponent variant="text" loading={loading} width={300}>
        <p className="row-item__usual-text ellipsis">{phone_number || "–"}</p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: contactsTableFields[3].width }}>
      <SkeletonComponent variant="text" loading={loading} width={300}>
        <p className="row-item__bold-text ellipsis">{position || "–"}</p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: contactsTableFields[4].width }}>
      <SkeletonComponent variant="text" loading={loading} width={70}>
        <IconButton
          className="contacts-edit"
          onClick={() =>
            onEditContactClick({
              id,
              name,
              email,
              phone_number,
              position
            })
          }
        >
          <img src={EditIcon} alt="edit" />
        </IconButton>
      </SkeletonComponent>
    </div>
  </div>
);

export { renderContactRow };

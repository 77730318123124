import { APP_USERS } from "../actionTypes";
import { joinQueries } from "../helpers/functions";

const getAppUsers = meta => ({
  type: APP_USERS.GET_APP_USERS,
  payload: {
    client: "default",
    request: {
      url: `/app-users/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const getAppUserBasicInfo = id => ({
  type: APP_USERS.GET_APP_USER_BASIC_INFO,
  payload: {
    client: "default",
    request: {
      url: `/app-users/${id}/basic-info/`,
      method: "get"
    }
  }
});

const getAppUserCampaigns = (id, meta) => ({
  type: APP_USERS.GET_APP_USER_CAMPAIGNS,
  payload: {
    client: "default",
    request: {
      url: `/app-users/${id}/campaigns/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const getAppUserRewards = (id, meta) => ({
  type: APP_USERS.GET_APP_USER_REWARDS,
  payload: {
    client: "default",
    request: {
      url: `/app-users/${id}/rewards/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const getAppUserTransactions = (id, meta) => ({
  type: APP_USERS.GET_APP_USER_TRANSACTIONS,
  payload: {
    client: "default",
    request: {
      url: `/app-users/${id}/transactions/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const getAppUserSharing = id => ({
  type: APP_USERS.GET_APP_USER_SHARING,
  payload: {
    client: "default",
    request: {
      url: `/app-users/${id}/sharing/`,
      method: "get"
    }
  }
});

const resetAppUsers = () => ({
  type: APP_USERS.RESET_APP_USERS_INFO
});

export {
  getAppUsers,
  getAppUserBasicInfo,
  getAppUserCampaigns,
  getAppUserRewards,
  getAppUserTransactions,
  getAppUserSharing,
  resetAppUsers
};

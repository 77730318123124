import React from "react";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CheckOff from "../../../assets/image/checkbox_unchecked.svg";
import CheckOn from "../../../assets/image/checkbox_checked.svg";

import "./styles.scss";

const Checkbox = ({ input, label, disabled }) => {
  return (
    <div className="check_field_wrapper">
      <FormControlLabel
        control={
          <MaterialCheckbox
            checked={Boolean(input.value)}
            onChange={input.onChange}
            disabled={disabled}
            value={input.name}
            checkedIcon={<img src={CheckOn} alt="check on" />}
            icon={<img src={CheckOff} alt="check off" />}
            classes={{
              root: "custom_check"
            }}
          />
        }
        label={label}
      />
    </div>
  );
};

export default Checkbox;

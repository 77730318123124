import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { tabs } from "./constants";
import { resetCampaigns } from "../../core/actions/campaignsActions";
import Tabs from "../_shared/Tabs";
import BackLink from "../_shared/BackLink";

import Header from "./Header";
import BasicInfo from "./BasicInfo";
import Steps from "./Steps";
import Audience from "./Audience";
import Distribution from "./Distribution";
import Cost from "./Cost";
import Deliveries from "./Deliveries";

import "./styles.scss";

const Campaign = () => {
  const [tab, setTab] = useState(sessionStorage.getItem("campaignTab") || "basic_info");
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(resetCampaigns());
    },
    [dispatch]
  );

  const renderTab = useMemo(() => {
    switch (tab) {
      case "basic_info":
        return <BasicInfo />;
      case "steps":
        return <Steps />;
      case "audience":
        return <Audience />;
      case "distribution":
        return <Distribution />;
      case "cost":
        return <Cost />;
      case "deliveries":
        return <Deliveries />;
      default:
        return null;
    }
  }, [tab]);

  const onTabChange = newTab => {
    setTab(newTab);
    sessionStorage.setItem("campaignTab", newTab);
  };

  return (
    <div className="campaign">
      <section className="campaign__wrapper">
        <BackLink text="Back to Campaigns" to="/admin/campaigns" />
        <Header />
        <div className="campaign__tabs">
          <Tabs currentTab={tab} changeTab={onTabChange} tabs={tabs} />
        </div>
        {renderTab}
      </section>
    </div>
  );
};

export default Campaign;

import React, { memo, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router";

import SkeletonComponent from "../../_shared/SkeletonComponent";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import AddCreditsDialog from "../../_shared/Dialogs/AddCreditsDialog";
import { splitByCommas } from "../../../core/helpers/functions";
import { getCompanyBasicInfo } from "../../../core/actions/companyActions";

import "./styles.scss";

const CompanyHeader = () => {
  const [isAddCreditsDialogOpen, setAddCreditsDialogOpen] = useState(false);
  const {
    initial,
    name,
    created_at,
    approved_at,
    approved_by,
    rejected_at,
    rejected_by,
    suspended_at,
    suspended_by,
    status,
    status_display,
    balance
  } = useSelector(({ company }) => company.basicInfo);
  const dispatch = useDispatch();
  const { id } = useParams();

  const toggleAddCreditsDialog = () => setAddCreditsDialogOpen(val => !val);

  const { actionAt, actionBy } = useMemo(() => {
    switch (status) {
      case "approved":
        return { actionAt: approved_at, actionBy: approved_by };
      case "rejected":
        return { actionAt: rejected_at, actionBy: rejected_by };
      case "suspended":
        return { actionAt: suspended_at, actionBy: suspended_by };
      default:
        return { actionAt: null, actionBy: null };
    }
    // eslint-disable-next-line
  }, [status]);

  return (
    <div className="company-header">
      <div className="company-header__first-block">
        <p className="company-header__name">
          <SkeletonComponent variant="text" loading={initial} width={200}>
            {name}
          </SkeletonComponent>
        </p>
        <div className="company-header__time-points">
          <SkeletonComponent variant="text" loading={initial} width={450}>
            <p>
              Created&nbsp;
              <span className="company-header__time-points--value">
                {moment(created_at).format("DD.MM.YY HH:mm")}
              </span>
              {actionAt && (
                <>
                  &nbsp;&nbsp;|&nbsp;&nbsp;{status_display}&nbsp;
                  <span className="company-header__time-points--value">
                    {moment(actionAt).format("DD.MM.YY HH:mm")}
                  </span>
                  {actionBy && (
                    <>
                      &nbsp;by&nbsp;
                      <span className="company-header__time-points--value">{`${actionBy?.first_name} ${actionBy?.last_name}`}</span>
                    </>
                  )}
                </>
              )}
            </p>
          </SkeletonComponent>
        </div>
      </div>
      <div className="company-header__last-block">
        <div className="company-header__balance">
          <p className="company-header__balance--label">Balance</p>
          <p className="company-header__balance--value">
            <SkeletonComponent variant="text" loading={initial} width={100}>
              {`${splitByCommas(balance || "")} €`}
            </SkeletonComponent>
          </p>
        </div>
        <DefaultButton
          variant="contained"
          classes="button_86 add_credits"
          onClick={toggleAddCreditsDialog}
          disabled={initial}
        >
          Add credits
        </DefaultButton>
      </div>
      <AddCreditsDialog
        isOpen={isAddCreditsDialogOpen}
        onClose={toggleAddCreditsDialog}
        refetchCurrentPage={() => dispatch(getCompanyBasicInfo(id))}
        companyId={id}
      />
    </div>
  );
};

export default memo(CompanyHeader);

import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import moment from "moment";
import clsx from "clsx";

import SkeletonComponent from "../../_shared/SkeletonComponent";
import { getCampaignHeader } from "../../../core/actions/campaignsActions";
import { translateCampaignStatus } from "../../../core/helpers/functions";

import UsersIcon from "../../../assets/image/users_icon.svg";

import "./styles.scss";

const Header = () => {
  const {
    initial,
    name,
    company_name,
    created_at,
    participants_count,
    max_participants_count,
    status
  } = useSelector(({ campaigns }) => campaigns.header);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCampaignHeader(id));
  }, [dispatch, id]);

  return (
    <div className="campaign-header">
      <div className="campaign-header__first-block">
        <p className="campaign-header__name">
          <SkeletonComponent variant="text" loading={initial} width={350}>
            <span>{name}</span>
          </SkeletonComponent>
          <SkeletonComponent variant="rect" loading={initial} width={75} height={16}>
            <span className={clsx("campaign-header__status-badge", status)}>
              {translateCampaignStatus(status)}
            </span>
          </SkeletonComponent>
        </p>
        <SkeletonComponent variant="text" loading={initial} width={300}>
          <div className="campaign-header__info">
            {`${company_name}  |  ${moment(created_at).format("DD.MM.YY HH:mm")}`}
          </div>
        </SkeletonComponent>
      </div>
      <div className="campaign-header__last-block">
        <div className="campaign-header__users-count">
          <SkeletonComponent variant="text" loading={initial} width={80}>
            <img src={UsersIcon} alt="users" className="campaign-header__users-count--icon" />
            <span className="campaign-header__users-count--number">{participants_count}</span>/
            {max_participants_count || 0}
          </SkeletonComponent>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);

import React, { Component } from "react";
import { connect } from "react-redux";

import { getUsersStatistics } from "../../../core/actions/statisticsActions";
import Loader from "../../_shared/Loader";
import TotalBlock from "../../_shared/TotalBlock";
import UsersChart from "./UsersChart";

import UsersIcon from "../../../assets/image/users_icon.svg";

class Users extends Component {
  state = {
    currentPage: null,
    chartToLoad: false
  };

  componentDidMount() {
    this.fetchStatisticsUsers().then(() => {
      this.setState({ chartToLoad: true });
    });
  }

  fetchStatisticsUsers = async () => {
    const { getUsersStatistics } = this.props;
    await getUsersStatistics();
  };

  render() {
    const {
      users_statistics: { total, ios, android },
      users_chart_data
    } = this.props;
    const { chartToLoad } = this.state;
    return (
      <div className="page-wrapper__content statistics_users_wrapper">
        <TotalBlock
          loading={!chartToLoad}
          totalIcon={UsersIcon}
          totalText={`${total} registered mobile users`}
          items={[
            { label: `${ios} iOS`, value: "iOS" },
            { label: `${android} Android`, value: "Android" }
          ]}
        />
        {chartToLoad ? (
          <div className="chart_wrapper">
            <UsersChart chartData={users_chart_data} />
          </div>
        ) : (
          <div className="loader_wrapper">
            <Loader />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ statistics }) => {
  return {
    users_statistics: statistics.users_statistics,
    users_chart_data: statistics.users_chart_data
  };
};

const mapDispatchToProps = {
  getUsersStatistics
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

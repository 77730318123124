import { PURCHASES } from "../actionTypes";
import initialResults from "../helpers/constants";

const INITIAL_STATE = {
  purchases: { count: 10, results: initialResults }
};

const purchasesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case PURCHASES.GET_PURCHASES_SUCCESS:
      return { ...state, purchases: payload.data };

    case PURCHASES.PATCH_PURCHASE_NOTE_SUCCESS:
      return {
        ...state,
        purchases: {
          ...state.purchases,
          results: state.purchases.results.map(p => (p.id === payload.data.id ? payload.data : p))
        }
      };

    case PURCHASES.RESET_PURCHASES:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default purchasesReducer;

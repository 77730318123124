import { takeEvery } from "redux-saga/effects";
import { disableLoader, enableLoader, openSuccessSnack } from "./index";

export function* handleCompanyLoader() {
  yield takeEvery("GET_COMPANY_BASIC_INFO", enableLoader);
  yield takeEvery("GET_COMPANY_BASIC_INFO_SUCCESS", disableLoader);
  yield takeEvery("GET_COMPANY_BASIC_INFO_FAIL", disableLoader);
}

export function* handleCompanySnacks() {
  yield takeEvery("PATCH_COMPANY_BASIC_INFO_SUCCESS", () =>
    openSuccessSnack("Company has been updated")
  );
  yield takeEvery("POST_COMPANY_CONTACT_SUCCESS", () =>
    openSuccessSnack("Contact has been created")
  );
  yield takeEvery("PATCH_COMPANY_CONTACT_SUCCESS", () =>
    openSuccessSnack("Contact has been updated")
  );
  yield takeEvery("PATCH_COMPANY_PAYMENT_SUCCESS", () =>
    openSuccessSnack("Payment has been updated")
  );
}

import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import tableColumns from "./tableColumns";
import SkeletonComponent from "../../_shared/SkeletonComponent";
import { splitByCommas } from "../../../core/helpers/functions";
import SwitchComponent from "../../_shared/SwitchComponent/SwitchComponent";

import ExpandIcon from "../../../assets/image/down-arrow-purple.svg";

const renderBillingByCompanyRow = ({
  id,
  loading,
  name,
  finalized_participants,
  amount_of_transactions,
  campaign_cost,
  fee_for_product_purchase,
  fee_for_product_purchase_no_vat,
  fee_for_monetary_step,
  link_sharing_cost,
  monetary_cost,
  idsToShowWithVAT,
  onHandleShowVatToggle
}) => {
  const withVAT = idsToShowWithVAT.includes(id);
  return (
    <Accordion
      key={`campaign_${id}`}
      classes={{
        root: "accordion-panel",
        expanded: "accordion-panel__expanded"
      }}
    >
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt=">" />}
        classes={{
          root: "accordion-panel__summary",
          content: "accordion-panel__content"
        }}
      >
        <div className="table-row billing-by-company__row">
          <div className="row-item" style={{ width: tableColumns[0].width }}>
            <SkeletonComponent variant="text" loading={loading} width={220}>
              <p className="billing-by-company__row--name ellipsis" title={name}>
                {name}
              </p>
            </SkeletonComponent>
          </div>
          <div className="row-item" style={{ width: tableColumns[1].width }}>
            <SkeletonComponent variant="text" loading={loading} width={50}>
              <p>{finalized_participants || 0}</p>
            </SkeletonComponent>
          </div>
          <div className="row-item" style={{ width: tableColumns[2].width }}>
            <SkeletonComponent variant="text" loading={loading} width={80}>
              <p>{`${splitByCommas((amount_of_transactions || 0).toFixed(2))} €`}</p>
            </SkeletonComponent>
          </div>
          <div className="row-item" style={{ width: tableColumns[3].width }}>
            <SkeletonComponent variant="text" loading={loading} width={70}>
              <p>{`${splitByCommas((campaign_cost || 0).toFixed(2))} €`}</p>
            </SkeletonComponent>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: "accordion-panel__details"
        }}
      >
        <div className="billing-by-company__expanded-info">
          <SwitchComponent
            label="Including VAT"
            defaultChecked={withVAT}
            onChange={() => onHandleShowVatToggle(id)}
            height={20}
            width={40}
            handleDiameter={12}
          />
          <div className="billing-by-company__expanded-info--fields-wrapper">
            <div>
              <div className="billing-by-company__expanded-info--label">
                Campy fee for product purchase
              </div>
              <div className="billing-by-company__expanded-info--value">
                {`${splitByCommas(
                  (
                    (withVAT ? fee_for_product_purchase : fee_for_product_purchase_no_vat) || 0
                  ).toFixed(2)
                )} €`}
              </div>
            </div>
            <div>
              <div className="billing-by-company__expanded-info--label">
                Campy fee for Monetary step
              </div>
              <div className="billing-by-company__expanded-info--value">
                {`${splitByCommas(
                  (fee_for_monetary_step / (withVAT ? 1 : 1.2) || 0).toFixed(2)
                )} €`}
              </div>
            </div>
            <div>
              <div className="billing-by-company__expanded-info--label">
                Link sharing rewards given
              </div>
              <div className="billing-by-company__expanded-info--value">
                {`${splitByCommas((link_sharing_cost / (withVAT ? 1 : 1.2) || 0).toFixed(2))} €`}
              </div>
            </div>
            <div>
              <div className="billing-by-company__expanded-info--label">
                Monetary step rewards given
              </div>
              <div className="billing-by-company__expanded-info--value">
                {`${splitByCommas((monetary_cost / (withVAT ? 1 : 1.2) || 0).toFixed(2))} €`}
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export { renderBillingByCompanyRow };

import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import moment from "moment";

Highcharts.setOptions({
  global: {
    useUTC: false
  }
});

const UsersChart = ({ chartData }) => {
  const options = {
    title: {
      text: null
    },
    navigator: {
      enabled: true,
      maskFill: "rgba(100, 41, 179, 0.1)"
    },
    tooltip: {
      borderWidth: 0,
      borderRadius: 4,
      backgroundColor: "#FFFFFF",
      useHTML: true,
      shared: true,
      formatter: function() {
        return `<div style="opacity: 0.5; font-size: 10px; font-weight: 500; line-height: 13px; margin-bottom: 10px;">
                        ${moment(this.x).format("D MMM YYYY")}
                        </div>
                        <div style="display: flex; align-items: center; font-size: 14px; font-weight: 600; line-height: 18px; margin-bottom: 10px;">
                        <div style="width: 24px; height: 4px; background-color: #F23EA6; margin-right: 8px;"></div>
                        ${
                          this.points.find(el => el.color === "#F23EA6")["y"]
                        }&nbsp;<span style="font-weight: 400;">All</span>
                        </div>
                        <div style="display: flex; align-items: center; font-size: 14px; font-weight: 600; line-height: 18px; margin-bottom: 10px;">
                        <div style="width: 24px; height: 4px; background-color: #214AC3; margin-right: 8px;"></div>
                        ${
                          this.points.find(el => el.color === "#214AC3")["y"]
                        }&nbsp;<span style="font-weight: 400;">iOS</span>
                        </div>
                        <div style="display: flex; align-items: center; font-size: 14px; font-weight: 600; line-height: 18px; margin-bottom: 10px;">
                        <div style="width: 24px; height: 4px; background-color: #4ABFB4; margin-right: 8px;"></div>
                        ${
                          this.points.find(el => el.color === "#4ABFB4")["y"]
                        }&nbsp;<span style="font-weight: 400;">Android</span>
                        </div>`;
      },
      style: {
        fontFamily: "InterRegular,sans-serif",
        color: "#020018"
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      type: "spline",
      height: 500,
      backgroundColor: "transparent",
      marginTop: 32,
      spacingLeft: 0,
      spacingRight: 20
    },
    xAxis: {
      type: "datetime",
      gridLineWidth: 1,
      gridLineColor: "rgba(2, 0, 24, 0.05)",
      tickColor: "rgba(127, 127, 139, 0.5)",
      lineColor: "rgba(127, 127, 139, 0.5)",
      lineWidth: 2,
      tickWidth: 2,
      tickLength: 6,
      minPadding: 0,
      maxPadding: 0,
      offset: 0,
      crosshair: {
        width: 2,
        color: "#d8d8d8",
        dashStyle: "dash"
      },
      labels: {
        style: {
          fontFamily: "InterMedium,sans-serif",
          color: "rgba(2, 0, 24, 0.5)",
          fontSize: "10px"
        },
        formatter: function() {
          return moment(this.value).format("D MMM YYYY");
        }
      }
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineColor: "rgba(2, 0, 24, 0.05)",
      tickColor: "rgba(127, 127, 139, 0.5)",
      lineColor: "rgba(127, 127, 139, 0.5)",
      lineWidth: 2,
      tickWidth: 2,
      tickLength: 6,
      title: {
        text: "Users",
        style: {
          fontFamily: "InterMedium,sans-serif",
          color: "#383838",
          fontSize: "12px"
        },
        align: "high",
        rotation: 0,
        y: -20,
        offset: -20
      },
      labels: {
        style: {
          fontFamily: "InterMedium,sans-serif",
          color: "rgba(2, 0, 24, 0.5)",
          fontSize: "10px"
        }
      }
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: true,
          enabledThreshold: 1
        },
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 4
          }
        },
        events: {
          legendItemClick: function() {
            return false;
          }
        }
      }
    },
    series: [
      {
        showInLegend: true,
        name: "All",
        data: chartData["totalData"],
        color: "#F23EA6",
        marker: {
          symbol: "circle"
        }
      },
      {
        showInLegend: true,
        name: "iOS",
        data: chartData["iosData"],
        color: "#214AC3",
        marker: {
          symbol: "circle"
        }
      },
      {
        showInLegend: true,
        name: "Android",
        data: chartData["androidData"],
        color: "#4ABFB4",
        marker: {
          symbol: "circle"
        }
      }
    ],
    legend: {
      itemStyle: {
        color: "#020018",
        fontFamily: "InterMedium,sans-serif",
        fontSize: "12px",
        fontWeight: "normal"
      },
      itemDistance: 70,
      itemHoverStyle: {
        color: "rgba(2, 0, 24, 0.5)"
      },
      margin: 64,
      symbolHeight: 10,
      symbolWidth: 32
    }
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default UsersChart;

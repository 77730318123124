import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Field, reduxForm, SubmissionError } from "redux-form";

import { patchCampaignNote, getCampaignBasicInfo } from "../../../core/actions/campaignsActions";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import RenderField from "../../_shared/RenderField";

import "./styles.scss";

let BasicInfo = ({ initialize, handleSubmit, submitting, pristine, valid }) => {
  const { initial, note, created_by_name } = useSelector(({ campaigns }) => campaigns.basicInfo);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCampaignBasicInfo(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!initial) {
      initialize({
        authorName: created_by_name,
        note
      });
    }
    // eslint-disable-next-line
  }, [initial]);

  const onFormSubmit = ({ note }) => {
    return dispatch(patchCampaignNote(id, { note })).then(res => {
      if (res && res.error) {
        const errors = {};
        Object.entries(res.error.response.data).forEach(entry => {
          errors[entry[0]] = entry[1][0].message;
        });
        throw new SubmissionError(errors);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="campaign-basic-info">
      <div className="campaign-basic-info__fields">
        <div className="campaign-basic-info__fields--author-name">
          <Field
            name="authorName"
            type="text"
            component={RenderField}
            label="Author name"
            disabled
          />
        </div>
        <Field name="note" type="text" component={RenderField} label="Note" multiline />
      </div>
      <hr className="campaign-basic-info__separator" />
      <DefaultButton
        variant="contained"
        classes="confirm"
        formAction
        disabled={initial || submitting || pristine || !valid}
        loading={submitting}
      >
        Save
      </DefaultButton>
    </form>
  );
};

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  return errors;
};

BasicInfo = reduxForm({
  form: "CompanyBasicInfoForm",
  validate
})(BasicInfo);

export default BasicInfo;

import React from "react";
import moment from "moment";

import { campaignsTableFields } from "../constants";
import SkeletonComponent from "../../_shared/SkeletonComponent";

const renderCampaignRow = ({
  id,
  loading,
  name,
  created_by,
  admins,
  admins_count,
  onAdminsClick
}) => (
  <div className="table-row campaign-row" key={`campaign_${id}`}>
    <div className="row-item" style={{ width: campaignsTableFields[0].width }}>
      <SkeletonComponent variant="text" loading={loading} width={300}>
        <p className="campaign-row__name ellipsis">{name}</p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: campaignsTableFields[1].width }}>
      <div className="campaign-row__owner">
        <SkeletonComponent variant="text" loading={loading} width={100}>
          <p className="campaign-row__owner--name ellipsis">{`${created_by?.first_name} ${created_by?.last_name}`}</p>
        </SkeletonComponent>
        <SkeletonComponent variant="text" loading={loading} width={200}>
          <p className="campaign-row__owner--email ellipsis">{created_by?.email || "–"}</p>
        </SkeletonComponent>
      </div>
    </div>
    <div className="row-item" style={{ width: campaignsTableFields[2].width }}>
      <SkeletonComponent variant="text" loading={loading} width={70}>
        <button className="campaign-row__admins" onClick={() => onAdminsClick(name, admins)}>
          {admins_count}
        </button>
      </SkeletonComponent>
    </div>
  </div>
);

const renderAdminRow = ({ manager, created_at, created_by }) => (
  <div className="campaign-row__admin-row" key={manager?.id}>
    <div>
      <div className="campaign-row__admin-row--name">{`${manager?.first_name} ${manager?.last_name}`}</div>
      <div className="campaign-row__admin-row--email">{manager?.email}</div>
    </div>
    {created_by && (
      <div className="campaign-row__admin-row--invited-by">
        Invited&nbsp;<span>{moment(created_at).format("DD.MM.YY")}</span>&nbsp;by&nbsp;
        <span>{`${created_by.first_name} ${created_by.last_name}`}</span>
      </div>
    )}
  </div>
);

const renderAdminDialogDescription = campaignName => (
  <div className="list-dialog__description">
    of&nbsp;<span>{campaignName}</span>
  </div>
);

export { renderCampaignRow, renderAdminRow, renderAdminDialogDescription };

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import CompaniesBalances from "./CompaniesBalances";
import ReplenishmentRequests from "./ReplenishmentRequests";
import { resetBalances } from "../../core/actions/balanceActions";
import Tabs from "../_shared/Tabs";
import { tabs } from "./helpers";

import "./styles.scss";

const Balances = () => {
  const [tab, setTab] = useState(sessionStorage.getItem("balancesTab") || "companies_balances");
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetBalances());
    };
  }, [dispatch]);

  const renderTab = useMemo(() => {
    switch (tab) {
      case "companies_balances":
        return <CompaniesBalances />;
      case "replenishment_requests":
        return <ReplenishmentRequests />;
      default:
        return null;
    }
  }, [tab]);

  const onTabChange = newTab => {
    setTab(newTab);
    sessionStorage.setItem("balancesTab", newTab);
  };

  return (
    <div className="page-wrapper">
      <h1>Balances</h1>
      <div className="balances-wrapper__tabs">
        <Tabs currentTab={tab} changeTab={onTabChange} tabs={tabs} />
      </div>
      <div className="page-wrapper__content">{renderTab}</div>
    </div>
  );
};

export default Balances;

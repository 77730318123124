import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import TableComponent from "../../_shared/TableComponentWithCustomFields";
import {
  renderAdminRow,
  renderCompanyRow,
  renderCampaignRow,
  renderBelongsToDescription
} from "./helpers";
import ListDialog from "../../_shared/Dialogs/ListDialog";
import { adminsTableFields } from "../constants";
import { getCompanyAdmins } from "../../../core/actions/companyActions";

import "./styles.scss";

const Admins = () => {
  const [companies, setCompanies] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [adminName, setAdminName] = useState("");
  const { results, count } = useSelector(({ company }) => company.admins);
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchAdmins = meta => {
    dispatch(getCompanyAdmins(id, meta));
  };

  const onCompaniesClick = (admin, companiesList) => {
    setAdminName(admin);
    setCompanies(companiesList);
  };
  const onCompaniesDialogClose = () => {
    setCompanies([]);
    setAdminName("");
  };

  const onCampaignsClick = (admin, campaignsList) => {
    setAdminName(admin);
    setCampaigns(campaignsList);
  };
  const onCampaignsDialogClose = () => {
    setCampaigns([]);
    setAdminName("");
  };

  return (
    <section className="company__wrapper">
      <div className="company__wrapper--title">Admins</div>
      {results.length === 0 && <div className="no-items">No admins yet</div>}
      {!!results.length && (
        <TableComponent
          tableFields={adminsTableFields}
          tableContent={results}
          onTableFetch={fetchAdmins}
          renderTableRow={el =>
            renderAdminRow({ ...el, onCompaniesClick, onCampaignsClick, companyID: id })
          }
          numberOfPages={count}
        />
      )}
      {!!companies.length && (
        <ListDialog
          isOpen
          title="Companies"
          onClose={onCompaniesDialogClose}
          renderDescription={() => renderBelongsToDescription(adminName)}
          renderRow={renderCompanyRow}
          elements={companies}
        />
      )}
      {!!campaigns.length && (
        <ListDialog
          isOpen
          title="Campaigns"
          onClose={onCampaignsDialogClose}
          renderDescription={() => renderBelongsToDescription(adminName)}
          renderRow={renderCampaignRow}
          elements={campaigns}
        />
      )}
    </section>
  );
};

export default memo(Admins);

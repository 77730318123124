import React from "react";

const renderStopReason = reason => {
  switch (reason) {
    case "force_stop":
      return (
        <>
          Campaign finished due to <br /> <strong>stopping</strong>
        </>
      );
    case "availability_ended":
      return (
        <>
          Campaign finished due to <br /> <strong>end date</strong>
        </>
      );
    case "max_participants_joined ":
      return (
        <>
          Campaign finished due to <br /> <strong>max participants limit</strong>
        </>
      );
    default:
      return "Unknown reason";
  }
};

export { renderStopReason };

const tableColumns = [
  { name: "name", label: "Campaign name", withSort: false, width: "31%" },
  {
    name: "finalized_participants",
    label: "Finalized participants",
    withSort: false,
    width: "23%"
  },
  {
    name: "amount_of_transactions",
    label: "Amount of transactions",
    withSort: false,
    width: "23%"
  },
  { name: "campaign_cost", label: "Campaign cost", withSort: false, width: "23%" }
];

export default tableColumns;

import React from "react";
import moment from "moment";
import clsx from "clsx";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { campaignsStatsTableFields } from "../constants";
import SkeletonComponent from "../../_shared/SkeletonComponent";
import { translateCampaignStatus } from "../../../core/helpers/functions";
import Inactive from "../../_shared/CostViews/Inactive";
import Published from "../../_shared/CostViews/Published";
import Finalized from "../../_shared/CostViews/Finalized";

import ExpandIcon from "../../../assets/image/down-arrow-purple.svg";

const renderCampaignStatsRow = ({
  id,
  loading,
  name,
  created_at,
  participants_count,
  max_participants,
  status,
  published_at,
  published_by,
  cost_tab_data_old,
  cost_tab_data_new
}) => (
  <Accordion
    key={`campaign_${id}`}
    classes={{
      root: "accordion-panel",
      expanded: "accordion-panel__expanded"
    }}
  >
    <AccordionSummary
      expandIcon={<img src={ExpandIcon} alt=">" />}
      classes={{
        root: "accordion-panel__summary",
        content: "accordion-panel__content"
      }}
    >
      <div className="table-row campaign-stats-row">
        <div className="row-item" style={{ width: campaignsStatsTableFields[0].width }}>
          <div className="campaign-stats-row__date">
            <SkeletonComponent variant="text" loading={loading} width={80}>
              <p>{moment(created_at).format("DD.MM.YYYY")}</p>
            </SkeletonComponent>
            <SkeletonComponent variant="text" loading={loading} width={50}>
              <p>{moment(created_at).format("HH:mm")}</p>
            </SkeletonComponent>
          </div>
        </div>
        <div className="row-item" style={{ width: campaignsStatsTableFields[1].width }}>
          <SkeletonComponent variant="text" loading={loading} width={300}>
            <p className="campaign-stats-row__name ellipsis" title={name}>
              {name}
            </p>
          </SkeletonComponent>
        </div>
        <div className="row-item" style={{ width: campaignsStatsTableFields[2].width }}>
          <SkeletonComponent variant="text" loading={loading} width={70}>
            <p className="campaign-stats-row__participants">
              <b>{participants_count}</b>
              {` of ${max_participants}`}
            </p>
          </SkeletonComponent>
        </div>
        <div className="row-item" style={{ width: campaignsStatsTableFields[3].width }}>
          <SkeletonComponent variant="text" loading={loading} width={80}>
            <div className={clsx("campaign-stats-row__status", status)}>
              {translateCampaignStatus(status)}
            </div>
          </SkeletonComponent>
          {status === "active" && (
            <div className="campaign-stats-row__published-at">
              {moment(published_at).format("DD.MM.YYYY HH:mm")}
              <span>&nbsp;by&nbsp;</span>
              {`${published_by?.first_name} ${published_by?.last_name}`}
            </div>
          )}
        </div>
      </div>
    </AccordionSummary>
    <AccordionDetails
      classes={{
        root: "accordion-panel__details"
      }}
    >
      {(status === "active" || status === "finalizing") && <Published {...cost_tab_data_old} />}
      {(status === "inactive" || status === "archived") && <Inactive {...cost_tab_data_old} />}
      {(status === "finalized" || status === "stopped_immediately") && (
        <Finalized {...cost_tab_data_new} />
      )}
    </AccordionDetails>
  </Accordion>
);

export { renderCampaignStatsRow };

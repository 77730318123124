import React from "react";
import Clipboard from "react-clipboard.js";
import clsx from "clsx";
import { Image as CloudinaryImage } from "cloudinary-react";

import TooltipMessage from "../../_shared/TooltipMessage";

import LocationIcon from "../../../assets/image/location.svg";
import VideoIcon from "../../../assets/image/video.svg";
import CheckTextIcon from "../../../assets/image/check-text.svg";
import ImageIcon from "../../../assets/image/image.svg";
import URLIcon from "../../../assets/image/url.svg";
import ScanQRIcon from "../../../assets/image/scan-qr.svg";
import PhotoIcon from "../../../assets/image/photo.svg";
import NewQRIcon from "../../../assets/image/new-qr.svg";
import MultipleChoiceIcon from "../../../assets/image/multiple-choice.svg";
import ShortTextIcon from "../../../assets/image/short-text.svg";
import PicturesIcon from "../../../assets/image/pictures.svg";
import YesNoIcon from "../../../assets/image/yes-no.svg";
import RatingIcon from "../../../assets/image/rating.svg";
import DiscountIcon from "../../../assets/image/discount.svg";
import BonusIcon from "../../../assets/image/bonus.svg";
import MonetaryIcon from "../../../assets/image/monetary.svg";
import NoveltyIcon from "../../../assets/image/novelty.svg";
import NewBarcodeIcon from "../../../assets/image/new-barcode.svg";
import QuizIcon from "../../../assets/image/quiz.svg";

import DownloadIcon from "../../../assets/image/download.svg";
import CopyIcon from "../../../assets/image/copy.svg";
import SelectedIcon from "../../../assets/image/selected.svg";
import CheckIcon from "../../../assets/image/tick.svg";
import StarIcon from "../../../assets/image/star-yellow.svg";
import { renderClickableImage } from "../../../core/helpers/functions";

const calculatePercentOf = (value, sum) => Math.round((value / sum) * 100);

const getStepInfo = type => {
  switch (type) {
    case "location":
      return { name: "Location", icon: LocationIcon };
    case "video":
      return { name: "Video", icon: VideoIcon };
    case "image":
      return { name: "Image", icon: ImageIcon };
    case "url":
      return { name: "URL", icon: URLIcon };
    case "scan_qr":
      return { name: "Scan QR", icon: ScanQRIcon };
    case "photo":
      return { name: "Photo", icon: PhotoIcon };
    case "new_qr":
      return { name: "New QR", icon: NewQRIcon };
    case "new_barcode":
      return { name: "New Barcode", icon: NewBarcodeIcon };
    case "check_text":
      return { name: "Check Text", icon: CheckTextIcon };
    case "multiple":
      return { name: "Multiple Choice", icon: MultipleChoiceIcon };
    case "short_text":
      return { name: "Short Text", icon: ShortTextIcon };
    case "pictures":
      return { name: "Pictures", icon: PicturesIcon };
    case "yes_no":
      return { name: "Yes/No", icon: YesNoIcon };
    case "rating":
      return { name: "Rating", icon: RatingIcon };
    case "quiz":
      return { name: "Quiz", icon: QuizIcon };
    case "discount":
      return { name: "Discount", icon: DiscountIcon };
    case "bonus":
      return { name: "Bonus", icon: BonusIcon };
    case "monetary":
      return { name: "Monetary", icon: MonetaryIcon };
    case "novelty":
      return { name: "Novelty", icon: NoveltyIcon };
    default:
      return {};
  }
};

const renderStepTitleAndDescription = (title, description) => (
  <>
    <div>
      <div className="campaign-step__details--label">Title</div>
      <div className="campaign-step__details--field">{title || "–"}</div>
    </div>
    <div>
      <div className="campaign-step__details--label">Description</div>
      <div className="campaign-step__details--field">{description || "–"}</div>
    </div>
  </>
);

const renderUrlField = (type, url, dispatch) => (
  <div>
    <div className="campaign-step__details--label">{`${
      type === "video" ? "Youtube" : "Website"
    } URL`}</div>
    <div className="campaign-step__details--field with-adornment">
      <p className="ellipsis">{url || "–"}</p>
      {url && (
        <Clipboard
          component="div"
          data-clipboard-text={url}
          className="campaign-step__details--copy"
          onSuccess={() =>
            dispatch({ type: "SUCCESS_SNACK_OPEN", payload: "URL is successfully copied" })
          }
        >
          <TooltipMessage text={"Copy link"} delay={200} position="top" classes="">
            <img className="text_hover" src={CopyIcon} alt="copy" />
          </TooltipMessage>
        </Clipboard>
      )}
    </div>
  </div>
);

const renderCodeFields = (type, total, expiration, remaining) => (
  <div className="campaign-step__details--code-fields">
    <div>
      <div className="campaign-step__details--label">Number of codes to create</div>
      <div className="campaign-step__details--field with-unit-block">
        {total || "–"}
        <div className="campaign-step__details--unit-block">codes</div>
      </div>
    </div>
    <div>
      <div className="campaign-step__details--label">{`${
        type === "new_qr" ? "QR code" : "Barcode"
      } expiration`}</div>
      <div className="campaign-step__details--field with-unit-block">
        {expiration || "–"}
        <div className="campaign-step__details--unit-block">hours</div>
      </div>
    </div>
    <div>
      <div className="campaign-step__details--label">Codes remaining</div>
      <div className="campaign-step__details--bold-value">{remaining || 0}</div>
    </div>
  </div>
);

const renderStepProduct = (product, label) => (
  <div>
    <div className="campaign-step__details--label">{label}</div>
    {product ? (
      <div className="campaign-step__details--product">
        <CloudinaryImage
          cloudName="campy"
          width="50"
          height="50"
          gravity="face"
          crop="thumb"
          publicId={product.image.split("/").pop()}
          className="campaign-step__details--product-image"
        />
        <div>
          <div className="campaign-step__details--product-name">{product.name}</div>
          <div className="campaign-step__details--product-description">{product.description}</div>
        </div>
      </div>
    ) : (
      "–"
    )}
  </div>
);

const renderStepSizes = product_sizes => (
  <div>
    <div className="campaign-step__details--label">Sizes</div>
    <div className="campaign-step__details--sizes-wrapper">
      {product_sizes.map(({ name, quantity }, key) => (
        <div className="campaign-step__details--sizes" key={`${name}-${key}`}>
          <div className="campaign-step__details--field ">{name || "–"}</div>
          <div className="campaign-step__details--field with-unit-block">
            {quantity || 0}
            <div className="campaign-step__details--unit-block">items</div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const renderStepTotal = (type, { count, url, download_url }) => (
  <div>
    <div className="campaign-step__details--label">Total</div>
    <div className="campaign-step__details--total">
      <div className="campaign-step__details--total-value">{count || 0}</div>
      <div className="campaign-step__details--total-name">
        {type === "photo" ? "photos" : "answers"}
      </div>
      {count > 0 && (
        <a
          className="campaign-step__details--download text_hover"
          href={url || download_url}
          download
        >
          <img
            src={DownloadIcon}
            alt="download"
            className="campaign-step__details--download-icon"
          />
          <p>Download all</p>
        </a>
      )}
    </div>
  </div>
);

const renderStepMultiple = results => (
  <div className="campaign-step__details--multiple">
    {!results.length && "–"}
    {results.map(({ text, count, is_most_popular: mostPopular }, key) => (
      <div key={`multiple-${key}`} className="campaign-step__details--multiple-row">
        {mostPopular && (
          <img
            src={CheckIcon}
            alt="most popular"
            className="campaign-step__details--multiple-check"
          />
        )}
        <p
          className={clsx("campaign-step__details--multiple-text", mostPopular && "most-popular")}
          title={text}
        >
          {text}
        </p>
        <hr className="campaign-step__details--multiple-separator" />
        <p
          className={clsx("campaign-step__details--multiple-count", mostPopular && "most-popular")}
        >
          {count}
        </p>
      </div>
    ))}
  </div>
);

const renderStepPictures = results => (
  <div className="campaign-step__details--multiple">
    {!results.length && "–"}
    {results.map(({ url, name, count, is_most_popular: mostPopular }, key) => (
      <div key={`multiple-${key}`} className="campaign-step__details--multiple-row">
        <a
          className="campaign-step__details--multiple-image-wrapper"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CloudinaryImage
            cloudName="campy"
            width="24"
            height="24"
            gravity="face"
            crop="thumb"
            publicId={url.split("/").pop()}
          />
        </a>
        {mostPopular && (
          <img
            src={CheckIcon}
            alt="most popular"
            className="campaign-step__details--multiple-check"
          />
        )}
        <p
          className={clsx(
            "campaign-step__details--multiple-text with-image",
            mostPopular && "most-popular"
          )}
          title={name}
        >
          {name}
        </p>
        <hr className="campaign-step__details--multiple-separator" />
        <p
          className={clsx("campaign-step__details--multiple-count", mostPopular && "most-popular")}
        >
          {count}
        </p>
      </div>
    ))}
  </div>
);

const renderStepImage = image => (
  <div>
    <div className="campaign-step__details--label">Image</div>
    {renderClickableImage(image)}
  </div>
);

const renderStepYesNo = ({ yes, no }) => (
  <div className="campaign-step__details--bars">
    <div className={clsx("campaign-step__details--bars-row", yes > no && "bigger")}>
      <div className="campaign-step__details--bars-name">
        {yes > no && (
          <img
            src={CheckIcon}
            alt="most popular"
            className="campaign-step__details--multiple-check"
          />
        )}
        <div className="campaign-step__details--bars-label">Yes</div>
      </div>
      <div className="campaign-step__details--bars-bar">
        <div
          className="campaign-step__details--bars-filled"
          style={{ width: `${calculatePercentOf(yes, yes + no)}%` }}
        />
      </div>
      <div className="campaign-step__details--bars-value">{yes}</div>
    </div>
    <div className={clsx("campaign-step__details--bars-row", no > yes && "bigger")}>
      <div className="campaign-step__details--bars-name">
        {no > yes && (
          <img
            src={CheckIcon}
            alt="most popular"
            className="campaign-step__details--multiple-check"
          />
        )}
        <div className="campaign-step__details--bars-label">No</div>
      </div>
      <div className="campaign-step__details--bars-bar">
        <div
          className="campaign-step__details--bars-filled"
          style={{ width: `${calculatePercentOf(no, yes + no)}%` }}
        />
      </div>
      <div className="campaign-step__details--bars-value">{no}</div>
    </div>
  </div>
);

const renderStepRating = results => {
  const ratingList = Array.from({ length: 5 }, (v, k) => ({
    id: k + 1,
    value: results[k + 1]
  })).reverse();
  const ratingSum = ratingList.reduce((acc, item) => acc + item.value, 0);
  return (
    <>
      <div>
        <div className="campaign-step__details--label">Answers</div>
        <div className="campaign-step__details--bars">
          {ratingList.map(({ id, value }) => {
            const isBiggest = ratingList.every(el => el.value < value);
            return (
              <div
                className={clsx("campaign-step__details--bars-row", isBiggest && "bigger")}
                key={`rating-${id}`}
              >
                <div className="campaign-step__details--bars-label">
                  {isBiggest && (
                    <img
                      src={CheckIcon}
                      alt="most popular"
                      className="campaign-step__details--multiple-check"
                    />
                  )}
                  <span>{id}</span>
                  <img src={StarIcon} alt="star" className="campaign-step__details--bars-icon" />
                </div>
                <div className="campaign-step__details--bars-bar">
                  <div
                    className="campaign-step__details--bars-filled"
                    style={{ width: `${calculatePercentOf(value, ratingSum)}%` }}
                  />
                </div>
                <div className="campaign-step__details--bars-value">{value}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div className="campaign-step__details--label">Total</div>
        <div className="campaign-step__details--total">
          <div className="campaign-step__details--total-rating">
            {(results.score || 0).toFixed(1)}
            <img src={StarIcon} alt="star" />
          </div>
          <div className="campaign-step__details--total-name">{`${results.total} answers`}</div>
        </div>
      </div>
    </>
  );
};

const renderDiscountFields = (type, discount, product) => {
  let discountValue = 0;
  if (product?.price)
    discountValue =
      type === "percent" ? (product.price * discount) / 100 : product.price - discount;
  return (
    <div className="campaign-step__details--discount-fields">
      <div>
        <div className="campaign-step__details--label">Full price</div>
        <div className="campaign-step__details--field with-unit-block">
          {product?.price || 0}
          <div className="campaign-step__details--unit-block">€</div>
        </div>
      </div>
      {type === "percent" ? (
        <div>
          <div className="campaign-step__details--label">Discount %</div>
          <div className="campaign-step__details--field with-unit-block">
            {discount || 0}
            <div className="campaign-step__details--unit-block">%</div>
          </div>
        </div>
      ) : (
        <div>
          <div className="campaign-step__details--label">Discount price</div>
          <div className="campaign-step__details--field with-unit-block">
            {(discount || 0).toFixed(2)}
            <div className="campaign-step__details--unit-block">€</div>
          </div>
        </div>
      )}
      <div>
        <div className="campaign-step__details--label">Discount value</div>
        <div className="campaign-step__details--bold-value">{`${discountValue.toFixed(2)} €`}</div>
      </div>
    </div>
  );
};

const renderStepBonusDiscounts = (product, gift, free_products_given, need_to_buy) => {
  const discountPercent =
    gift?.price && product?.price
      ? ((gift.price * free_products_given) / (product.price * need_to_buy)) * 100
      : 0;
  const discountValue = gift?.price ? gift.price * free_products_given : 0;
  return (
    <div className="campaign-step__details--bonus-fields">
      <div>
        <div className="campaign-step__details--label">Discount %</div>
        <div className="campaign-step__details--bold-value">{discountPercent.toFixed(0)}%</div>
      </div>
      <div>
        <div className="campaign-step__details--label">Discount value</div>
        <div className="campaign-step__details--bold-value">{discountValue.toFixed(2)} €</div>
      </div>
    </div>
  );
};

const renderStepQuiz = (image, available_answers) => (
  <>
    <div>
      <div className="campaign-step__details--label">Answers</div>
      {available_answers.map(({ id, answer, is_correct }) => (
        <div
          key={`quiz-answer-${id}`}
          className={clsx("campaign-step__details--multiple-row", is_correct && "is-selected")}
        >
          {is_correct && <img src={SelectedIcon} alt="V" className="selected-icon" />}
          {answer}
        </div>
      ))}
    </div>
    <div>
      <div className="campaign-step__details--label">Image</div>
      {renderClickableImage(image)}
    </div>
  </>
);

const renderStepDetails = ({
  stepType,
  dispatch,
  title,
  description,
  address,
  radius,
  url,
  image,
  duration,
  number_of_codes,
  expiration_hours,
  codes_remaining,
  text,
  product = {},
  product_sizes = [],
  results = {},
  type,
  amount,
  value,
  gift = {},
  need_to_buy,
  free_products_given,
  available_answers = []
}) => {
  switch (stepType) {
    case "location":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          <div className="campaign-step__details--location-fields">
            <div className="campaign-step__details--address">
              <div className="campaign-step__details--label">Address</div>
              <div className="campaign-step__details--field ellipsis" title={address}>
                {address || "–"}
              </div>
            </div>
            <div className="campaign-step__details--radius">
              <div className="campaign-step__details--label">Radius</div>
              <div className="campaign-step__details--field with-unit-block">
                {radius || 0}
                <div className="campaign-step__details--unit-block">KM</div>
              </div>
            </div>
          </div>
        </div>
      );
    case "video":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          {renderUrlField(stepType, url, dispatch)}
        </div>
      );
    case "image":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          <div className="campaign-step__details--image-fields">
            {renderStepImage(image)}
            <div>
              <div className="campaign-step__details--label">
                How long the user needs to watch it
              </div>
              <div className="campaign-step__details--field with-unit-block seconds">
                {duration || 0}
                <div className="campaign-step__details--unit-block">sec</div>
              </div>
            </div>
          </div>
        </div>
      );
    case "url":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          {renderUrlField(stepType, url, dispatch)}
        </div>
      );
    case "scan_qr":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          <div>
            <div className="campaign-step__details--label">QR code</div>
            <div className="campaign-step__details--qr-code">
              <img src={image} alt="QR" />
            </div>
          </div>
        </div>
      );
    case "photo":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          {renderStepTotal(stepType, results)}
        </div>
      );
    case "new_qr":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          {renderCodeFields(stepType, number_of_codes, expiration_hours, codes_remaining)}
        </div>
      );
    case "new_barcode":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          {renderCodeFields(stepType, number_of_codes, expiration_hours, codes_remaining)}
        </div>
      );
    case "check_text":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          <div>
            <div className="campaign-step__details--label">Right text app user has to insert</div>
            <div className="campaign-step__details--field">{text || "–"}</div>
          </div>
        </div>
      );
    case "multiple":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          <div>
            <div className="campaign-step__details--label">Type</div>
            <div className="campaign-step__details--type">
              <img src={SelectedIcon} alt="V" />
              <span style={{ textTransform: "capitalize" }}>{type}</span>&nbsp;choice
            </div>
          </div>
          <div>
            <div className="campaign-step__details--label">Answers</div>
            {renderStepMultiple(results)}
          </div>
        </div>
      );
    case "short_text":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          {renderStepTotal(stepType, results)}
        </div>
      );
    case "pictures":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          <div>
            <div className="campaign-step__details--label">Images</div>
            {renderStepPictures(results)}
          </div>
        </div>
      );
    case "yes_no":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          <div>
            <div className="campaign-step__details--label">Answers</div>
            {renderStepYesNo(results)}
          </div>
        </div>
      );
    case "rating":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          {renderStepRating(results)}
        </div>
      );
    case "quiz":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          {renderStepQuiz(image, available_answers)}
        </div>
      );
    case "discount":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          {renderStepProduct(product, "Product")}
          {!!product_sizes.length && renderStepSizes(product_sizes)}
          <div>
            <div className="campaign-step__details--label">Discount type</div>
            <div className="campaign-step__details--type">
              <img src={SelectedIcon} alt="V" />
              {type === "percent" ? "Percentage discount" : "Specific price"}
            </div>
          </div>
          {renderDiscountFields(type, value, product)}
        </div>
      );
    case "bonus":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          {renderStepProduct(product, "Product")}
          {!!product_sizes.length && renderStepSizes(product_sizes)}
          <div className="campaign-step__details--discount-fields">
            <div>
              <div className="campaign-step__details--label">Full price</div>
              <div className="campaign-step__details--field with-unit-block money">
                {product?.price || 0}
                <div className="campaign-step__details--unit-block">€</div>
              </div>
            </div>
            <div>
              <div className="campaign-step__details--label">Need to buy</div>
              <div className="campaign-step__details--field with-unit-block products">
                {need_to_buy || "–"}
                <div className="campaign-step__details--unit-block">items</div>
              </div>
            </div>
          </div>
          {renderStepProduct(gift, "Bonus product")}
          {!!product_sizes.length && renderStepSizes(product_sizes)}
          <div className="campaign-step__details--discount-fields">
            <div>
              <div className="campaign-step__details--label">Full price</div>
              <div className="campaign-step__details--field with-unit-block money">
                {gift?.price || 0}
                <div className="campaign-step__details--unit-block">€</div>
              </div>
            </div>
            <div>
              <div className="campaign-step__details--label">Free products given</div>
              <div className="campaign-step__details--field with-unit-block products">
                {free_products_given || "–"}
                <div className="campaign-step__details--unit-block">items</div>
              </div>
            </div>
          </div>
          {renderStepBonusDiscounts(product, gift, free_products_given, need_to_buy)}
        </div>
      );
    case "monetary":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          <div>
            <div className="campaign-step__details--label">Campy money received</div>
            <div className="campaign-step__details--field with-unit-block money">
              {amount || "–"}
              <div className="campaign-step__details--unit-block">€</div>
            </div>
          </div>
        </div>
      );
    case "novelty":
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
          {renderStepProduct(product, "Product")}
          {!!product_sizes.length && renderStepSizes(product_sizes)}
          <div>
            <div className="campaign-step__details--label">Full price</div>
            <div className="campaign-step__details--field with-unit-block money">
              {product?.price || 0}
              <div className="campaign-step__details--unit-block">€</div>
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className="campaign-step__details">
          {renderStepTitleAndDescription(title, description)}
        </div>
      );
  }
};

export { getStepInfo, renderStepDetails };

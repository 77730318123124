import React, { memo } from "react";
import { useSelector } from "react-redux";

import SkeletonComponent from "../../_shared/SkeletonComponent";
import { splitByCommas } from "../../../core/helpers/functions";
import platforms from "../../../core/helpers/platforms";

import CampaignsIcon from "../../../assets/image/campaigns_icon.svg";

import "./styles.scss";

const Header = () => {
  const {
    initial,
    first_name,
    last_name,
    balance,
    id,
    region,
    email,
    sign_in_provider,
    campaigns_count
  } = useSelector(({ appUsers }) => appUsers.basicInfo);
  const platform = sign_in_provider && platforms.find(el => el.value === sign_in_provider);

  return (
    <div className="app-user-header">
      <div className="app-user-header__first-block">
        <p className="app-user-header__name">
          <SkeletonComponent variant="text" loading={initial} width={200}>
            <span>{`${first_name} ${last_name}`}</span>
            {platform?.icon && (
              <img src={platform.icon} alt="" className="app-user-header__platform-icon" />
            )}
          </SkeletonComponent>
        </p>
        <SkeletonComponent variant="text" loading={initial} width={300}>
          <div className="app-user-header__info">{`#${id}  |  ${email}  ${
            region ? `|  ${region}` : ""
          }`}</div>
        </SkeletonComponent>
      </div>
      <div className="app-user-header__last-block">
        <div className="app-user-header__campaigns-count">
          <SkeletonComponent variant="text" loading={initial} width={80}>
            <img
              src={CampaignsIcon}
              alt="campaigns"
              className="app-user-header__campaigns-count--icon"
            />
            <p className="app-user-header__campaigns-count--number">{campaigns_count}</p>
          </SkeletonComponent>
        </div>
        <div className="app-user-header__balance">
          <p className="app-user-header__balance--label">Campy credits</p>
          <p className="app-user-header__balance--value">
            <SkeletonComponent variant="text" loading={initial} width={100}>
              <span>{`${splitByCommas(balance || "")} €`}</span>
            </SkeletonComponent>
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);

import React from "react";
import moment from "moment";
import clsx from "clsx";

import { paymentsTableFields } from "../constants";
import SkeletonComponent from "../../_shared/SkeletonComponent";
import EditIcon from "../../../assets/image/edit.svg";
import { IconButton } from "@material-ui/core";

const renderPaymentRow = ({
  id,
  loading,
  created_at,
  type_description,
  math_amount,
  note,
  onUpdatePaymentClick
}) => (
  <div className="table-row payment-row" key={`campaign_${id}`}>
    <div className="row-item" style={{ width: paymentsTableFields[0].width }}>
      <div className="payment-row__date">
        <SkeletonComponent variant="text" loading={loading} width={100}>
          <p>{moment(created_at).format("DD.MM.YYYY")}</p>
        </SkeletonComponent>
        <SkeletonComponent variant="text" loading={loading} width={50}>
          <p>{moment(created_at).format("HH:mm")}</p>
        </SkeletonComponent>
      </div>
    </div>
    <div className="row-item" style={{ width: paymentsTableFields[1].width }}>
      <SkeletonComponent variant="text" loading={loading} width={300}>
        <p
          className="payment-row__type overflowed_p"
          dangerouslySetInnerHTML={{ __html: type_description }}
          title={type_description?.replace("<b>", "").replace("</b>", "")}
        />
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: paymentsTableFields[2].width }}>
      <SkeletonComponent variant="text" loading={loading} width={80}>
        <p className={clsx("payment-row__amount", math_amount > 0 ? "positive" : "negative")}>
          {`${math_amount?.toFixed(2)} €`}
        </p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: paymentsTableFields[3].width }}>
      <div className="payment-row__note-wrapper">
        <SkeletonComponent variant="text" loading={loading} width={200}>
          <p className="payment-row__note overflowed_p" title={note}>
            {note}
          </p>
        </SkeletonComponent>
        {!loading && (
          <>
            {note ? (
              <IconButton
                className="payment-row__edit-note"
                onClick={() =>
                  onUpdatePaymentClick({
                    id,
                    note
                  })
                }
              >
                <img src={EditIcon} alt="edit" />
              </IconButton>
            ) : (
              <button
                className="payment-row__add-note"
                onClick={() => onUpdatePaymentClick({ id, note })}
              >
                + Add note
              </button>
            )}
          </>
        )}
      </div>
    </div>
  </div>
);

export { renderPaymentRow };

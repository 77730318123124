import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "../../../components/Auth/Login";

import NotFoundImage from "../../../assets/image/404.png";
import CampyLogo from "../../../assets/image/auth-logo.svg";

import "./styles.scss";

class AuthContainer extends Component {
  render() {
    const { match } = this.props;
    if (!!localStorage.token) return <Redirect to="/admin" />;
    return (
      <Fragment>
        <main className="auth_container">
          <div className="triangle" />
          <div className="auth_content">
            <img src={CampyLogo} alt="campy" className="campy_logo" />
            <Switch>
              <Route path={match.url} exact render={() => <Redirect to="/auth/login" />} />
              <Route path={`${match.url}/login`} exact component={Login} />
              <Route
                render={() => (
                  <div className="not_found">
                    <img src={NotFoundImage} alt="not found" />
                  </div>
                )}
              />
            </Switch>
          </div>
        </main>
      </Fragment>
    );
  }
}

export default AuthContainer;

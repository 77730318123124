import React, { useEffect } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import DialogComponent from "../../../_shared/DialogComponent";
import RenderField from "../../../_shared/RenderField";
import DefaultButton from "../../../_shared/Buttons/DefaultButton";
import {
  getCompanyBasicInfo,
  patchCompanyContact,
  postCompanyContact
} from "../../../../core/actions/companyActions";

let ContactDialog = ({
  onClose,
  handleSubmit,
  submitting,
  pristine,
  valid,
  initialize,
  defaultValues
}) => {
  const editingMode = !defaultValues.empty;
  const dispatch = useDispatch();
  const { id: companyID } = useParams();

  useEffect(() => {
    if (editingMode) {
      initialize(defaultValues);
    }
    // eslint-disable-next-line
  }, [editingMode]);

  const saveContact = async data => {
    if (editingMode) {
      await dispatch(patchCompanyContact(companyID, defaultValues.id, data));
    } else {
      await dispatch(postCompanyContact(companyID, data));
    }
  };

  const onFormSubmit = data => {
    return saveContact(data).then(res => {
      if (res && res.error) {
        const errors = {};
        Object.entries(res.error.response.data).forEach(entry => {
          errors[entry[0]] = entry[1][0].message;
        });
        throw new SubmissionError(errors);
      } else {
        dispatch(getCompanyBasicInfo(companyID));
        onClose();
      }
    });
  };

  return (
    <DialogComponent open onClose={onClose} closeIcon>
      <div className="default_dialog_wrapper">
        <div className="dialog_title not_capitalize">{`${
          editingMode ? "Edit" : "Add"
        } contact`}</div>
        <form onSubmit={handleSubmit(onFormSubmit)} className="dialog_form contact-dialog">
          <div className="fields-wrapper">
            <Field name="name" type="text" component={RenderField} label="Name" />
            <Field name="email" type="text" component={RenderField} label="Email" />
            <div className="contact-dialog__two-fields">
              <div>
                <Field
                  name="phone_number"
                  type="text"
                  component={RenderField}
                  label="Phone number"
                />
              </div>
              <div>
                <Field name="position" type="text" component={RenderField} label="Position" />
              </div>
            </div>
          </div>
          <div className="contact-dialog__buttons">
            <DefaultButton
              variant="contained"
              classes="confirm"
              formAction
              disabled={submitting || pristine || !valid}
              loading={submitting}
            >
              {editingMode ? "Save" : "Add"}
            </DefaultButton>
            <DefaultButton variant="contained" classes="cancel_btn" onClick={onClose}>
              Cancel
            </DefaultButton>
          </div>
        </form>
      </div>
    </DialogComponent>
  );
};

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
    errors.email = "Please enter a correct email address";
  }
  return errors;
};

ContactDialog = reduxForm({
  form: "ContactForm",
  validate
})(ContactDialog);

export default ContactDialog;

import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import TableComponent from "../../_shared/TableComponentWithCustomFields";
import { renderPaymentRow } from "./helpers";
import { paymentsTableFields, paymentsTypes } from "../constants";
import { getCompanyPayments } from "../../../core/actions/companyActions";
import NoteDialog from "./NoteDialog";

import "./styles.scss";

const Payments = () => {
  const [paymentToUpdate, setPaymentToUpdate] = useState(null);
  const { results, count } = useSelector(({ company }) => company.payments);
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchPayments = meta => {
    dispatch(getCompanyPayments(id, meta));
  };

  const onUpdatePaymentClick = paymentToUpdate => {
    setPaymentToUpdate(paymentToUpdate);
  };
  const onPaymentDialogClose = () => {
    setPaymentToUpdate(null);
  };

  return (
    <section className="company-payments">
      <TableComponent
        tableFields={paymentsTableFields}
        tableContent={results}
        onTableFetch={fetchPayments}
        renderTableRow={el => renderPaymentRow({ ...el, onUpdatePaymentClick })}
        numberOfPages={count}
        withSearch
        searchPlaceholder="Search by user or campaign"
        noItemsText="No payments"
        filterKey="type"
        filterOptions={paymentsTypes}
      />
      {paymentToUpdate && (
        <NoteDialog defaultValues={paymentToUpdate} onClose={onPaymentDialogClose} />
      )}
    </section>
  );
};

export default memo(Payments);

import React from "react";
import moment from "moment";
import clsx from "clsx";

import SkeletonComponent from "../../_shared/SkeletonComponent";
import { splitByCommas } from "../../../core/helpers/functions";

const requestsHistoryTableFields = [
  { name: "created_at", label: "Date/Time", withSort: false, width: "15%" },
  { name: "company", label: "Company", withSort: false, width: "25%" },
  { name: "owner", label: "Requested by", withSort: false, width: "23%" },
  { name: "amount", label: "Amount", withSort: false, width: "22%" },
  { name: "status", label: "Status", withSort: false, width: "15%" }
];

const renderRequestsHistoryRow = ({
  id,
  loading,
  amount,
  status,
  created_at,
  company_name,
  created_by_name
}) => (
  <div className="table-row requests-history__row" key={`transaction_${id}`}>
    <div className="row-item" style={{ width: requestsHistoryTableFields[0].width }}>
      <SkeletonComponent variant="text" loading={loading} width={100}>
        <div>{moment(created_at).format("DD.MM.YYYY")}</div>
      </SkeletonComponent>
      <SkeletonComponent variant="text" loading={loading} width={70}>
        <div>{moment(created_at).format("HH:mm")}</div>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: requestsHistoryTableFields[1].width }}>
      <SkeletonComponent variant="text" loading={loading} width={150}>
        <p title={company_name} className="requests-history__row--bold-text ellipsis">
          {company_name}
        </p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: requestsHistoryTableFields[2].width }}>
      <SkeletonComponent variant="text" loading={loading} width={130}>
        <p className="requests-history__row--bold-text">{created_by_name}</p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: requestsHistoryTableFields[3].width }}>
      <SkeletonComponent variant="text" loading={loading} width={100}>
        <p className="requests-history__row--bold-text">
          {splitByCommas(Math.abs(+amount || 0).toFixed(2))} €
        </p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: requestsHistoryTableFields[4].width }}>
      <SkeletonComponent variant="text" loading={loading} width={80}>
        <span
          className={clsx(
            "requests-history__row--bold-text text-capitalized",
            status === "accepted" ? "income" : "outcome"
          )}
        >
          {status}
        </span>
      </SkeletonComponent>
    </div>
  </div>
);

export { requestsHistoryTableFields, renderRequestsHistoryRow };

import { COMPANIES } from "../actionTypes";
import { joinQueries } from "../helpers/functions";

export function getAllCompanies(queries) {
  return {
    type: COMPANIES.GET_ALL_COMPANIES,
    payload: {
      client: "default",
      request: {
        url: `/companies/${joinQueries(queries)}`,
        method: "get"
      }
    }
  };
}

export function postApproveCompany(company_id) {
  return {
    type: COMPANIES.POST_APPROVE_COMPANY,
    payload: {
      client: "default",
      request: {
        url: `/companies/${company_id}/approve/`,
        method: "post"
      }
    }
  };
}

export function postRejectCompany(company_id) {
  return {
    type: COMPANIES.POST_REJECT_COMPANY,
    payload: {
      client: "default",
      request: {
        url: `/companies/${company_id}/reject/`,
        method: "post"
      }
    }
  };
}

export function postSuspendCompany(company_id) {
  return {
    type: COMPANIES.POST_SUSPEND_COMPANY,
    payload: {
      client: "default",
      request: {
        url: `/companies/${company_id}/suspend/`,
        method: "post"
      }
    }
  };
}

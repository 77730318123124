import { APP } from "../actionTypes";

const INITIAL_STATE = {
  loading: false,
  buttonLoading: false,
  errorSnack: false,
  errorSnackText: "",
  successSnack: "",
  successSnackText: "",
  refetchCurrentTable: false
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case APP.LOADING:
      return { ...state, loading: action.payload };
    case APP.BUTTON_LOADING:
      return { ...state, buttonLoading: action.payload };
    case APP.ERROR_SNACK_OPEN:
      return { ...state, errorSnack: true, errorSnackText: action.payload };
    case APP.ERROR_SNACK_CLOSE:
      return { ...state, errorSnack: false };
    case APP.SUCCESS_SNACK_OPEN:
      return { ...state, successSnack: true, successSnackText: action.payload };
    case APP.SUCCESS_SNACK_CLOSE:
      return { ...state, successSnack: false };
    case APP.REFETCH_CURRENT_TABLE:
      return { ...state, refetchCurrentTable: action.refetchCurrentTable };
    default:
      return state;
  }
};

export default appReducer;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import _isEmpty from "lodash/isEmpty";

import {
  getAllCompanies,
  postApproveCompany,
  postRejectCompany,
  postSuspendCompany
} from "../../core/actions/companiesActions";
import ConfirmationDialog from "../_shared/Dialogs/ConfirmationDialog";
import tableColumns from "./tableColumns";
import TotalBlock from "../_shared/TotalBlock";
import TableComponent from "../_shared/TableComponent";
import { renderActionButtons } from "./helpers";

import CompaniesIcon from "../../assets/image/companies_total.svg";

const Companies = () => {
  const { count, results, companies_totals } = useSelector(({ companies }) => companies.list);
  const [state, setState] = useState({
    companyId: undefined,
    actionType: undefined,
    approveDialog: false,
    rejectDialog: false,
    suspendDialog: false,
    unsuspendDialog: false,
    dialogTitle: "",
    searchValue: ""
  });
  const history = useHistory();

  const dialogIsOpen =
    state.approveDialog || state.rejectDialog || state.suspendDialog || state.unsuspendDialog;

  const dispatch = useDispatch();

  const fetchCompanies = meta => dispatch(getAllCompanies(meta));

  const toggleDialog = (type, companyId = undefined) => {
    switch (type) {
      case "approve_company":
        setState(({ approveDialog }) => ({
          approveDialog: !approveDialog,
          companyId,
          actionType: companyId ? type : undefined,
          dialogTitle: "approve"
        }));
        break;
      case "reject_company":
        setState(({ rejectDialog }) => ({
          rejectDialog: !rejectDialog,
          companyId,
          actionType: companyId ? type : undefined,
          dialogTitle: "reject"
        }));
        break;
      case "suspend_company":
        setState(({ suspendDialog }) => ({
          suspendDialog: !suspendDialog,
          companyId,
          actionType: companyId ? type : undefined,
          dialogTitle: "suspend"
        }));
        break;
      case "unsuspend_company":
        setState(({ unsuspendDialog }) => ({
          unsuspendDialog: !unsuspendDialog,
          companyId,
          actionType: companyId ? type : undefined,
          dialogTitle: "unsuspend"
        }));
        break;
      default:
        break;
    }
  };

  const dispatchCompanyAction = async () => {
    const { companyId, actionType } = state;
    switch (actionType) {
      case "approve_company":
        await dispatch(postApproveCompany(companyId));
        toggleDialog("approve_company");
        break;
      case "reject_company":
        await dispatch(postRejectCompany(companyId));
        toggleDialog("reject_company");
        break;
      case "suspend_company":
        await dispatch(postSuspendCompany(companyId));
        toggleDialog("suspend_company");
        break;
      case "unsuspend_company":
        await dispatch(postApproveCompany(companyId));
        toggleDialog("unsuspend_company");
        break;
      default:
        break;
    }
  };

  return (
    <div className="page-wrapper">
      <h1>Сompanies</h1>
      <div className="page-wrapper__content">
        <TableComponent
          columns={tableColumns}
          data={results}
          fetchData={fetchCompanies}
          numberOfPages={count}
          noItemsText="No companies"
          withSearch
          searchPlaceholder="Search company by name or owner"
          renderTitle={meta => (
            <h2>
              <span style={{ textTransform: "capitalize" }}>{meta.status || "All"}</span> companies
            </h2>
          )}
          onRowClick={id => history.push(`/admin/company/${id}`)}
          renderActionButtons={({ id, status }) =>
            renderActionButtons({ id, status, toggleDialog })
          }
          renderTotals={onMetaChange => (
            <TotalBlock
              loading={_isEmpty(companies_totals)}
              totalIcon={CompaniesIcon}
              totalText={`${companies_totals.total} companies in total`}
              items={[
                { label: `${companies_totals.approved} approved`, value: "approved" },
                { label: `${companies_totals.suspended} suspended`, value: "suspended" },
                { label: `${companies_totals.pending} pending`, value: "pending" },
                { label: `${companies_totals.rejected} rejected`, value: "rejected" }
              ]}
              onItemClick={val => onMetaChange("status", val)}
            />
          )}
        />
      </div>
      <ConfirmationDialog
        open={dialogIsOpen}
        onClose={() => toggleDialog(state.actionType)}
        title={`${state.dialogTitle} company`}
        confirmationText={`Confirm that you want to ${state.dialogTitle} this company`}
        onConfirm={dispatchCompanyAction}
      />
    </div>
  );
};

export default Companies;

import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _omit from "lodash/omit";

import TableComponent from "../_shared/TableComponentWithCustomFields";
import { renderPurchaseRow } from "./helpers";
import { purchasesTableFields, purchaseStatuses, purchaseTypes } from "./constants";
import { getPurchases, resetPurchases } from "../../core/actions/purchasesActions";

import "./styles.scss";

const Purchases = () => {
  const { results, count } = useSelector(({ purchases }) => purchases.purchases);
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(resetPurchases());
    },
    [dispatch]
  );

  const fetchPurchases = meta =>
    dispatch(
      getPurchases(
        !meta.is_updated_more_than_24_hours_ago
          ? _omit(meta, ["is_updated_more_than_24_hours_ago"])
          : {
              ...meta,
              is_updated_less_than_24_hours_ago: false
            }
      )
    );

  return (
    <div className="page-wrapper purchases">
      <h1>Purchases</h1>
      <div className="page-wrapper__content">
        <TableComponent
          tableFields={purchasesTableFields}
          tableContent={results}
          onTableFetch={fetchPurchases}
          renderTableRow={renderPurchaseRow}
          numberOfPages={count}
          withSearch
          searchPlaceholder="Search delivery by user"
          noItemsText="No purchases"
          filterKey="type"
          filterOptions={purchaseTypes}
          secondFilterKey="status"
          secondFilterOptions={purchaseStatuses}
          filterCheckbox={{
            label: "Updated more than 24 hours ago",
            name: "is_updated_more_than_24_hours_ago"
          }}
          withExpandedRow
        />
      </div>
    </div>
  );
};

export default memo(Purchases);

import { STATISTICS } from "../actionTypes";
import { parseUsersChart } from "../helpers/functions";
import initialResults from "../helpers/constants";

const INITIAL_STATE = {
  companies_statistics: {
    campaigns_totals: {},
    companies_totals: {},
    results: initialResults
  },
  users_statistics: {},
  campaigns: []
};

const statisticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STATISTICS.GET_COMPANIES_STATISTICS_SUCCESS:
      return { ...state, companies_statistics: action.payload.data };
    case STATISTICS.GET_USERS_STATISTICS_SUCCESS:
      return {
        ...state,
        users_statistics: action.payload.data.total_data,
        users_chart_data: parseUsersChart(action.payload.data.chart_data)
      };
    case STATISTICS.GET_CAMPAIGNS_BY_COMPANY_SUCCESS:
      return { ...state, campaigns: action.payload.data };
    case STATISTICS.RESET_CAMPAIGNS_BY_COMPANY:
      return { ...state, campaigns: [] };

    default:
      return state;
  }
};

export default statisticsReducer;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Field, reduxForm, SubmissionError, getFormValues } from "redux-form";
import clsx from "clsx";

import { patchCompanyBasicInfo } from "../../../core/actions/companyActions";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import RenderField from "../../_shared/RenderField";
import Checkbox from "../../_shared/CheckBox";

import "./styles.scss";

let CompanyBasicInfo = ({ initialize, handleSubmit, submitting, pristine, valid }) => {
  const {
    initial,
    name,
    note,
    campy_fee,
    card_payment_fee,
    card_payment_fix_fee,
    created_by,
    address,
    registry_code,
    legal_name,
    bank_account_number,
    billing_contact_email,
    contact_person_phone_number,
    is_monthly_payment_enabled,
    monthly_payment_amount
  } = useSelector(({ company }) => company.basicInfo);
  const formValues = useSelector(state => getFormValues("CompanyBasicInfoForm")(state));
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (!initial) {
      initialize({
        name,
        note,
        campy_fee,
        card_payment_fee,
        card_payment_fix_fee,
        owner_name: `${created_by?.first_name} ${created_by?.last_name}`,
        owner_email: created_by?.email,
        address,
        registry_code,
        legal_name,
        bank_account_number,
        billing_contact_email,
        contact_person_phone_number,
        is_monthly_payment_enabled,
        monthly_payment_amount
      });
    }
    // eslint-disable-next-line
  }, [initial]);

  const prepareDataForSubmit = ({
    is_monthly_payment_enabled: isMonthlyPaymentEnabled,
    monthly_payment_amount: monthlyPaymentAmount,
    ...data
  }) => {
    const values = { ...data, is_monthly_payment_enabled: isMonthlyPaymentEnabled };
    if (isMonthlyPaymentEnabled) values.monthly_payment_amount = +monthlyPaymentAmount;
    return values;
  };

  const onFormSubmit = data => {
    return dispatch(patchCompanyBasicInfo(id, prepareDataForSubmit(data))).then(res => {
      if (res && res.error) {
        const errors = {};
        Object.entries(res.error.response.data).forEach(entry => {
          errors[entry[0]] = entry[1][0].message;
        });
        throw new SubmissionError(errors);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="company-basic-info">
      <div className="company-basic-info__fields">
        <Field name="name" type="text" component={RenderField} label="Brand name" />
        <Field
          name="legal_name"
          type="text"
          component={RenderField}
          label="Company legal name"
          disabled
        />
        <Field
          name="bank_account_number"
          type="text"
          component={RenderField}
          label="Company bank account number"
          disabled
        />
        <div className="company-basic-info__two-fields">
          <div>
            <Field
              name="billing_contact_email"
              type="text"
              component={RenderField}
              label="Billing contact email"
              disabled
            />
          </div>
          <div>
            <Field
              name="contact_person_phone_number"
              type="text"
              component={RenderField}
              label="Contact person's phone number"
              disabled
            />
          </div>
        </div>
        <div className="company-basic-info__address-fields">
          <div>
            <Field
              name="address"
              type="text"
              component={RenderField}
              label="Company address"
              disabled
            />
          </div>
          <div>
            <Field
              name="registry_code"
              type="text"
              component={RenderField}
              label="Registry code"
              disabled
            />
          </div>
        </div>
        <Field name="note" type="text" component={RenderField} label="Note" multiline />
        <div className="company-basic-info__two-fields">
          <div>
            <Field
              name="owner_name"
              type="text"
              component={RenderField}
              label="Owner name"
              disabled
            />
          </div>
          <div>
            <Field
              name="owner_email"
              type="text"
              component={RenderField}
              label="Owner email"
              disabled
            />
          </div>
        </div>
        <div className="company-basic-info__fees">
          <div>
            <Field
              name="campy_fee"
              type="number"
              component={RenderField}
              label="Campy fee"
              classes="with-percent-adornment"
            />
          </div>
          <div>
            <Field
              name="card_payment_fee"
              type="number"
              component={RenderField}
              label="Card payment fee"
              classes="with-percent-adornment"
            />
          </div>
          <div>
            <Field
              name="card_payment_fix_fee"
              type="number"
              component={RenderField}
              label="Card payment fix fee"
              classes="with-euro-adornment"
            />
          </div>
        </div>
        <Field name="is_monthly_payment_enabled" component={Checkbox} label="Monthly payment" />
        <div
          className={clsx([
            "company-basic-info__credit-limit",
            !formValues?.is_monthly_payment_enabled && "disabled"
          ])}
        >
          <Field
            name="monthly_payment_amount"
            type="number"
            component={RenderField}
            label="Monthly credit limit"
            classes="credit-limit"
            disabled={!formValues?.is_monthly_payment_enabled}
          />
        </div>
      </div>
      <hr className="company-basic-info__separator" />
      <DefaultButton
        variant="contained"
        classes="confirm"
        formAction
        disabled={initial || submitting || pristine || !valid}
        loading={submitting}
      >
        Save
      </DefaultButton>
    </form>
  );
};

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  return errors;
};

CompanyBasicInfo = reduxForm({
  form: "CompanyBasicInfoForm",
  validate
})(CompanyBasicInfo);

export default CompanyBasicInfo;

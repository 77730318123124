import React, { memo } from "react";

import { splitByCommas } from "../../../../core/helpers/functions";

const Published = ({
  already_paid,
  balance_left,
  initial_balance,
  max_participants,
  participants_finalized,
  participants_joined,
  total_estimated_cost,
  total_estimated_cost_based_on_people_joined,
  total_estimated_cost_bonuses,
  total_estimated_fee,
  total_estimated_link_sharing_cost
}) => (
  <div className="accordion-cost-wrapper">
    <div className="cost_block">
      <div className="cost_marked_block">
        <div>
          <p>Initial balance</p>
          <div>{splitByCommas(initial_balance || "0.00")}&nbsp;€</div>
        </div>
        <div>
          <p>Balance left</p>
          <div>{splitByCommas(balance_left || "0.00")}&nbsp;€</div>
        </div>
        <div>
          <p>Already paid</p>
          <div>{splitByCommas(already_paid || "0.00")}&nbsp;€</div>
        </div>
      </div>
      <div className="cost_block_info">
        <div className="total_estimated_cost">
          <p>Estimated campaign cost based on people joined</p>
          <div>
            {splitByCommas(total_estimated_cost_based_on_people_joined || "0.00")}
            &nbsp;€
          </div>
        </div>
        <div className="split_info">
          <div>
            <p>Participants joined</p>
            <div>
              <span>{participants_joined}</span> of {max_participants}
            </div>
          </div>
          <div>
            <p>Participants finalized the campaign</p>
            <div>
              <span>{participants_finalized}</span> of {participants_joined}
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr className="accordion-cost-wrapper__separator" />
    <div className="cost_block">
      <div className="cost_block_info">
        <div className="total_estimated_cost inactive">
          <p>Estimated campaign cost</p>
          <div className="inactive">{splitByCommas(total_estimated_cost || "0.00")}&nbsp;€</div>
        </div>
        <div className="split_info mb-32 inactive">
          <div>
            <p>Estimated cost of bonuses</p>
            <div>{splitByCommas(total_estimated_cost_bonuses || "0.00")}&nbsp;€</div>
          </div>
          <div>
            <p>Maximum participants</p>
            <div>{max_participants}</div>
          </div>
          <div>
            <p>Estimated fee (inc VAT)</p>
            <div>{splitByCommas(total_estimated_fee || "0.00")}&nbsp;€</div>
          </div>
        </div>
        <div className="split_info inactive">
          <div>
            <p>Estimated link sharing cost</p>
            <div>{splitByCommas(total_estimated_link_sharing_cost || "0.00")}&nbsp;€</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default memo(Published);

import { deliveryTypeByType, getOption } from "../../core/helpers/functions";
import { translateDeliveryStatus } from "../Campaign/Deliveries/helpers";

const purchasesTableFields = [
  { name: "campaign_name", label: "Campaign", withSort: false, width: "17%" },
  { name: "company_name", label: "Company", withSort: false, width: "15%" },
  { name: "user", label: "User", withSort: false, width: "20%" },
  { name: "purchased_at", label: "Purchased", withSort: true, width: "13%" },
  { name: "type", label: "Delivery type", withSort: true, width: "15%" },
  { name: "status_updated_at", label: "Status", withSort: true, width: "20%" }
];

const purchaseStatuses = [
  { label: getOption("All statuses"), value: "" },
  {
    label: getOption(translateDeliveryStatus("delivery_confirmation")),
    value: "delivery_confirmation"
  },
  { label: getOption(translateDeliveryStatus("deliver_to_pickup")), value: "deliver_to_pickup" },
  { label: getOption(translateDeliveryStatus("needs_delivery")), value: "needs_delivery" },
  { label: getOption(translateDeliveryStatus("ready_for_pickup")), value: "ready_for_pickup" },
  { label: getOption(translateDeliveryStatus("on_delivery")), value: "on_delivery" },
  { label: getOption(translateDeliveryStatus("done")), value: "done" },
  { label: getOption(translateDeliveryStatus("unredeemed")), value: "unredeemed" },
  { label: getOption(translateDeliveryStatus("redeemed")), value: "redeemed" }
];

const purchaseTypes = [
  { label: getOption("All types"), value: "" },
  { label: getOption(deliveryTypeByType("pickup")), value: "pickup" },
  { label: getOption(deliveryTypeByType("courier")), value: "courier" },
  { label: getOption(deliveryTypeByType("parcel_machine")), value: "parcel_machine" },
  { label: getOption(deliveryTypeByType("redeemable_any")), value: "redeemable_any" }
];

export { purchasesTableFields, purchaseStatuses, purchaseTypes };

import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import clsx from "clsx";

import { getCampaignAudience } from "../../../core/actions/campaignsActions";

import SelectedIcon from "../../../assets/image/selected.svg";
import NotSelectedIcon from "../../../assets/image/not-selected.svg";

import "./styles.scss";

const Audience = () => {
  const { audience, interests, hashtags, gender, age_from, age_to } = useSelector(
    ({ campaigns }) => campaigns.audience
  );
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCampaignAudience(id));
  }, [dispatch, id]);

  const audienceType = useMemo(() => {
    if (!audience) return "No limit — available to all users";
    switch (audience.type) {
      case "segment":
        return (
          <span>
            Based on <b>{audience.name}</b> segment
          </span>
        );
      case "csv":
        return (
          <span>
            Client lists from CSV file - "<b>{audience.name}</b>"
          </span>
        );
      case "analytics":
        return (
          <span>
            Clients based on <b>{audience.name}</b> analytics
          </span>
        );
      default:
        return "";
    }
  }, [audience]);

  const genders = useMemo(() => {
    return ["female", "male", "unisex"].map(el => ({
      name: el,
      selected: gender.includes(el)
    }));
  }, [gender]);

  return (
    <div className="campaign-audience">
      <div className="campaign-audience__block">
        <div className="campaign-audience__label">Use audience list</div>
        <div className="campaign-audience__field">{audienceType}</div>
      </div>
      <div className="campaign-audience__age-and-gender">
        <div className="campaign-audience__block">
          <div className="campaign-audience__label">Age</div>
          <div className="campaign-audience__fields">
            <div className="campaign-audience__field age">
              <span>From</span>
              <p>{age_from ? `${age_from} years` : "No limit"}</p>
            </div>
            <div className="campaign-audience__field age">
              <span>To</span>
              <p>{age_to ? `${age_to} years` : "No limit"}</p>
            </div>
          </div>
        </div>
        <div className="campaign-audience__block">
          <div className="campaign-audience__label">Gender</div>
          <div className="campaign-audience__field">
            {genders.map(({ name, selected }, key) => (
              <div
                className={clsx("campaign-audience__field--gender", !selected && "inactive")}
                key={name}
              >
                <img src={selected ? SelectedIcon : NotSelectedIcon} alt="V" />
                <p>{name}</p>
                {genders.length - 1 > key ? <span className="comma">,</span> : null}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="campaign-audience__block">
        <div className="campaign-audience__label">Interests</div>
        <div className="campaign-audience__field tags">
          {!interests.length && "–"}
          {interests.map((el, key) => (
            <div className="campaign-audience__tag" key={`interest_${key}`}>
              {el.name}
            </div>
          ))}
        </div>
      </div>
      <div className="campaign-audience__block">
        <div className="campaign-audience__label">Hashtags</div>
        <div className="campaign-audience__field tags">
          {!hashtags.length && "–"}
          {hashtags.map((el, key) => (
            <div className="campaign-audience__tag" key={`hashtag_${key}`}>
              {el.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Audience;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import _has from "lodash/has";

import { getAppUserCampaigns } from "../../../core/actions/appUsersActions";
import TotalBlock from "../../_shared/TotalBlock";
import TableComponent from "../../_shared/TableComponentWithCustomFields";
import { campaignsTableFields } from "../constants";
import { renderCampaignRow } from "../helpers";

import CampaignsIcon from "../../../assets/image/campaigns_icon.svg";

import "./styles.scss";

const Campaigns = () => {
  const { count, results, count_by_status } = useSelector(({ appUsers }) => appUsers.campaigns);
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchCampaigns = meta => {
    dispatch(getAppUserCampaigns(id, meta));
  };

  return (
    <section className="app-user-campaigns">
      <TotalBlock
        loading={!_has(count_by_status, "total")}
        totalIcon={CampaignsIcon}
        totalText={`${count_by_status.total} campaigns in total`}
        items={[
          { label: `${count_by_status.ongoing} ongoing`, value: "ongoing" },
          { label: `${count_by_status.completed} finished`, value: "completed" },
          { label: `${count_by_status.canceled} cancelled`, value: "canceled" },
          { label: `${count_by_status.failed} failed`, value: "failed" }
        ]}
      />
      <TableComponent
        tableFields={campaignsTableFields}
        tableContent={results}
        onTableFetch={fetchCampaigns}
        renderTableRow={renderCampaignRow}
        numberOfPages={count}
        noItemsText="No campaigns"
        withDatePickers
      />
    </section>
  );
};

export default Campaigns;

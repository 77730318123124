import React from "react";
import moment from "moment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import { purchasesTableFields } from "./constants";
import SkeletonComponent from "../_shared/SkeletonComponent";
import { translateDeliveryStatus } from "../Campaign/Deliveries/helpers";
import { deliveryTypeByType, splitByCommas } from "../../core/helpers/functions";
import NoteForm from "./NoteForm";

import ExpandIcon from "../../assets/image/down-arrow-purple.svg";
import CompletedIcon from "../../assets/image/completed.svg";
import PendingIcon from "../../assets/image/pending.svg";

const renderPurchaseRow = ({
  id,
  loading,
  campaign_name,
  company_name,
  purchased_at,
  user,
  type,
  status,
  status_updated_at,
  product_name,
  full_price,
  credits_used,
  paid_using_card,
  address_name,
  delivery_price,
  tracking_number,
  states,
  note
}) => (
  <Accordion
    key={`campaign_${id}`}
    classes={{
      root: "accordion-panel",
      expanded: "accordion-panel__expanded"
    }}
  >
    <AccordionSummary
      expandIcon={<img src={ExpandIcon} alt=">" />}
      classes={{
        root: "accordion-panel__summary",
        content: "accordion-panel__content"
      }}
    >
      <div className="table-row purchases-row">
        <div className="row-item" style={{ width: purchasesTableFields[0].width }}>
          <SkeletonComponent variant="text" loading={loading} width={120}>
            <p className="purchases-row__name" title={campaign_name}>
              {campaign_name}
            </p>
          </SkeletonComponent>
        </div>
        <div className="row-item" style={{ width: purchasesTableFields[1].width }}>
          <SkeletonComponent variant="text" loading={loading} width={100}>
            <p className="purchases-row__name ellipsis" title={company_name}>
              {company_name}
            </p>
          </SkeletonComponent>
        </div>
        <div className="row-item" style={{ width: purchasesTableFields[2].width }}>
          <SkeletonComponent variant="text" loading={loading} width={100}>
            <p className="purchases-row__user-name ellipsis" title={user?.full_name}>
              {user?.full_name || "-"}
            </p>
          </SkeletonComponent>
          <SkeletonComponent variant="text" loading={loading} width={140}>
            <p className="purchases-row__user-email ellipsis" title={user?.email}>
              {user?.email || "-"}
            </p>
          </SkeletonComponent>
        </div>
        <div className="row-item" style={{ width: purchasesTableFields[3].width }}>
          <div className="purchases-row__date">
            <SkeletonComponent variant="text" loading={loading} width={70}>
              <p>{purchased_at ? moment(purchased_at).format("DD.MM.YYYY") : "-"}</p>
            </SkeletonComponent>
          </div>
        </div>
        <div className="row-item" style={{ width: purchasesTableFields[4].width }}>
          <SkeletonComponent variant="text" loading={loading} width={100}>
            <p className="purchases-row__name">{deliveryTypeByType(type)}</p>
          </SkeletonComponent>
        </div>
        <div className="row-item" style={{ width: purchasesTableFields[5].width }}>
          <SkeletonComponent variant="text" loading={loading} width={150}>
            <div className="purchases-row__status">{translateDeliveryStatus(status)}</div>
          </SkeletonComponent>
          <SkeletonComponent variant="text" loading={loading} width={120}>
            <div className="purchases-row__updated-at">
              Updated&nbsp;
              <span>
                {moment(status_updated_at)
                  .subtract(1, "seconds")
                  .fromNow()}
              </span>
            </div>
          </SkeletonComponent>
        </div>
      </div>
    </AccordionSummary>
    <AccordionDetails
      classes={{
        root: "accordion-panel__details"
      }}
    >
      <div className="purchase-details">
        <div className="purchase-details__product-info">
          <div>
            <div className="purchase-details__label">Product</div>
            <div className="purchase-details__value">{product_name || "-"}</div>
          </div>
          <div>
            <div className="purchase-details__label">Full price</div>
            <div className="purchase-details__value">
              {splitByCommas(full_price?.toFixed(2) || "0.00")}&nbsp;€
            </div>
          </div>
          <div>
            <div className="purchase-details__label">Campy credits used</div>
            <div className="purchase-details__value">
              {splitByCommas(credits_used?.toFixed(2) || "0.00")}&nbsp;€
            </div>
          </div>
          <div>
            <div className="purchase-details__label">Paid using card</div>
            <div className="purchase-details__value">
              {splitByCommas(paid_using_card?.toFixed(2) || "0.00")}&nbsp;€
            </div>
          </div>
        </div>
        <hr className="purchase-details__separator" />
        <div className="purchase-details__delivery-info">
          <div>
            <div className="purchase-details__label">Delivery location</div>
            <div className="purchase-details__value">{address_name || "-"}</div>
          </div>
          <div>
            <div className="purchase-details__label">Delivery price</div>
            <div className="purchase-details__value">
              {splitByCommas(delivery_price?.toFixed(2) || "0.00")}&nbsp;€
            </div>
          </div>
          <div>
            <div className="purchase-details__label">Tracking number</div>
            <div className="purchase-details__value">{tracking_number || "-"}</div>
          </div>
        </div>
        <hr className="purchase-details__separator" />
        <div className="purchase-details__states">
          {states?.map(({ name, progress_state, date }) => (
            <div className="purchase-details__state" key={name}>
              <img
                src={progress_state === "completed" ? CompletedIcon : PendingIcon}
                alt="status"
              />
              <div className="purchase-details__state--info">
                <div className="purchase-details__value">{name}</div>
                <p className="purchase-details__label">{moment(date).format("DD.MM.YYYY HH:mm")}</p>
              </div>
            </div>
          ))}
        </div>
        <hr className="purchase-details__separator" />
        <NoteForm purchaseID={id} defaultValue={note} />
      </div>
    </AccordionDetails>
  </Accordion>
);

export { renderPurchaseRow };

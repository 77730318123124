import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import moment from "moment";

import { getCampaignDistribution } from "../../../core/actions/campaignsActions";
import { renderClickableImage } from "../../../core/helpers/functions";

import SelectedIcon from "../../../assets/image/selected.svg";
import NotSelectedIcon from "../../../assets/image/not-selected.svg";

import "./styles.scss";

const Distribution = () => {
  const {
    title,
    image,
    description,
    availability_start_date,
    availability_start_time,
    availability_end_date,
    availability_end_time,
    max_participants_value,
    time_to_finish,
    deliveries,
    addresses,
    agreement,
    link_share_is_enabled,
    link_share_title,
    link_share_description,
    link_share_image,
    link_share_reward
  } = useSelector(({ campaigns }) => campaigns.distribution);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCampaignDistribution(id));
  }, [dispatch, id]);

  const getDeliveryName = name => {
    switch (name) {
      case "smartpost":
        return "Itella SmartPost";
      case "omniva":
        return "Omniva";
      case "pickup":
        return "Pickup";
      case "courier":
        return "Courier";
      case "redeemable_any":
        return "Redeemable at any location";
      default:
        return name;
    }
  };

  return (
    <div className="campaign-distribution">
      <div className="campaign-distribution__block">
        <div className="campaign-distribution__label">Campaign title</div>
        <div className="campaign-distribution__field">{title}</div>
      </div>
      <div className="campaign-distribution__block">
        <div className="campaign-distribution__label">Campaign image</div>
        <div>{renderClickableImage(image)}</div>
      </div>
      <div className="campaign-distribution__block">
        <div className="campaign-distribution__label">Campaign description</div>
        <div className="campaign-distribution__field">{description}</div>
      </div>
      <div className="campaign-distribution__fields">
        <div className="campaign-distribution__block">
          <div className="campaign-distribution__label">Start date and time</div>
          <div className="campaign-distribution__field">
            {availability_start_date
              ? `${moment(availability_start_date).format("DD.MM.YY")} ${
                  availability_start_time
                    ? availability_start_time.slice(0, 5)
                    : moment()
                        .set({ hour: 0, minute: 0 })
                        .format("HH:mm")
                }`
              : "-"}
          </div>
        </div>
        <div className="campaign-distribution__block">
          <div className="campaign-distribution__label">End date and time</div>
          <div className="campaign-distribution__field">
            {availability_end_date
              ? `${moment(availability_end_date).format("DD.MM.YY")} ${
                  availability_end_time
                    ? availability_end_time.slice(0, 5)
                    : moment()
                        .set({ hour: 0, minute: 0 })
                        .format("HH:mm")
                }`
              : "-"}
          </div>
        </div>
      </div>
      <div className="campaign-distribution__fields">
        <div className="campaign-distribution__block">
          <div className="campaign-distribution__label">Max participants</div>
          <div className="campaign-distribution__field">{max_participants_value || "-"}</div>
        </div>
        <div className="campaign-distribution__block">
          <div className="campaign-distribution__label">Time to finish</div>
          <div className="campaign-distribution__field with-adornment">
            <p>{time_to_finish || "-"}</p>
            <div className="campaign-distribution__field--adornment">MIN</div>
          </div>
        </div>
      </div>
      <div className="campaign-distribution__block">
        <div className="campaign-distribution__label">Delivery</div>
        <div className="campaign-distribution__deliveries">
          {deliveries?.map(d => (
            <Fragment key={d.type}>
              <div className="campaign-distribution__deliveries--label">
                <img src={SelectedIcon} alt="V" />
                {getDeliveryName(d.type)}
              </div>
              {(d.type === "courier" || d.type === "smartpost" || d.type === "omniva") && (
                <div className="campaign-distribution__fields ml-20">
                  <div className="campaign-distribution__block">
                    <div className="campaign-distribution__label">Delivery price</div>
                    <div className="campaign-distribution__field with-adornment">
                      <p>{d.price ? d.price.toFixed(2) : "0.00"}</p>
                      <div className="campaign-distribution__field--adornment">€</div>
                    </div>
                  </div>
                </div>
              )}
              {d.type === "pickup" && (
                <div className="campaign-distribution__deliveries--addresses">
                  {addresses.map(a => (
                    <div
                      className="campaign-distribution__deliveries--address"
                      key={`address-${a.id}`}
                    >
                      {a.address}
                    </div>
                  ))}
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
      <div className="campaign-distribution__block">
        <div className="campaign-distribution__label">User agreement</div>
        <div className="campaign-distribution__field">{agreement || "-"}</div>
      </div>
      <div className="campaign-distribution__block">
        <div className="campaign-distribution__label">Share via link</div>
        <div className="campaign-distribution__share--label">
          <img src={link_share_is_enabled ? SelectedIcon : NotSelectedIcon} alt="V" />
          {link_share_is_enabled ? "Enabled" : "Disabled"}
        </div>
        {link_share_is_enabled && (
          <div className="campaign-distribution__share">
            <div className="campaign-distribution__block">
              <div className="campaign-distribution__label">Title</div>
              <div className="campaign-distribution__field">{link_share_title || "-"}</div>
            </div>
            <div className="campaign-distribution__block">
              <div className="campaign-distribution__label">Image</div>
              <div>{renderClickableImage(link_share_image)}</div>
            </div>
            <div className="campaign-distribution__block">
              <div className="campaign-distribution__label">Description</div>
              <div className="campaign-distribution__field">{link_share_description || "-"}</div>
            </div>
            <div className="campaign-distribution__block">
              <div className="campaign-distribution__label">Reward in Campy credits</div>
              <div className="campaign-distribution__field">
                <p>{link_share_reward ? link_share_reward.toFixed(2) : "0.00"}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Distribution;

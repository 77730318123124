import { COMPANIES } from "../actionTypes";
import initialResults from "../helpers/constants";

const INITIAL_STATE = {
  list: { count: 10, results: initialResults, companies_totals: {} }
};

const companyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COMPANIES.GET_ALL_COMPANIES_SUCCESS:
      return { ...state, list: action.payload.data };

    default:
      return state;
  }
};

export default companyReducer;

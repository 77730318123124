import { BALANCES } from "../actionTypes";
import { joinQueries } from "../helpers/functions";

const getCompaniesBalances = meta => ({
  type: BALANCES.GET_COMPANIES_BALANCES,
  payload: {
    client: "default",
    request: {
      url: `/balances/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const getAddingCreditsHistory = meta => ({
  type: BALANCES.GET_ADDING_CREDITS_HISTORY,
  payload: {
    client: "default",
    request: {
      url: `/balances/history/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const postAddCredits = (company_id, data) => ({
  type: BALANCES.POST_ADD_CREDITS,
  payload: {
    client: "default",
    request: {
      url: `/balances/${company_id}/add-credits/`,
      method: "post",
      data
    }
  }
});

const getReplenishmentRequests = meta => ({
  type: BALANCES.GET_REPLENISHMENT_REQUESTS_BALANCES,
  payload: {
    client: "default",
    request: {
      url: `/invoices/pending/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const getRequestsHistory = meta => ({
  type: BALANCES.GET_REQUESTS_HISTORY,
  payload: {
    client: "default",
    request: {
      url: `/invoices/history/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const postAcceptRequest = id => ({
  type: BALANCES.POST_ACCEPT_REQUEST,
  payload: {
    client: "default",
    request: {
      url: `/invoices/${id}/accept/`,
      method: "post"
    }
  }
});

const postRejectRequest = id => ({
  type: BALANCES.POST_REJECT_REQUEST,
  payload: {
    client: "default",
    request: {
      url: `/invoices/${id}/reject/`,
      method: "post"
    }
  }
});

const resetBalances = () => ({
  type: BALANCES.RESET_BALANCES
});

export {
  getCompaniesBalances,
  getAddingCreditsHistory,
  postAddCredits,
  getReplenishmentRequests,
  getRequestsHistory,
  postAcceptRequest,
  postRejectRequest,
  resetBalances
};

import { COMPANY } from "../actionTypes";
import { joinQueries } from "../helpers/functions";

const getCompanyBasicInfo = id => ({
  type: COMPANY.GET_COMPANY_BASIC_INFO,
  payload: {
    client: "default",
    request: {
      url: `/companies/${id}/tabs/basic-info/`,
      method: "get"
    }
  }
});

const patchCompanyBasicInfo = (id, data) => ({
  type: COMPANY.PATCH_COMPANY_BASIC_INFO,
  payload: {
    client: "default",
    request: {
      url: `/companies/${id}/tabs/basic-info/`,
      method: "patch",
      data
    }
  }
});

const getCompanyAdmins = (id, meta) => ({
  type: COMPANY.GET_COMPANY_ADMINS,
  payload: {
    client: "default",
    request: {
      url: `/companies/${id}/tabs/basic-info/admins/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const getCompanyCampaigns = (id, meta) => ({
  type: COMPANY.GET_COMPANY_CAMPAIGNS,
  payload: {
    client: "default",
    request: {
      url: `/companies/${id}/tabs/basic-info/campaigns/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const postCompanyContact = (companyID, data) => ({
  type: COMPANY.POST_COMPANY_CONTACT,
  payload: {
    client: "default",
    request: {
      url: `/companies/${companyID}/tabs/basic-info/add-contact/`,
      method: "post",
      data
    }
  }
});

const patchCompanyContact = (companyID, contactId, data) => ({
  type: COMPANY.PATCH_COMPANY_CONTACT,
  payload: {
    client: "default",
    request: {
      url: `/companies/${companyID}/tabs/basic-info/edit-contact/${contactId}/`,
      method: "patch",
      data
    }
  }
});

const getCompanyPayments = (id, meta) => ({
  type: COMPANY.GET_COMPANY_PAYMENTS,
  payload: {
    client: "default",
    request: {
      url: `/companies/${id}/tabs/transactions/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const patchCompanyPayment = (companyID, paymentId, data) => ({
  type: COMPANY.PATCH_COMPANY_PAYMENT,
  payload: {
    client: "default",
    request: {
      url: `/companies/${companyID}/tabs/transactions/${paymentId}/`,
      method: "patch",
      data
    }
  }
});

const getCompanyProducts = (id, meta) => ({
  type: COMPANY.GET_COMPANY_PRODUCTS,
  payload: {
    client: "default",
    request: {
      url: `/companies/${id}/tabs/products/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const getCompanyCampaignsStats = (id, meta) => ({
  type: COMPANY.GET_COMPANY_CAMPAIGNS_STATS,
  payload: {
    client: "default",
    request: {
      url: `/companies/${id}/tabs/campaigns/${joinQueries(meta)}`,
      method: "get"
    }
  }
});

const resetCompanyInfo = () => ({
  type: COMPANY.RESET_COMPANY_INFO
});

export {
  getCompanyBasicInfo,
  patchCompanyBasicInfo,
  resetCompanyInfo,
  getCompanyAdmins,
  getCompanyCampaigns,
  postCompanyContact,
  patchCompanyContact,
  getCompanyPayments,
  patchCompanyPayment,
  getCompanyProducts,
  getCompanyCampaignsStats
};

import React from "react";
import clsx from "clsx";
import moment from "moment";

import SkeletonComponent from "../SkeletonComponent";
import platforms from "../../../core/helpers/platforms";
import { splitByCommas } from "../../../core/helpers/functions";
import TooltipMessage from "../TooltipMessage";
import { renderStopReason } from "../../Campaigns/helpers";

import SortInactiveIcon from "../../../assets/image/sort_inactive.svg";
import SortUpIcon from "../../../assets/image/sort-up.svg";
import SortDownIcon from "../../../assets/image/sort-down.svg";
import QuestionIcon from "../../../assets/image/c-question.svg";

const renderHeaderCell = ({ ordering, setOrdering, label, name, withSort, sortKey, width }) => {
  let sortIcon = SortInactiveIcon;
  if (ordering === name || ordering === sortKey) sortIcon = SortUpIcon;
  if (ordering === `-${name}` || ordering === `-${sortKey}`) sortIcon = SortDownIcon;
  return (
    <div
      className="table-cell"
      style={{ width }}
      onClick={() => withSort && setOrdering(sortKey || name)}
      key={`header-cell__${name}`}
    >
      <p className="table-cell__label" style={{ width: withSort ? "min-content" : "unset" }}>
        {label}
      </p>
      {withSort && <img src={sortIcon} alt="sort" />}
    </div>
  );
};

const getCellContent = (name, { loading, ...f }, onClick) => {
  switch (name) {
    case "companyWithStatus":
      return (
        <div className="table-cell__company">
          <div className="table-cell__company--name" title={f.name}>
            <SkeletonComponent variant="text" loading={loading} width={100}>
              <span>{f.name}</span>
            </SkeletonComponent>
          </div>
          <div className={clsx("table-cell__company--status", f.status)}>
            <SkeletonComponent variant="text" loading={loading} width={70}>
              <span>{f.status_display}</span>
            </SkeletonComponent>
          </div>
        </div>
      );
    case "companyWithLegalName":
      return (
        <div className="table-cell__company">
          <div className="table-cell__company--name" title={f.name}>
            <SkeletonComponent variant="text" loading={loading} width={100}>
              <span>{f.name}</span>
            </SkeletonComponent>
          </div>
          <div className="table-cell__company--legal-name">
            <SkeletonComponent variant="text" loading={loading} width={80}>
              <span>{f.legal_name}</span>
            </SkeletonComponent>
          </div>
        </div>
      );
    case "campaign":
      return (
        <div className="table-cell__campaign">
          <div className="table-cell__campaign--name" title={f.name}>
            <SkeletonComponent variant="text" loading={loading} width={200}>
              <span>{f.name}</span>
            </SkeletonComponent>
          </div>
          <div className="table-cell__campaign--company-of-campaign">
            <SkeletonComponent variant="text" loading={loading} width={80}>
              <p>{f.company_name}</p>
            </SkeletonComponent>
          </div>
        </div>
      );
    case "companyWithCreatedBy":
    case "companyWithRequestedBy":
      return (
        <div className="table-cell__company">
          <div className="table-cell__company--name" title={f.name}>
            <SkeletonComponent variant="text" loading={loading} width={100}>
              <span>{f.company_name || f.name}</span>
            </SkeletonComponent>
          </div>
          <div className="table-cell__company--created-by">
            <SkeletonComponent variant="text" loading={loading} width={200}>
              <p>
                {name
                  .split("With")
                  .pop()
                  .replace("By", "")}{" "}
                {name === "companyWithCreatedBy" && f.status === "pending" && (
                  <span>{moment(f.created_at).fromNow()} </span>
                )}
                by{" "}
                <span>
                  {f.created_by
                    ? `${f.created_by.first_name} ${f.created_by.last_name}`
                    : f.owner?.full_name || f.created_by_name}
                </span>
              </p>
            </SkeletonComponent>
          </div>
        </div>
      );
    case "status":
      return (
        <div className={clsx("table-cell__status", f.status)}>
          <SkeletonComponent variant="text" loading={loading} width={70}>
            <p>{f.status_display}</p>
          </SkeletonComponent>
        </div>
      );
    case "owner":
    case "user":
      return (
        <div className="table-cell__user">
          <div className="table-cell__user--name" title={f[name] && f[name].full_name}>
            <SkeletonComponent variant="text" loading={loading} width={80}>
              <span>{f[name] ? f[name].full_name : "–"}</span>
            </SkeletonComponent>
          </div>
          <div className="table-cell__user--email" title={f[name] && f[name].email}>
            <SkeletonComponent variant="text" loading={loading} width={100}>
              <span>{f[name] ? f[name].email : "–"}</span>
            </SkeletonComponent>
          </div>
        </div>
      );
    case "userWithID":
      const fullName = f.full_name || `${f.first_name} ${f.last_name}`;
      return (
        <div className="table-cell__user">
          <div className="table-cell__user--name bold" title={fullName}>
            <SkeletonComponent variant="text" loading={loading} width={120}>
              <span>{fullName}</span>
            </SkeletonComponent>
          </div>
          <div className="table-cell__user--id">
            <SkeletonComponent variant="text" loading={loading} width={50}>
              <span>{`#${f.id}`}</span>
            </SkeletonComponent>
          </div>
        </div>
      );
    case "platform":
      const platform = f.sign_in_provider && platforms.find(el => el.value === f.sign_in_provider);
      return (
        <SkeletonComponent variant="text" loading={loading} width={100}>
          <div className="table-cell__platform">
            {platform ? (
              <>
                <img src={platform.icon} alt="pin" className="table-cell__platform--icon" />
                <div>{platform.label}</div>
              </>
            ) : (
              "–"
            )}
          </div>
        </SkeletonComponent>
      );
    case "balance":
    case "amount":
      return (
        <div className="table-cell__balance in-bold">
          <SkeletonComponent variant="text" loading={loading} width={80}>
            <p>{`${splitByCommas(f[name] || "0.00")} €`}</p>
          </SkeletonComponent>
        </div>
      );
    case "amount_of_transactions":
    case "cost_of_campaigns":
      return (
        <div className="table-cell__balance">
          <SkeletonComponent variant="text" loading={loading} width={80}>
            <p>{`${splitByCommas(f[name] || "0.00")} €`}</p>
          </SkeletonComponent>
        </div>
      );
    case "ownerEmail":
      return (
        <div className="table-cell__default-text">
          <SkeletonComponent variant="text" loading={loading} width={150}>
            <p>{f.owner?.email}</p>
          </SkeletonComponent>
        </div>
      );
    case "created_at":
      return (
        <div className="table-cell__default-text">
          <SkeletonComponent variant="text" loading={loading} width={100}>
            <p>{moment(f.created_at).format("DD.MM.YY HH:mm")}</p>
          </SkeletonComponent>
        </div>
      );
    case "creationDate":
      return (
        <div className="table-cell__default-text">
          <SkeletonComponent variant="text" loading={loading} width={100}>
            <p>{moment(f.created_at).format("DD.MM.YY")}</p>
          </SkeletonComponent>
        </div>
      );
    case "createdAt":
    case "publishedAt":
    case "startedAt":
    case "finishedAt":
    case "archivedAt":
    case "deletedAt":
      let date;
      if (name === "createdAt") {
        date = f.created_at;
      } else if (name === "publishedAt") {
        date = f.published_at;
      } else if (name === "startedAt") {
        date = f.availability_start_at;
      } else if (name === "finishedAt") {
        date = f.stopped_at;
      } else if (name === "archivedAt") {
        date = f.archived_at;
      } else if (name === "deletedAt") {
        date = f.deleted_at;
      }
      const stopReasonToShow = name === "finishedAt" && f.stop_reason;
      return (
        <>
          {date ? (
            <div className="table-cell__date-time">
              <div className="table-cell__date-time--date">
                <span>{moment(date).format("DD.MM.YY")}</span>
                {stopReasonToShow && (
                  <TooltipMessage text={renderStopReason(f.stop_reason)} delay={200} position="top">
                    <img src={QuestionIcon} alt="?" className="table-cell__question-icon" />
                  </TooltipMessage>
                )}
              </div>
              <p>{moment(date).format("HH:mm")}</p>
            </div>
          ) : (
            <div className="table-cell__default-text">
              <SkeletonComponent variant="text" loading={loading} width={55}>
                <p>–</p>
              </SkeletonComponent>
              <SkeletonComponent variant="text" loading={loading} width={30}>
                <p />
              </SkeletonComponent>
            </div>
          )}
        </>
      );
    case "is_sent":
      return (
        <div
          className={clsx(
            "table-cell__default-text",
            f[name] ? "table-cell__is-sent" : "table-cell__pending"
          )}
        >
          <SkeletonComponent variant="text" loading={loading} width={60}>
            <p>{f[name] ? "Sent" : "Pending"}</p>
          </SkeletonComponent>
        </div>
      );
    default:
      return (
        <div
          className={clsx("table-cell__default-text", name, !!onClick && !!f[name] && "clickable")}
          onClick={() => onClick && !!f[name] && onClick(f, name)}
        >
          <SkeletonComponent variant="text" loading={loading} width={50}>
            <p>{f[name] || "–"}</p>
          </SkeletonComponent>
        </div>
      );
  }
};

const renderBodyCell = ({ data, columnInfo: { name, width, onClick } }) => (
  <div className="table-cell" style={{ width }} key={`body-cell__${name}`}>
    {getCellContent(name, data, onClick)}
  </div>
);

export { renderHeaderCell, renderBodyCell };

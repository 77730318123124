import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import moment from "moment";

import { getBillingByCompany, resetBillingByCompany } from "../../../core/actions/billingActions";
import tableColumns from "./tableColumns";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import TableComponent from "../../_shared/TableComponentWithCustomFields";
import BackLink from "../../_shared/BackLink";
import SkeletonComponent from "../../_shared/SkeletonComponent";
import { renderBillingByCompanyRow } from "./helpers";

import "./styles.scss";

const BillingByCompany = () => {
  const { count, company, excel_link, results } = useSelector(
    ({ billing }) => billing.billingByCompany
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const [idsToShowWithVAT, setIdsToShowWithVat] = useState([]);

  useEffect(() => {
    return () => {
      dispatch(resetBillingByCompany());
    };
  }, [dispatch]);

  const fetchBillingByCompany = meta => dispatch(getBillingByCompany(id, meta));

  const onExportToExcelClick = () => window.open(excel_link);

  const onHandleShowVatToggle = id => {
    setIdsToShowWithVat(prevState =>
      prevState.includes(id) ? prevState.filter(i => i !== id) : [...prevState, id]
    );
  };

  return (
    <div className="page-wrapper">
      <div className="page-wrapper__content">
        <BackLink text="Back to Billing" to="/admin/billing" />
        <div className="billing-by-company__company-name">
          <SkeletonComponent variant="text" loading={!company} width={200}>
            {company?.name}
          </SkeletonComponent>
        </div>
        <div className="billing-by-company__company-billing-info">
          <SkeletonComponent variant="text" loading={!company} width={510}>
            {`${company?.legal_name ? `${company.legal_name}  |  ` : ""}${
              company?.billing_contact_email ? `${company.billing_contact_email}  |  ` : ""
            }${company?.bank_account_number}`}
          </SkeletonComponent>
        </div>
        <div className="billing-by-company__table-wrapper">
          <div className="billing-by-company__export-button">
            <SkeletonComponent variant="rect" loading={!excel_link} width={151} height={35}>
              <DefaultButton variant="contained" onClick={onExportToExcelClick} classes="confirm">
                Export to excel
              </DefaultButton>
            </SkeletonComponent>
          </div>
          <TableComponent
            tableFields={tableColumns}
            tableContent={results}
            onTableFetch={fetchBillingByCompany}
            renderTableRow={props =>
              renderBillingByCompanyRow({ ...props, idsToShowWithVAT, onHandleShowVatToggle })
            }
            numberOfPages={count}
            noItemsText="No stats"
            withDatePickers
            datePickerProps={{
              views: ["year", "month"],
              variant: "inline",
              format: "MM/yyyy"
            }}
            defaultMeta={{
              page: 1,
              date_to: moment(new Date())
                .subtract(1, "months")
                .endOf("month")
                .format("YYYY-MM-DD"),
              date_from: moment(new Date())
                .subtract(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD")
            }}
            withExpandedRow
          />
        </div>
      </div>
    </div>
  );
};

export default BillingByCompany;

import React from "react";
import moment from "moment";
import clsx from "clsx";

import SkeletonComponent from "../../_shared/SkeletonComponent";
import { splitByCommas } from "../../../core/helpers/functions";

const addingCreditsHistoryTableFields = [
  { name: "created_at", label: "Date/Time", withSort: false, width: "15%" },
  { name: "company", label: "Company", withSort: false, width: "25%" },
  { name: "owner", label: "Owner", withSort: false, width: "23%" },
  { name: "admin", label: "Admin", withSort: false, width: "22%" },
  { name: "amount", label: "Amount", withSort: false, width: "15%" }
];

const renderAddingCreditsHistoryRow = ({ id, loading, admin, amount, created_at, company }) => (
  <div className="table-row adding-credits-history__row" key={`transaction_${id}`}>
    <div className="row-item" style={{ width: addingCreditsHistoryTableFields[0].width }}>
      <SkeletonComponent variant="text" loading={loading} width={100}>
        <div>{moment(created_at).format("DD.MM.YYYY")}</div>
      </SkeletonComponent>
      <SkeletonComponent variant="text" loading={loading} width={70}>
        <div>{moment(created_at).format("HH:mm")}</div>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: addingCreditsHistoryTableFields[1].width }}>
      <SkeletonComponent variant="text" loading={loading} width={100}>
        <p title={company?.name} className="adding-credits-history__row--bold-text ellipsis">
          {company?.name}
        </p>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: addingCreditsHistoryTableFields[2].width }}>
      <SkeletonComponent variant="text" loading={loading} width={130}>
        <p className="adding-credits-history__row--bold-text">{company?.owner.full_name}</p>
      </SkeletonComponent>
      <SkeletonComponent variant="text" loading={loading} width={200}>
        <span
          title={company?.owner.email}
          className="adding-credits-history__row--light-text ellipsis"
        >
          {company?.owner.email}
        </span>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: addingCreditsHistoryTableFields[3].width }}>
      <SkeletonComponent variant="text" loading={loading} width={130}>
        <p className="adding-credits-history__row--bold-text">{admin?.full_name || "–"}</p>
      </SkeletonComponent>
      <SkeletonComponent variant="text" loading={loading} width={200}>
        <span title={admin?.email} className="adding-credits-history__row--light-text ellipsis">
          {admin?.email || "–"}
        </span>
      </SkeletonComponent>
    </div>
    <div className="row-item" style={{ width: addingCreditsHistoryTableFields[4].width }}>
      <SkeletonComponent variant="text" loading={loading} width={50}>
        <span
          className={clsx(
            "adding-credits-history__row--bold-text",
            Number(amount) > 0 ? "income" : "outcome"
          )}
        >
          {Number(amount) > 0 ? "+" : "-"}&nbsp;
          {splitByCommas(Math.abs(+amount || 0).toFixed(2))}&nbsp;€
        </span>
      </SkeletonComponent>
    </div>
  </div>
);

export { addingCreditsHistoryTableFields, renderAddingCreditsHistoryRow };

import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _debounce from "lodash/debounce";
import _omitBy from "lodash/omitBy";
import { FormControl } from "@material-ui/core";
import clsx from "clsx";
import { useLocation } from "react-router";

import Pagination from "../Pagination";
import SelectComponent from "../SelectComponent";
import DatePicker from "../DatePicker";
import { formatDate } from "../../../core/helpers/functions";
import { renderBodyCell, renderHeaderCell } from "./helpers";

import SearchIcon from "../../../assets/image/zoom.svg";

import "./styles.scss";

const TableComponent = ({
  columns,
  data,
  fetchData,
  numberOfPages,
  isStatic,
  withSearch = false,
  searchPlaceholder = "",
  noItemsText = "",
  filterKey = null,
  filterOptions,
  withDatePickers = false,
  datePickerProps = {},
  renderTitle = () => {},
  renderTotals = () => {},
  renderActionButtons = () => {},
  onRowClick = null,
  defaultMeta = {
    page: 1,
    search: "",
    ordering: "",
    date_to: "",
    date_from: ""
  }
}) => {
  const location = useLocation();
  const refetchCurrentTable = useSelector(({ app }) => app.refetchCurrentTable);
  const [meta, setMeta] = useState(
    localStorage.getItem(`meta-${location.pathname}`)
      ? JSON.parse(localStorage.getItem(`meta-${location.pathname}`))
      : defaultMeta
  );

  useEffect(() => {
    if (!isStatic) fetchData(_omitBy(meta, v => !v));
    if (location.pathname.split("/").length === 3) {
      localStorage.setItem(`meta-${location.pathname}`, JSON.stringify(meta));
    }
    // eslint-disable-next-line
  }, [meta]);

  useEffect(() => {
    if (refetchCurrentTable) fetchData(_omitBy(meta, v => !v));
    // eslint-disable-next-line
  }, [refetchCurrentTable]);

  const onMetaChange = (fieldName, value) => {
    setMeta(prev => ({
      ...prev,
      [fieldName]: value
    }));
  };

  const onInputChange = inputText => {
    setMeta(prev => ({
      ...prev,
      page: 1,
      search: inputText
    }));
  };

  const onFilterChange = (fieldName, value) => {
    setMeta(prev => ({
      ...prev,
      page: 1,
      [fieldName]: value
    }));
  };

  const handleDateChange = (date, name) => {
    setMeta(prev => ({
      ...prev,
      page: 1,
      [name]: date ? formatDate(date) : ""
    }));
  };

  const debouncedOnInput = useMemo(
    () => _debounce(onInputChange, 300),
    // eslint-disable-next-line
    []
  );

  const setOrdering = ordering => {
    if (meta.ordering === `-${ordering}`) {
      onMetaChange("ordering", ordering);
    } else {
      onMetaChange("ordering", `-${ordering}`);
    }
  };

  return (
    <>
      {renderTotals(onFilterChange)}
      {renderTitle(meta)}
      {(withSearch || filterKey || withDatePickers) && (
        <div className="table-search">
          {withSearch && (
            <div className="search">
              <input
                defaultValue={meta.search}
                onChange={e => {
                  e.persist();
                  debouncedOnInput(e.target.value);
                }}
                type="text"
                placeholder={searchPlaceholder}
              />
              <span>
                <img src={SearchIcon} alt="search" />
              </span>
            </div>
          )}
          {filterKey && (
            <FormControl className="select_wrapper">
              <SelectComponent
                value={filterOptions.find(el => el.value === meta[filterKey]) || filterOptions[0]}
                options={filterOptions}
                change={({ value }) => onFilterChange(filterKey, value)}
              />
            </FormControl>
          )}
          {withDatePickers && (
            <div className="table-date-pickers">
              <DatePicker
                maxDate={meta.date_to ? new Date(meta.date_to) : undefined}
                handleDateChange={handleDateChange}
                name="date_from"
                prevDate={meta.date_from}
                leftInputAdornment="From"
                {...datePickerProps}
              />
              <DatePicker
                minDate={meta.date_from ? new Date(meta.date_from) : undefined}
                handleDateChange={handleDateChange}
                name="date_to"
                prevDate={meta.date_to}
                leftInputAdornment="To"
                {...datePickerProps}
              />
            </div>
          )}
        </div>
      )}
      <div className="table-container">
        <div className="table-header">
          <div className="table-row">
            {columns.map(el => renderHeaderCell({ ...el, ordering: meta.ordering, setOrdering }))}
          </div>
        </div>
        {!!data.length && (
          <div className="table-body">
            {data.map((row, key) => (
              <div
                className={clsx("table-row", onRowClick && "clickable")}
                key={`table-row__${key}`}
                onClick={() => onRowClick && onRowClick(row.id)}
              >
                {columns.map(col => {
                  if (col.name === "actions") {
                    return (
                      <div
                        className="table-cell actions"
                        style={{ width: col.width }}
                        key={`body-cell__${col.name}`}
                      >
                        {renderActionButtons(row)}
                      </div>
                    );
                  }
                  return renderBodyCell({ data: row, columnInfo: col });
                })}
              </div>
            ))}
          </div>
        )}
        {!data.length && <div className="no-items">{noItemsText}</div>}
      </div>
      {numberOfPages > 10 && (
        <div className="pagination_wrapper">
          <Pagination
            active={meta.page}
            pageCount={Math.ceil(numberOfPages / 10)}
            onChange={e => onMetaChange("page", e.selected + 1)}
          />
        </div>
      )}
    </>
  );
};

export default TableComponent;

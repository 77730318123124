import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import TableComponent from "../../_shared/TableComponentWithCustomFields";
import { renderProductRow, renderCampaignRow, renderBelongsToDescription } from "./helpers";
import { productsTableFields, productsTypes } from "../constants";
import { getCompanyProducts } from "../../../core/actions/companyActions";
import ListDialog from "../../_shared/Dialogs/ListDialog";

import "./styles.scss";

const Products = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [productName, setProductName] = useState("");
  const { results, count } = useSelector(({ company }) => company.products);
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchProducts = meta => {
    dispatch(getCompanyProducts(id, meta));
  };

  const onCampaignsClick = (product, campaignsList) => {
    setProductName(product);
    setCampaigns(campaignsList);
  };
  const onCampaignsDialogClose = () => {
    setCampaigns([]);
    setProductName("");
  };

  return (
    <section className="company-products">
      <TableComponent
        tableFields={productsTableFields}
        tableContent={results}
        onTableFetch={fetchProducts}
        renderTableRow={el => renderProductRow({ ...el, onCampaignsClick })}
        numberOfPages={count}
        withSearch
        searchPlaceholder="Search by product name or user"
        noItemsText="No products"
        filterKey="is_from_api"
        filterOptions={productsTypes}
      />
      {!!campaigns.length && (
        <ListDialog
          isOpen
          title="Campaigns"
          onClose={onCampaignsDialogClose}
          renderDescription={() => renderBelongsToDescription(productName)}
          renderRow={renderCampaignRow}
          elements={campaigns}
        />
      )}
    </section>
  );
};

export default memo(Products);

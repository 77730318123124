import React from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "./styles.scss";

export default function DatePicker({
  handleDateChange,
  prevDate,
  disabled,
  name,
  disablePast = false,
  minDate = new Date("2000-01-01"),
  maxDate = new Date("2100-01-01"),
  leftInputAdornment,
  views = ["day", "year", "month"],
  variant = "dialog",
  format = "dd/MM/yyyy"
}) {
  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDate = date => {
    setSelectedDate(date);
    if (date === null || date?.toString() !== "Invalid Date") handleDateChange(date, name);
  };

  return (
    <div className="datepicker">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disabled={disabled}
          invalidDateMessage="Invalid Date"
          minDate={minDate}
          maxDate={maxDate}
          disablePast={disablePast}
          variant={variant}
          format={format}
          views={views}
          margin="normal"
          id={`date-picker-inline-${name}`}
          placeholder={format.toLowerCase()}
          value={prevDate || selectedDate}
          onChange={handleDate}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
        />
      </MuiPickersUtilsProvider>
      <div className="datepicker__adornment">{leftInputAdornment}</div>
    </div>
  );
}

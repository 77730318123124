import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import _isEmpty from "lodash/isEmpty";

import { getAppUsers } from "../../core/actions/appUsersActions";
import TableComponent from "../_shared/TableComponent";
import TotalBlock from "../_shared/TotalBlock";
import tableColumns from "./tableColumns";

import UsersIcon from "../../assets/image/users_icon.svg";

const AppUsers = () => {
  const {
    list: { results, count, users_totals }
  } = useSelector(({ appUsers }) => appUsers);
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchAppUsers = meta => dispatch(getAppUsers(meta));

  const platformName = name => {
    switch (name) {
      case "android":
        return "Android";
      case "ios":
        return "iOS";
      default:
        return "All";
    }
  };

  return (
    <div className="page-wrapper">
      <h1>App users</h1>
      <div className="page-wrapper__content">
        <TableComponent
          columns={tableColumns}
          data={results}
          fetchData={fetchAppUsers}
          numberOfPages={count}
          noItemsText="No users"
          withSearch
          searchPlaceholder="Search user by ID, name or email"
          onRowClick={id => history.push(`/admin/app-users/${id}`)}
          renderTitle={meta => (
            <h2>
              <span>{platformName(meta.sign_in_provider)}</span> users
            </h2>
          )}
          renderTotals={onMetaChange => (
            <TotalBlock
              loading={_isEmpty(users_totals)}
              totalIcon={UsersIcon}
              totalText={`${users_totals.total} registered mobile users`}
              items={[
                { label: `${users_totals.ios} iOS`, value: "ios" },
                { label: `${users_totals.android} Android`, value: "android" }
              ]}
              onItemClick={val => onMetaChange("sign_in_provider", val)}
            />
          )}
        />
      </div>
    </div>
  );
};

export default AppUsers;

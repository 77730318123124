import { STATISTICS } from "../actionTypes";
import { joinQueries } from "../helpers/functions";

export function getCompaniesStatistics(meta) {
  return {
    type: STATISTICS.GET_COMPANIES_STATISTICS,
    payload: {
      client: "default",
      request: {
        url: `/statistics/companies/${joinQueries(meta)}`,
        method: "get"
      }
    }
  };
}

export function getUsersStatistics() {
  return {
    type: STATISTICS.GET_USERS_STATISTICS,
    payload: {
      client: "default",
      request: {
        url: `/statistics/users/`,
        method: "get"
      }
    }
  };
}

export function getCampaignsByCompany(companyID, status) {
  return {
    type: STATISTICS.GET_CAMPAIGNS_BY_COMPANY,
    payload: {
      client: "default",
      request: {
        url: `/statistics/companies/${companyID}/campaigns/?status=${status}`,
        method: "get"
      }
    }
  };
}

export function resetCampaignsByCompany() {
  return {
    type: STATISTICS.RESET_CAMPAIGNS_BY_COMPANY
  };
}

import { COMPANY } from "../actionTypes";
import initialResults from "../helpers/constants";

const INITIAL_STATE = {
  basicInfo: { initial: true, contacts: initialResults },
  admins: { count: 10, results: initialResults },
  campaigns: { count: 10, results: initialResults },
  payments: { count: 10, results: initialResults },
  products: { count: 10, results: initialResults },
  campaignsStats: { count: 10, results: initialResults }
};

const companyReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case COMPANY.GET_COMPANY_BASIC_INFO_SUCCESS:
      return { ...state, basicInfo: payload.data };
    case COMPANY.PATCH_COMPANY_BASIC_INFO_SUCCESS:
      return { ...state, basicInfo: payload.data };
    case COMPANY.GET_COMPANY_ADMINS_SUCCESS:
      return { ...state, admins: payload.data };
    case COMPANY.GET_COMPANY_CAMPAIGNS_SUCCESS:
      return { ...state, campaigns: payload.data };
    case COMPANY.GET_COMPANY_PAYMENTS_SUCCESS:
      return { ...state, payments: payload.data };
    case COMPANY.PATCH_COMPANY_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: {
          ...state.payments,
          results: state.payments.results.map(el => {
            if (el.id === payload.data.id) {
              return { ...el, note: payload.data.note };
            }
            return el;
          })
        }
      };
    case COMPANY.GET_COMPANY_PRODUCTS_SUCCESS:
      return { ...state, products: payload.data };
    case COMPANY.GET_COMPANY_CAMPAIGNS_STATS_SUCCESS:
      return { ...state, campaignsStats: payload.data };

    case COMPANY.RESET_COMPANY_INFO:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default companyReducer;

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import { multiClientMiddleware } from "redux-axios-middleware";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import api from "./core/actions/api";
import routes from "./core/routes/routes";
import rootReducer from "./core/reducers";
import rootSaga from "./core/sagas";
import { SENTRY_DSN_URL } from "./config";

const axiosMiddlewareOptions = {
  interceptors: {
    request: [
      (action, config) => {
        if (localStorage.admin_token) {
          const token = localStorage.admin_token;
          config.headers["Authorization"] = "Bearer " + token;
        } else if (localStorage.token) {
          const token = localStorage.token;
          config.headers["Authorization"] = "Token " + token;
        }
        return config;
      }
    ],
    response: [
      {
        success: ({ dispatch }, response) => {
          return response;
        },
        error: ({ dispatch }, error) => {
          if (error.response.status === 401) {
            localStorage.clear();
          }
          return Promise.reject(error);
        }
      }
    ]
  }
};

const history = createBrowserHistory();
const appRouterMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const createStoreWithMiddleware = applyMiddleware(
  multiClientMiddleware(api, axiosMiddlewareOptions),
  appRouterMiddleware,
  sagaMiddleware
)(createStore);
const store = createStoreWithMiddleware(
  rootReducer(history),
  {},
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
);

sagaMiddleware.run(rootSaga);

Sentry.init({
  dsn: SENTRY_DSN_URL,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history} children={routes} />
  </Provider>,
  document.getElementById("wrapper")
);

import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import TableComponent from "../../_shared/TableComponentWithCustomFields";
import { renderAdminDialogDescription, renderAdminRow, renderCampaignRow } from "./helpers";
import ListDialog from "../../_shared/Dialogs/ListDialog";
import { campaignsTableFields } from "../constants";
import { getCompanyCampaigns } from "../../../core/actions/companyActions";

import "./styles.scss";

const Campaigns = () => {
  const [admins, setAdmins] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const { results, count } = useSelector(({ company }) => company.campaigns);
  const dispatch = useDispatch();
  const { id } = useParams();

  const fetchCampaigns = meta => {
    dispatch(getCompanyCampaigns(id, meta));
  };

  const onAdminsClick = (campaign, adminsList) => {
    setCampaignName(campaign);
    setAdmins(adminsList);
  };
  const onAdminsDialogClose = () => {
    setAdmins([]);
    setCampaignName("");
  };

  return (
    <section className="company__wrapper">
      <div className="company__wrapper--title">Campaigns</div>
      {results.length === 0 && <div className="no-items">No campaigns yet</div>}
      {!!results.length && (
        <TableComponent
          tableFields={campaignsTableFields}
          tableContent={results}
          onTableFetch={fetchCampaigns}
          renderTableRow={el => renderCampaignRow({ ...el, onAdminsClick })}
          numberOfPages={count}
        />
      )}
      {!!admins.length && (
        <ListDialog
          isOpen
          title="Admins"
          onClose={onAdminsDialogClose}
          renderDescription={() => renderAdminDialogDescription(campaignName)}
          renderRow={renderAdminRow}
          elements={admins}
        />
      )}
    </section>
  );
};

export default memo(Campaigns);

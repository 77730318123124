import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import ErrorIcon from "@material-ui/icons/Error";
import { postLogin, resetAuthError } from "../../../core/actions/authActions";
import RenderField from "../../_shared/RenderField";
import DefaultButton from "../../_shared/Buttons/DefaultButton";
import TooltipMessage from "../../_shared/TooltipMessage";

class Login extends Component {
  componentDidMount() {
    const { history } = this.props;
    if (localStorage.token) {
      history.push("/admin");
    }
  }

  submitForm = data => {
    const { postLogin, history, resetAuthError } = this.props;
    resetAuthError();
    postLogin(data).then(res => {
      if (res.payload && res.payload.status && res.payload.status === 200) {
        localStorage.token = res.payload.data.token;
        history.push("/admin");
      }
    });
  };

  render() {
    const { handleSubmit, submitting, pristine, valid, authError, loading } = this.props;
    return (
      <form onSubmit={handleSubmit(this.submitForm)} className="login_form_wrapper">
        <div className="auth_block_head">Welcome to Campy!</div>
        <div className="auth_block_descriptions">
          Please enter your credentials to log in to the system.
        </div>
        <Field name="email" type="text" component={RenderField} label="Email" />
        <Field name="password" type="password" component={RenderField} label="Password" />
        <div className="auth_btn_wrapper">
          <DefaultButton
            variant="contained"
            classes="auth"
            disabled={submitting || pristine || !valid}
            loading={loading}
            formAction
          >
            LOG IN
          </DefaultButton>
          {authError ? (
            <TooltipMessage text={authError} delay={200} error position="right" classes="">
              <ErrorIcon />
            </TooltipMessage>
          ) : (
            ""
          )}
        </div>
      </form>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
    errors.email = "Please enter a correct email address";
  }
  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Must be 8 characters or more";
  }
  return errors;
};

Login = reduxForm({
  form: "LoginForm",
  validate
})(Login);

const mapStateToProps = ({ auth, app }) => {
  return {
    authError: auth.error_auth,
    loading: app.loading
  };
};
const mapDispatchToProps = {
  resetAuthError,
  postLogin
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import _isEmpty from "lodash/isEmpty";

import { getCampaigns } from "../../core/actions/campaignsActions";
import { translateCampaignStatus } from "../../core/helpers/functions";
import TableComponent from "../_shared/TableComponent";
import TotalBlock from "../_shared/TotalBlock";
import tableColumns from "./tableColumns";

import CampaignsIcon from "../../assets/image/campaigns_icon.svg";

const Campaigns = () => {
  const {
    list: { results, count, totals }
  } = useSelector(({ campaigns }) => campaigns);
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchCampaigns = meta => dispatch(getCampaigns(meta));

  return (
    <div className="page-wrapper">
      <h1>Campaigns</h1>
      <div className="page-wrapper__content">
        <TableComponent
          columns={tableColumns}
          data={results}
          fetchData={fetchCampaigns}
          numberOfPages={count}
          noItemsText="No campaigns"
          withSearch
          searchPlaceholder="Search campaign by name or company"
          onRowClick={id => history.push(`/admin/campaigns/${id}`)}
          renderTitle={meta => (
            <h2>
              <span>{meta.status ? translateCampaignStatus(meta.status) : "All"}</span> campaigns
            </h2>
          )}
          renderTotals={onMetaChange => (
            <TotalBlock
              loading={_isEmpty(totals)}
              totalIcon={CampaignsIcon}
              totalText={`${totals.total} campaigns in total`}
              items={[
                { label: `${totals.active} published`, value: "active" },
                { label: `${totals.inactive} unpublished`, value: "inactive" },
                { label: `${totals.finalizing} finalizing`, value: "finalizing" },
                { label: `${totals.finalized} finalized`, value: "finalized" },
                { label: `${totals.stopped_immediately} stopped`, value: "stopped_immediately" },
                { label: `${totals.archived} archived`, value: "archived" },
                { label: `${totals.deleted} deleted`, value: "deleted" }
              ]}
              onItemClick={val => onMetaChange("status", val)}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Campaigns;
